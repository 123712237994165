import React from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom'
import Users from './components/Users'
import Kindergartens from './components/Kindergartens'
import Main from './components/Main'
import Payments from './components/payments'
import ScrollToTop from './components/ScrollTop'
import Tarifs from './components/Tarifs'

import Paysys from './components/paysys';

import { PrivateRoute }  from './helpers/privateroute';

import Sign from './components/sign';

export default props => (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path='/' component={ Main } />
          <PrivateRoute exact path='/users' component={ Users } />
          <PrivateRoute exact path='/payments' component={ Payments } />
          <Route exact path='/tarifs' component={ Tarifs } />
          <PrivateRoute exact path='/kindergartens' component={ Kindergartens } />
          <Route exact path='/sign' component={ Sign } />
          <Route exact path='/paysys' component={ Paysys } />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  )