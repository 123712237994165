import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Link as MaterialLink } from "@material-ui/core";
import Menu from "./Menu";

import UserAccount from "./topbar/user_acc";

import {store, useGlobalState} from 'state-pool';

const logo = require("../images/mom.svg");

const useStyles = makeStyles( theme => ({
  appBar: {
    position: "relative",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.grey["100"]}`,
    backgroundColor: "white"
  },
  logo:{
    verticalAlign: 'bottom'
  },
  inline: {
    display: "inline",
    width: "183px",
  },
  flex: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center"
    }
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
  productLogo: {
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up("md")]: {
      paddingTop: "1.5em"
    }
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: "0.8em"
    }
  },
  iconContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  iconButton: {
    float: "right"
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: "auto"
  },
  block_user: {
    marginLeft: '22%'
  }
}) );

function Topbar(props) {

  const classes = useStyles(props);

  const [value, setValue] = useState(0);

  const [ menuDrawer, setMenuDrawer] = useState(false);

  const [auth, updateAuth, setAuth] = useGlobalState("auth");
  
  const handleChange = (event, value) => {
    setValue(value);
  };

  const menu_role = Menu.filter(menu_el=>{
    
    if(menu_el.role.length == 0) return true;
    if(!auth.user || !auth.user.role || !auth.user.role.role) return false;

    const arr_roles = JSON.parse(auth.user.role.role);
    const res = arr_roles.reduce( (acc, cur) => acc || ( menu_el.role.includes(cur) ), false );
    return res;

  })

  const mobileMenuOpen = event => {
    setMenuDrawer(true);
  };

  const mobileMenuClose = event => {
    setMenuDrawer(false);
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
  })


  let location = useLocation();

  

  const current = () => {

    //updateAuth( auth=> { auth.location = location.pathname } );
    
    const menu_position = menu_role.findIndex(el=>el.pathname == location.pathname );

    return menu_position < 0 ?  0 : menu_position;

  };

    return (
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Grid container spacing={10} alignItems="baseline">
            <Grid item xs={8} className={classes.flex}>
              <div className={classes.inline}>
                <Typography variant="h6" color="inherit" noWrap>
                  <Link to="/" className={classes.link}>
                    <img className={classes.logo} width={30} src={logo} alt="" />
                    <span className={classes.tagline}>OSv Bill</span>
                  </Link>
                </Typography>
              </div>
              {!props.noTabs && (
                <React.Fragment>
                  <div className={classes.iconContainer}>
                    <IconButton
                      onClick={mobileMenuOpen}
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="Menu"
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                  <div className={classes.tabContainer}>
                    <SwipeableDrawer
                      anchor="right"
                      open={menuDrawer}
                      onClose={mobileMenuClose}
                      onOpen={mobileMenuOpen}
                    >
                      <AppBar title="Menu" />
                      <List>
                        {Menu.map((item, index) => (
                          <ListItem
                            component={item.external ? MaterialLink : Link}
                            href={item.external ? item.pathname : null}
                            to={
                              item.external
                                ? null
                                : {
                                    pathname: item.pathname,
                                    search: location.search
                                  }
                            }
                            button
                            key={item.label}
                          >
                            <ListItemText primary={item.label} />
                          </ListItem>
                        ))}
                      </List>
                    </SwipeableDrawer>
                    <Tabs
                      value={current() || value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChange}
                    >
                      {menu_role.map((item, index) => (
                        <Tab
                          key={index}
                          component={item.external ? MaterialLink : Link}
                          href={item.external ? item.pathname : null}
                          to={
                            item.external
                              ? null
                              : {
                                  pathname: item.pathname,
                                  search: location.search
                                }
                          }
                          classes={{ root: classes.tabItem }}
                          label={item.label}
                        />
                      ))}
                    </Tabs>
                  </div>
                 
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={4} className={classes.flex}>
               <div className={classes.block_user}><UserAccount /></div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
}

export default Topbar;
