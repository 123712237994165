export function localDateTime(date){
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            
    var localISOTime = (str)=>(new Date( new Date(str).getTime() - tzoffset )).toISOString().slice(0, -1);

    return localISOTime(date);

}

export function localDate(date){
    
    const loc_date_time = localDateTime(date);

    return loc_date_time.split('T')[0];
}

export function nextMonth(iso_str){
    const [ year, month, day] = iso_str.split('-');
    if(month < 12) return `${year}-${(+month + 1).toLocaleString('en-US',{minimumIntegerDigits: 2}) }-${day}`;
    return `${+year+1}-01-${day}`;
}

export function prevMonth(iso_str){
    const [ year, month, day] = iso_str.split('-');
    if(+month == 1) return `${year -1}-12-${day}`;
    return `${+year}-${(+month - 1).toLocaleString('en-US',{minimumIntegerDigits: 2}) }-${day}`;
}
