/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectKg(props) {
  const classes = useStyles();

  const { data, onChange } = props;

  return (
    <Autocomplete
      id="country-select-demo"
      //style={{ width: 300 }}
      options={ (data || []).sort((a,b)=>a.kg_number - b.kg_number)}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      autoHighlight
      sortBy={(option) => String(option.kg_number)}
      getOptionLabel={(option) => `${option.kg_number}, ${option.kg_name}` }
      renderOption={(option) => (
        <React.Fragment>
          {`${option.kg_number}, ${option.kg_name}, ${option.edrpou}`} 
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберіть заклад"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

