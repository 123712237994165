import React, { useReducer } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AreaIcon from '@material-ui/icons/SelectAll';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import PaymentSystems from '@material-ui/icons/Payment';

import TouchIcon from '@material-ui/icons/TouchApp';

import SettingsInput from '@material-ui/icons/SettingsInputComponent';

import DynamicFeed from '@material-ui/icons/DynamicFeed';


const menuItemsMain = [
  [
    { title: "Користувачі", key: "users", icon: <PeopleIcon />},
   
],[
    { title: "Платіжні сервіси", key: "api_bank_list", icon: <PaymentSystems />}
]
];

const menuItemsSecondary = [
  { title: "Навантаження", key: "users", icon: <TouchIcon />},
  { title: "Авторизація", key: "roles", icon: <SettingsInput />},
  { title: "Дії", key: "area", icon: <DynamicFeed />}
];

export const MenuInternal = (props)=>{

  const initialState = { index:0, key: menuItemsMain[0][0].key };

  const reducer = (state, action)=>({ index: action.index, key: action.key })

  const  [ selected, dispatch ] = useReducer(reducer, initialState);

  const select_menu = (key, index)=>{
    props.select_menu(key);
    dispatch( {index: index, key: key} );
  }

  return (<List>
    { menuItemsMain.map( (block, index)=>{
      
      return ( <div> <Divider />
      { block.map( el=>(
        <Tooltip title={props.open ? '': el.title} placement="right" arrow>
        <MenuItem id={ el.key} key={ el.key } button onClick={ ()=>select_menu( el.key, index ) }  selected={selected.index == index && selected.key == el.key}>
            <ListItemIcon>{el.icon}</ListItemIcon>
            <ListItemText value={ el.key} primary={ el.title } />
        </MenuItem>
        </Tooltip>
      ) 
      ) } </div> ) }
    )}
    </List>)
};

