import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CircularProgress from '@material-ui/core/CircularProgress';
import { matchSorter } from 'match-sorter';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import SelectChildInKg from './SelectChildInKg';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const useStyles = makeStyles((theme) =>({
  wrapdiv:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icons:{
    verticalAlign: 'middle',
    position: 'relative',
    top: '-1px'
  }
}));

export default function SelectChildrenByKG(props) {

  const { headers_auth, onChange, groups, changeGroup } = props;
  
  const classes = useStyles();
  
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const [search, setSearch] = useState('');

  const [list_childrens, setListChildrens] = useState([]);

  const [kg_info, setKgInfo ] = useState([]);

  const [child_name, setChildName] = useState({});

  
  const get_childrens_list = async (kg) =>{

    console.log('get_childrens_list', kg);
    const cur_date = new Date();
    const period = cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1;

    const dateTo = '2020-09-01';
    const url = `/v1/get_children_by_kg?kg_id=${kg}&period=${period}&date_to=${dateTo}`;
    const response = await fetch(url, { headers: headers_auth });
    const data = await response.json();

    //console.log('cur_date.getMonth, cur_date.getDate', cur_date.getMonth, cur_date.getDate);

    if(cur_date.getMonth() == 7 && cur_date.getDate() > 15) {
      const url_1 = `/v1/get_children_by_kg?kg_id=${kg}&period=${cur_date.getFullYear()}&date_to=${dateTo}`;
      const response_1 = await fetch(url_1, { headers: headers_auth });
      const data_1 = await response_1.json();

      const ch_l_1 = data.data.map(ch=>ch._id);

      const filtr = data_1.data.filter(el=>!ch_l_1.includes(el._id));

      //console.log('data.data', data.data);
      //console.log('filtr', filtr);

      data.data = [...data.data, ...filtr ]; 
    }

    setListChildrens(data.data || []);
  }
  
  


  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const log_in = (e) =>{
     setSearch(e.target.value);
  }

  const select_kg = (event, value)=>{
    if(!value) return setListChildrens([]);

    setKgInfo(value);


    get_childrens_list(value.kg_id);
  }

  const list_kgs_district = groups.map(el=>el._id);




  const setChildrenInKg = (event, value) => {
      if(!value) return;
      const { firstName, lastName } = value;


      const res_obj = {
        child:{
          name_first: firstName || 'Не зазначено',
          name_last: lastName || 'Не зазначено'
        },
        kginfo:{
          _id: kg_info.kg_id,
          name: kg_info.name || 'Не зазначено',
          number: kg_info.number || 'Не зазначено',
          groups:{
            children:{
              dateFrom: value.dateFrom || 'Не зазначено',
              smallNumber: value.tabelNumber || 'Не зазначено',
              number: value.bigNumber || 'Не зазначено',
              childId: value._id,
              gioc_id: value.gioc_id_rono_child || ''
            },
            number: value.groupNumber
          }
        }
    };
    
    
    onChange(JSON.stringify(res_obj));


  }


 
  return (
    <Grid container spacing={3}>
        <Grid item xs={5}>
            <Autocomplete
            id="children-async-select"
            //style={{ width: 300 }}
            open={open}
            autoComplete='off'
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option.number + ", " + option.name + " (" + (option.contacts.address.string || "без адреси") + ")"}
            options={ (list_kgs_district|| []).sort((a,b)=>{  if(+a.number > +b.number || a.number.length > b.number.length) return 1; if(+a.number < +b.number || a.number.length < b.number.length) return -1; return 0 } ) }
            loading={loading}
            onChange={select_kg}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Садок"
                //variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                />
            )}
            /> </Grid>
            <Grid item xs={2}>
              <div className={classes.wrapdiv} onClick={changeGroup}>
                <CompareArrowsIcon className={classes.icons} fontSize="large"/>
              </div>
            </Grid>
            <Grid item xs={5}>
                <SelectChildInKg data={list_childrens} onChange={setChildrenInKg}/>
            </Grid></Grid>  );
}
