// Library
import React from 'react';
import Form from '@rjsf/material-ui';

import InputMask from 'react-input-mask';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TextField from "@material-ui/core/TextField";


// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');

export default function FormAddUser (props) {

    const MaskEDRPOU = (props)=><MuiThemeProvider>
            <InputMask 
                mask="99 99 99 99" 
                value={props.value}
                onChange={(event) => {}}
                disabled={false}
                children={<TextField label={props.schema.title}/>}/>
            </MuiThemeProvider>
    
    const MaskIBAN = (props)=><MuiThemeProvider>
    <InputMask 
        mask="UA9999999999999999999999" 
        value={props.value}
        onChange={(event) => {}}
        disabled={false}
        children={<TextField label={props.schema.title}/>}/>
    </MuiThemeProvider>

    const widgets = {
        MaskEDRPOU: MaskEDRPOU,
        MaskIBAN: MaskIBAN
    };


    const onSubmit = (value, callback) => {
        console.log('onSubmit: %s', JSON.stringify(value)); // eslint-disable-line no-console
        setTimeout(() => callback && callback(), 2000); // just an example in real world can be your XHR call
    }
    
    const onCancel = () => {
        console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    if(!props.show) return <div/>;
   
    return (
         <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         />
    );
}