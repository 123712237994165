/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const sortdata = (data) => data.sort((a,b)=>{  if(+a.kg_number > +b.kg_number || a.kg_number.length > b.kg_number.length) return 1; if(+a.kg_number < +b.kg_number || a.kg_number.length < b.kg_number.length) return -1; return 0 } );


export default function SelectKg(props) {
  const classes = useStyles();
  
  const { data, onChange, setId, readOnly } = props;

  const [options, setOptions] = useState([]);

  useEffect(()=>{
    if(!data || ( data.length == options.length ) ) return;
    setOptions(sortdata(data) );
  },[data && data.length]);


  return (
    <Autocomplete
      id="kg-select"
      //style={{ width: 300 }}
      options={options}
      value={options.find(r=>r.kg_id == setId) || null}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      autoHighlight
      getOptionLabel={(option) => `${option.kg_number}, ${option.kg_name}` }
      renderOption={(option) => (
        <React.Fragment>
          {`${option.kg_number}, ${option.kg_name}, ${option.edrpou}`} 
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберить заклад"
          //variant="outlined"
          disabled={readOnly}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

