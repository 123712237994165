import React, { useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MenuInternal } from './InternalMenu';
import Topbar from '../Topbar';

import { headers } from '../../helpers/headers';
import { fetchInit } from '../../helpers/fetchinit';

import { store, useGlobalState } from 'state-pool';

import FormAddUser from './components/AddEditUserForm';

import AddFormAccount from './components/AddBankAccount';

import { ListOfUsers } from './components/ListOfUsers';

import { ListOfDivisions } from './components/Divisions/ListOfDivisions'

import AddTarif from './components/AddTarif';
import { ListOfPaySystem } from './components/ListOfPaySystems';
import { AddEditPaySystem } from './components/AddEditPaySystem';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        IEFR-Studio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const screens = [
  'users_list', 'user_add', 'roles', 'areas', 'api_bank_list', 'api_bank_add'
];

const screen_menu_link = {
  "users": 0,
  "roles": 2,
  "areas": 3,
  "api_bank_list": 4
}

const endpoints = {
  roles:{ url: "/v1/get_roles", type: "get", isLoad: false},
  areas:{ url: "/v1/get_areas", type: "get", isLoad: false},
  users:{ url: "/v1/get_users", type: "get", isLoad: false},
  save_new_user: { url: "/v1/new_user", type: "POST", isLoad: false }
}


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
   // minHeight:'22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    //...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100hv',
  },
}));

export default function Users(props) {
  const [auth, updateAuth, setAuth] = useGlobalState("auth");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = React.useState(0);

  const [roles, setRoles] = useState({});
  const [areas, setAreas] = useState({});
  const [users, setUsers] = useState({});
  const [bank_info, setBankInfo] = useState({});


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const saveNewUser = async (data) => {
    
    const options = {
      method: endpoints.save_new_user.type,
      headers: headers(auth),
      body: JSON.stringify(data)
    };
    
    const res = await fetch(endpoints.save_new_user.url, options);
    if(!res.ok) {
      console.log("error by fetch", endpoints.save_new_user);
      alert("Error by sending req");
      return;
    }

    const info = res.json();

    alert("New User Saved Successfully");

  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const currentPath = props.location.pathname;

  const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

  const select_menu = (key)=>{
        setScreen(screen_menu_link[key]);
  }

  const addUser = (state)=>{
    if(state) return setScreen(screens[1]);
    setScreen(0);
  }

  const addPaySys = (state)=>{
    if(state) {
      const bank_key = genRanHex(24);
      const system_key = genRanHex(24);
      const info = { bank_key, system_key};
      setBankInfo(info);
      return setScreen(screens[5]);
    }
    setScreen(4);
  }

  const getRoles = ()=>fetchInit(endpoints.roles, setRoles, headers(auth));
  const getAreas = ()=>fetchInit(endpoints.areas, setAreas, headers(auth));
  const getUsers = ()=>fetchInit(endpoints.users, setUsers, headers(auth));

  console.log("users index ")

    useEffect(()=>{
      getRoles();
      getAreas();
      getUsers();
      return function cleanup(){
        for( let key in endpoints) {
          endpoints[key]['isLoad'] = false;
        }
      }
    }, [Object.keys(endpoints).reduce( (acc,cur)=>acc&&endpoints[cur].isLoad, true ) ] 
    );


  return (
    <React.Fragment>
    <CssBaseline />
    <Topbar currentPath={currentPath} />   
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <MenuInternal open={open} select_menu={select_menu}/>
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                <ListOfUsers show={screen == screen_menu_link["users"] } addUser={addUser} data={users}/> 
                <ListOfDivisions show={screen == screen_menu_link["roles"]} />
                <FormAddUser show={screen == "user_add"} addUser={addUser} roles={roles} areas={areas} saveNewUser={saveNewUser} /> 
                <AddFormAccount show={screen == "bank_account_add"} />
                <AddTarif />
                <ListOfPaySystem show={screen == screen_menu_link["api_bank_list"]} data={[]} addPaySys={addPaySys}/>
                <AddEditPaySystem show={screen == "api_bank_add"} info={ bank_info }/>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            
          </Box>
        </Container>
      </main>
    </div>
    </React.Fragment>
  );
}