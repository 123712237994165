import React, { useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MenuInternal } from './InternalMenu';
import Topbar from '../Topbar';

import { headers } from '../../helpers/headers';
import { fetchInit } from '../../helpers/fetchinit';

import { store, useGlobalState } from 'state-pool';

import FormAddBenefit from './components/AddBenefit';

import { ListOfTarifs } from './components/ListOfTarifs';

import { ListOfBenefit } from './components/ListOfBenefit';

import AddTarif from './components/AddTarif';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        IEFR-Studio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const screens = [
  'tarifs_list', 'tarif_add', 'benefits_list', 'benefit_add'
];

const screen_menu_link = {
  "tarifs_list": 0, "benefits_list": 2
}

const endpoints = {
  tarifs:{ url: "/v1/get_tarifs", type: "get", isLoad: false},
  save_new_tarif: { url: "/v1/new_tarif", type: "POST", isLoad: false },
  benefits: { url: "/v1/get_benefits", type: "get", isLoad: false },
  save_new_benefit: { url: "/v1/new_benefit", type: "POST", isLoad: false },
  update_tarif: {url: "/v1/update_tarif", type: "PUT", isLoad: false}
}


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
   // minHeight:'22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    //...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100hv',
  },
}));

export default function Tarifs(props) {
  const [auth, updateAuth, setAuth] = useGlobalState("auth");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = React.useState(screens[0]);

  const [tarifs, setTarifs] = useState({});
  const [benefits, setBenefits] = useState([]);
  const [boolUpdateTarifs, setBoolUpdateTarifs] = useState(false);
  const [boolUpdateBenefits, setBoolUpdateBenefits] = useState(false);

  const [update_tarif_data, setTarifsUpdateData] = useState([]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getTarifs = async ()=> await fetchInit(endpoints.tarifs, setTarifs, headers(auth));
  const getBenefits = ()=>fetchInit(endpoints.benefits, setBenefits, headers(auth));

  const prepaid_data = (data)=>{
    const new_data = JSON.parse(JSON.stringify(data));

    const names_serv =  {
       eat: "Батьківська сплата за харчування",
       bfn: "Базовий фінансовий норматив"
    }
    new_data['name'] = `${new_data.sum.replace(/[.,\,]/g, "-")}_${names_serv[new_data.service]}`;
    new_data.sum = new_data.sum.replace(/\,/g, ".");

    return ({ tarif_info: new_data}) ;
  
  }

  const reqSaveNewTarif = async (data) => {
    
    const options = {
      method: endpoints.save_new_tarif.type,
      headers: headers(auth),
      body: JSON.stringify(prepaid_data( data ) )
    };
    
    const res = await fetch(endpoints.save_new_tarif.url, options);
    if(!res.ok) {
      console.log("error by fetch", endpoints.save_new_tarif);
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;

  }

  const reinitTarifs = async () => {
    endpoints.tarifs.isLoad = false;
    await getTarifs();
    setBoolUpdateTarifs(!boolUpdateTarifs);
  }

  const reinitBenefits = async () => {
    endpoints.benefits.isLoad = false;
    await getBenefits();
    setBoolUpdateBenefits(!boolUpdateBenefits);
  }

  const saveNewTarif = async (data) => {
    const res = await reqSaveNewTarif(data);
    reinitTarifs();
}

  const reqUpdateTarif = async (data) => {
    
    const options = {
      method: endpoints.update_tarif.type,
      headers: headers(auth),
      body: JSON.stringify(prepaid_data( data ) )
    };
    
    const res = await fetch(endpoints.update_tarif.url, options);
    if(!res.ok) {
      console.log("error by fetch", endpoints.update_tarif);
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;

  }

  const updateTarif = async (data) => {
      const res = await reqUpdateTarif(data);
      reinitTarifs();
  }

  const saveNewBenefit = async (data) => {

    const options = {
      method: endpoints.save_new_benefit.type,
      headers: headers(auth),
      body: JSON.stringify( { benefit_info: data } )
    };
    
    const res = await fetch(endpoints.save_new_benefit.url, options);
    if(!res.ok) {
      console.log("error by fetch", endpoints.save_new_benefit);
      alert("Error by sending req");
      return;
    }

    const info = res.json();

    reinitBenefits();

  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const currentPath = props.location.pathname;


  const select_menu = (key)=>{
        setTarifsUpdateData({});
        setScreen(key);
  }

  const addTarif = (state)=>{
    setTarifsUpdateData({});
    if(state) return setScreen(screens[1]);
    setScreen(screens[0]);
  }

  const addBenefit = (state)=>{
    if(state) return setScreen(screens[3]);
    setScreen(screens[2]);
  }

  const setUpdateTarif = (id)=>{
    setTarifsUpdateData(tarifs.data.find(tar=>tar.id == id) );
    setScreen(screens[1]);
  }


    useEffect(()=>{
      getTarifs();
      getBenefits();
      return function cleanup(){
        for( let key in endpoints) {
          endpoints[key]['isLoad'] = false;
        }
      }
    }, [Object.keys(endpoints).reduce( (acc,cur)=>acc&&endpoints[cur].isLoad, true ) ] 
    );


  return (
    <React.Fragment>
    <CssBaseline />
    <Topbar currentPath={currentPath} />   
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <MenuInternal open={open} select_menu={select_menu}/>
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                <ListOfTarifs show={screen == "tarifs_list" } addTarif={addTarif} data={tarifs} update={setUpdateTarif} callUpdate={boolUpdateTarifs}/> 
                <AddTarif show={screen == "tarif_add"} update={updateTarif} data={update_tarif_data} addTarif={addTarif}  saveNewTarif={saveNewTarif}/>
                <ListOfBenefit show={screen == "benefits_list" } data={benefits} addBenefit={addBenefit}/>
                <FormAddBenefit show={screen == "benefit_add"} addBenefit={addBenefit}  saveNewBenefit={saveNewBenefit}/>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            
          </Box>
        </Container>
      </main>
    </div>
    </React.Fragment>
  );
}