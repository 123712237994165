import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import { Grid, Typography, Switch, FormControlLabel } from '@material-ui/core';

import AddEditChildSchool from './AddEditChildSchool';

import DatePickers from '../../../helpers/dateinput.js'

import SelectKg from './SelectKg';

import  EditChildren  from './EditChildren';

import { get_students_by_sh } from './actions';
import { promise_set_attendance_student } from "./actions/index";

const useStyles = makeStyles((theme) => ({

  pickerEl:{
    marginTop: '0px',
  },
  labelBetween:{
    marginTop: '21px'
  },
  selectKg:{
    maxWidth: '100%',
    paddingLeft: '10px',
    paddingBottom: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons_div: {
    cursor: 'pointer',
    "&:hover, &:focus": {
      fontWeight: "bold",
      color: "#303F9F"
    }
  }
}));


const [year, month] = new Date().toISOString().split('T')[0].split('-');

const defDate = `${year}-${month}-01`;
const finDate = `${+month > 11 ? +year + 1: year}-${+month > 11 ? '01' : +month + 1}-01`;

const column_source = [
  { title: 'Прізвище', field: 'lastName', editable: "never" },
  { title: 'Ім\'я', field: 'firstName', editable: "never" }, 
  { title: 'Група', field: 'groupNumber', editable: "never"},
  { title: 'Табельній номер', field: 'tab', render: rowData =>`${rowData.tabelNumber || ""}(${rowData.bigNumber || ""})`, editable: "never" },
  { title: 'ID номер', field: 'gioc_id_rono_child', editable: "never" },
  { title: 'Кількість днів відвідувань', field: 'days', editable: "never" },
  { title: 'Пільга', field: 'gioc_id_rono_lgota', editable: "never" },
  { title: 'Тип місця', field: 'typePlace', editable: "never"}
];

const conv_fields = {
        id: 'id',
        id_class: 'id_class',
        lastName: 'name_last',
        firstName: 'name_first',
        groupNumber: 'number',
        tab: 'tab_number',
        gioc_id_rono_child: 'gioc_id',
        days: 'days',
        gioc_id_rono_lgota: null,
        typePlace: null
      };


export  function Attendance(props) {

    const classes = useStyles();

    const { show, data, headers } = props;
    
    const [show_edit, setShowEdit] = useState({show:false, data:{}});

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const [children_data, setTableData] = useState([]);
    
    const [kgs_list, setKgList] = useState();

    const [date_p, setDate] = useState({ from: defDate, to: finDate})

    const [display, setShow] = useState(false);

    const [selected_kg, setSelectedKg] = useState({});

    const [show_dates_range, setDatesRange] = useState(false);

    const [childrens_info, setChildrensInfo] = useState();

    const [isStudents, setUseStudents] = useState(false);

    const [columns, setColumns] = useState(JSON.parse(JSON.stringify(column_source)));

    const [shAddChildSchool, setShowAddChildSchool] = useState(false);
    const [dataChildSchool, setDataChildSchool] = useState(null);

    const [size_of_content, setSizeContent ] = useState(17);
    const[vis_control, setVisControl] = useState('hidden');



    const set_data_students = async () => {
      if(!selected_kg || !selected_kg.kg_id) return;

      const ch_data = await get_students_by_sh({id_school: selected_kg.kg_id, period_start_year: 2023, date_begin: date_p.from, date_end: date_p.to }, headers);
      if(ch_data && ch_data.length > 0) {
        setVisControl('visible');
      } else {
        setVisControl('hidden');
      }
      
      setTableData(ch_data.map(r=>{
        //lastName,firstName,groupNumber,tab,gioc_id_rono_child,days,gioc_id_rono_lgota,typePlace
        //W1.id_school, W1.id_class, W1.name_first, W1.name_last, W1.tab_number, W1.gioc_id, W2.number, W2.name, W2.period_start_year
        const l = {
          id: r.id,
          id_class: r.id_class,
          lastName: r.name_last,
          firstName: r.name_first,
          groupNumber: r.number,
          tab: r.tab_number,
          gioc_id_rono_child: r.gioc_id,
          days: r.days || 0,
          gioc_id_rono_lgota: '',
          typePlace: ''
        };

        return l;
      }));
    }

    const set_data_ch_kindergartens = async ()=>setTableData(await attend_kg_gr(selected_kg.kg_id, date_p.from, date_p.to));

    const setEditStudent = (data)=>{

      const data_form = {
        id_class: data.id_class,
        firstName: data.firstName,
        lastName: data.lastName,
        tabNumber: data.tab + "",
        id_school: selected_kg.kg_id
      }

      setDataChildSchool(data_form);
      setShowAddChildSchool(true);

    };

    const closeEditStudent = ()=>{
      setDataChildSchool(null);
      set_data_students();
      setShowAddChildSchool(false);
    };
    
    
    useEffect(()=>{
      if(!selected_kg || !selected_kg.kg_id) return;
      const new_col = JSON.parse(JSON.stringify(column_source));
      const idx = new_col.findIndex(r=>r.field == 'days');
 
      if(isStudents) new_col[idx]['editable'] = 'numeric';
      if(isStudents) set_data_students();
      if(!isStudents) set_data_ch_kindergartens();
      setColumns(new_col);
    }, [isStudents]);

    
    useEffect(()=>{
      setShow(show);
    },[show])


    const get_attendance_list = async (kg, date) =>{
      const cur_date = new Date(date);
      const url = `/v1/attendance_kg?kg_id=${kg}&period=${cur_date.getMonth() + 1 }.${cur_date.getFullYear()}`;
      const response = await fetch(url, { headers: headers });
      const data = await response.json();
      return parseData(data.data || []);
    }

    const get_childrens_list = async (kg) =>{
      const cur_date = new Date();
      const url = `/v1/get_children_by_kg?kg_id=${kg}&period=${cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 }&date_to=${cur_date.getMonth() + 1 }.${cur_date.getFullYear()}`;
      const response = await fetch(url, { headers: headers });
      const data = await response.json();
      return data.data || [];
    }

    const attend_kg_gr = async (kg, date_from, date_to) =>{
      const url = `/v1/attend_kg_gr?kg_id=${kg}&dateFrom=${date_from}&dateTo=${date_to}`;
      const response = await fetch(url, { headers: headers});
      const data = await response.json();

      const result = data.data || [];

      return parseDataGr(result);
    }

    const parseDataGr = (data_gr) => {

      const typesPlaces = {
        'standart': "Повного дня",
        'without-bed': "Короткотривале",
        'inclusive': "Інклюзія"
      };
      
      return data_gr.map(row=>{
        const { firstName, lastName } = row.childInfo;
        const { idChild } = row._id;

             
        const typePlace = typesPlaces[row._id.tPl];
      
        let days = row.tabel.length;

        const group_info = selected_kg.groups.find(gr=>gr.gr_id == row._id.idGr);

        const groupNumber = group_info && group_info['gr'] || 'архівна';

        const childInf = childrens_info.find(ch=>idChild == ch._id);

        if(!childInf) return ({ firstName, lastName, days, groupNumber, gioc_id_rono_child: row.gioc_id_rono_child, typePlace } );
        
        
        const { tabelNumber, bigNumber } = childInf;
        //const bigNumber = childrens_info.find(ch=>row._id.idGr == ch._id)['tabelNumber'];
        
//        for(let day in row.tabel) {
//          if(row.tabel.main[day] == 1 || row.tabel.first[day] == 1 ) days += 1;
//        }

        return ({ firstName, lastName, days, groupNumber, tabelNumber, bigNumber, gioc_id_rono_child: row.gioc_id_rono_child, typePlace } );
      })
    }

    const parseData = (data) => {
      return data.map(row=>{
        const { firstName, lastName } = row.childInfo;
        let days = 0;

        for(let day in row.tabel.main) {
          if(row.tabel.main[day] == 1 || row.tabel.first[day] == 1 ) days += 1;
        }

        return ({ firstName, lastName, days } );
      })
    }

    const constr_data = async (kg)=>{
      await setSelectedKg(kg);
      setDatesRange(true);

      await setChildrensInfo(await get_childrens_list(kg.kg_id));

    }

    const select_kg = (event, value) =>{

     if(!value) return;

     setTableData([]);
     setVisControl('hidden');
     setDate({ from: date_p.from, to: date_p.to});

      const { kg_id, groups } = value;

      if(!kg_id) {
        setDatesRange( false );
      } else {
        constr_data( {kg_id, groups} )
      }
    }

    const addNewBenefit = ()=>{
      props.addBenefit(true);
    }

    const setdateFrom = (data)=>{
      const dateTo = new Date(date_p.to) < new Date(data) ? data : date_p.to;

      setDate({ from: data, to: dateTo });
    }

    const setdateTo = async (data)=>{
      const new_data = (new Date(data) < new Date(date_p.from) ) || ( new Date() < new Date(data) ) ? date_p.from : data;
      setDate({ from: date_p.from, to: new_data});
      if(!isStudents) setTableData(await attend_kg_gr(selected_kg.kg_id, date_p.from, data));
      if(isStudents) set_data_students();
    }

    
    const edit_child = (event, rowData) => {
      // Do save operation
      setShowEdit({ show: true, data: rowData});

    }

    const close_edchild = ()=>{
      constr_data(selected_kg);
      setShowEdit({ show: false, data:{}});
    }

    useEffect(()=>{
        const info = [];
      
    for(let el of data){
      const { kg_id, number, name, edrpou, property, contacts } = el._id;

      const groups = el.groups;

      const district = contacts.address.district || "Київ"

        const row_kg = {
          kg_id: kg_id,
          kg_number: number,
          kg_name: name,
          edrpou: edrpou,
          property: property,
          district: district,
          groups: groups
        };
        info.push(row_kg);
      }
      
      setKgList(info);

 

    },[data.length])

    const changeDaysStudents = (value, row, resolve)=>{
      const idx = children_data.findIndex(r=>r.id == row.id);
      const new_d = [...children_data];
      new_d[idx]['days'] = value;
      setChildrensInfo(new_d);
      return resolve();
    }
 

    if(!display) return null;

    if(shAddChildSchool) return <AddEditChildSchool data_kg={kgs_list} data={dataChildSchool} show={shAddChildSchool} close={closeEditStudent} headers={headers}/>

    return (
      <div style={{ maxWidth: "100%" }}>
        <div className={ classes.selectKg } >
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={5}>
              <SelectKg data={kgs_list} setId = {selected_kg && selected_kg.kg_id || null} onChange={select_kg} />
            </Grid>
            <Grid className={classes.pickerEl} item xs={2}>
            { show_dates_range && <DatePickers setdate={setdateFrom} label="Дата від" defdate={defDate} max={new Date().toISOString().split('T')[0]} /> }
            </Grid>
            <Grid className={classes.labelBetween} item xs={1}>
              <Typography>
              { show_dates_range && 'по' }
              </Typography>
            </Grid>
            <Grid className={classes.pickerEl} item xs={2}> 
             { show_dates_range && <DatePickers setdate={setdateTo} label="Дата по" min={date_p.from} value={date_p.to} /> }
            </Grid>
            <Grid className={classes.pickerEl} item xs={2}> 
            <FormControlLabel
              control={
             <Switch
                checked={isStudents}
                onChange={(event)=>setUseStudents(event.target.checked)}
                name="students"
                color="primary"/>}
              label={isStudents ? "Школярі" : "Садочки" } />
            </Grid>
          </Grid>
        </div>
        <EditChildren close={ close_edchild } open={show_edit && show_edit['show']} data={show_edit['data']} headers={headers} kg_id={selected_kg}/>
        <div className={classes.size_control} style={{ visibility: vis_control}}>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content < 30) setSizeContent(size_of_content + 1) } }>+ Збільшити шрифт в таблиці</div>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content > 10) setSizeContent(size_of_content - 1) } }>- Зменьшити шрифт в таблиці</div>
        </div>

        <div style={{ fontSize: size_of_content + 'px'}}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={children_data}
          title="Відвідування по садочку за період"
          
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                const { id, id_class, } = rowData;
                promise_set_attendance_student({ id_students:id, id_class, days: newValue, date_begin: date_p.from, date_end: date_p.to}, headers)
                .then(response=>response.json())
                .then(id=>changeDaysStudents(newValue, rowData, resolve))
                .catch(err=>reject())
              });
            }
          }}
          actions={[
            rowData => ({
              icon: 'edit',
              tooltip: 'редагувати',
              onClick: isStudents ? ()=>setEditStudent(rowData) :  edit_child,
//              disabled: rowData && rowData.gioc_id_rono_child
            })
          ]}
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50],
            cellStyle: {
              lineHeight: '0.9em',
              marginTop: '1px'
            }
          }}
        />
        </div>
      </div>
    );

}