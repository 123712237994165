import {store, useGlobalState} from 'state-pool';
import jwt_decode from "jwt-decode";
import { checkAuth } from './checkauth';

const auth = localStorage.getItem("auth");

store.setState("addPay", {}, {persist: true});

const info_auth = auth && auth.length > 1 ? jwt_decode(auth): { };

export function initAuth(){
    if( checkAuth({user: info_auth}) ) {
    store.setState("auth", { user: info_auth, token: auth });
    } else {
    localStorage.removeItem("auth");
    store.setState("auth", { user: {}, token:"" });
    }
}