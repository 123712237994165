import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store, useGlobalState } from 'state-pool';
import { checkAuth } from './checkauth';

import AuthPoint from '../components/sign/auth';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const [auth, updateAuth, setAuth] = useGlobalState("auth");
    return (<Route {...rest} render={props => (
        checkAuth(auth)
            ? <Component {...props} />
            : <AuthPoint state={props.location}/>
    )} />
)}