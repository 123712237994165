async function getReq (link, callback, err, headers) {

    const res = await fetch(link, { headers});
  
    if(!res.ok) {
      return err();
    }

    callback( await res.json() ) ;

}


const cur_date = new Date();

const [dd, mm, yy] = cur_date.toLocaleDateString('uk').split(".");

const dateTo = mm == 12 ? `1.${+yy+1}` : `${+mm + 1}.${yy}`;
const period=cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 ;


export const get_charge_bill = (data, callback, err, headers)=>{

    const { child_id, gioc_id, kg_id } = data;

    const link=`v1/bill_child?child_id=${child_id}&kg_id=${kg_id}&period=${period}&date_to=${dateTo}`;

    const pre_data = (data)=> {
        if(!data || !data[0]) return callback(data);
        data[0]['saldo_beg'] = data[0].deb_begin - data[0].cred_begin;
        data[0]['saldo_fin'] = data[0].deb_end - data[0].cred_end;
        data[0]['correction'] = data[0].correction_deb - data[0].correction_cred + data[0].correction_deb_pay - data[0].correction_cred_pay;

        return callback(data);
    }
    
    getReq(link, pre_data, err, headers);


}

export const get_payments = (data, callback, err, headers)=>{

    const translate_fields ={
        source:{
            'dbf': 'Банківська випіска',
            'online': 'Платіж на сайті',
            'api': 'Партнерский банк'
        }
    }

    const { child_id, gioc_id, kg_id } = data;

    const link =`v1/pay_child?child_id=${child_id}&kg_id=${kg_id}&period=${period}&date_to=${dateTo}`;

    const pre_data = (data_pre)=> {
        
        
        return callback(data_pre.map(d => {
            d['group_number'] = data['group_number'];
            d['gioc_id'] = data['gioc_id'];
            d.date_pay = new Date(d.date_pay).toLocaleDateString('uk');
            d.summ = d.summ.slice(1);
            if(d.summ_cor) d.summ_cor = d.summ_cor.slice(1);
            d['source'] = translate_fields.source[d.source];
            if(!d.period) d['period'] = 'Поточний';
            return d;
        }));
    }

    getReq(link, pre_data, err, headers);

}
