import React from 'react'
import TextField from '@material-ui/core/TextField'
import * as _ from 'lodash'

export function isDateInRange(dateFrom, dateTo, dateToBeFiltered) {
  const parsedDateToBeFiltered = parseDateWithDots(dateToBeFiltered)

  if (!parsedDateToBeFiltered) {
    return !(dateFrom || dateTo)
  }

  if (!(dateFrom || dateTo)) {
    return true
  } else if (!dateFrom) {
    return parsedDateToBeFiltered <= new Date(dateTo).setHours(0, 0, 0, 0)
  } else if (!dateTo) {
    return parsedDateToBeFiltered >= new Date(dateFrom).setHours(0, 0, 0, 0)
  }
  return parsedDateToBeFiltered >= new Date(dateFrom).setHours(0, 0, 0, 0)
    && parsedDateToBeFiltered <= new Date(dateTo).setHours(0, 0, 0, 0)
}

export function parseDateWithDots(dateString) {
  if (!dateString) {
    return null
  }

  if(dateString.split('T').length == 2) return new Date(dateString);

  if (dateString.includes(' ')) {
    dateString = dateString.split(' ')[0]
  }

  const dateArray = dateString.split('.')
  return new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
}



export const FilterNumericBetween = (props) => {
  const { columnDef, onFilterChanged } = props;


  return (
    <div>
        <div>
      <TextField
        type='date'//{columnDef.type === 'numeric' ? 'number' : columnDef.type === 'date'? 'date' : 'search'}
        value={_.get(columnDef, ['tableData', 'filterValue', 'dateFrom']) || ''}
        placeholder={columnDef.filterPlaceholder || 'з'}
        onChange={(event) => {
          let value = {...columnDef.tableData.filterValue}
          value.dateFrom = event.target.value
          onFilterChanged(columnDef.tableData.id, value)
        }}
      />
      </div>
      <div>
      <TextField
        type='date'//{columnDef.type === 'numeric' ? 'number' : columnDef.type === 'date'? 'date' : 'search'}
        value={_.get(columnDef, ['tableData', 'filterValue', 'dateTo']) || ''}
        placeholder={columnDef.filterPlaceholder || 'по'}
        onChange={(event) => {
          let value = {...columnDef.tableData.filterValue}
          value.dateTo = event.target.value

          onFilterChanged(columnDef.tableData.id, value)
        }}
      />
      </div>
    </div>
  )
}

