const Menu = [
  {
    label: "Головна",
    pathname: "/",
    role:[]
  },
  {
    label: "Користувачі",
    pathname: "/users",
    role:["superadministrator"]
  },
  {
    label: "Довідники та тарифи",
    pathname: "/tarifs",
    role:["superadministrator"]
  },
  {
    label: "Заклади",
    pathname: "/kindergartens",
    role:["superadministrator", "buchgalter"]
  },
  {
    label: "Платежі",
    pathname: "/payments",
    role:["superadministrator", "payManager", "buchgalter", "bankMenager", "tarifManager", "benefitManager"]
  },
  {
    label: "Сплатити",
    pathname: "/paysys",
    role:[]
  },
  {
    label: "Садочки Києва",
    pathname: "https://osvita.kyivcity.gov.ua",
    external: true,
    role:[]
  }

];

export default Menu;