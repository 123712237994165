import React, {useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import SelectKg from './components/SelectKg';

import SelectGr from './components/SelectGr';

import SelectChildrens from './components/SelectChildrens';


export default function AddressForm(props) {

    const { data, select_kg, changeChildrensInfo } = props;

    const [kgs_list, setKgList] = useState();
    const [kg_selected, setKg] = useState();
    const [gr_selected, setGr] = useState();

    const [ch_selected, setCh] = useState();

    const [groups, setKgGroups] = useState();

    const [ch_list_all, setListChildrens] = useState();
    const [ch_list_gr, setListChildrensGr] = useState();

    const [last_name, setLastName] = useState();

    const [sum, setSum] = useState();

    const [update_s, setS] = useState(false);

    const get_childrens_list = async (kg) =>{
        const cur_date = new Date();
        const url = `/v1/get_children_by_kg_free?kg_id=${kg}&period=${cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 }&date_to=${cur_date.getMonth() + 1 }.${cur_date.getFullYear()}`;
        const response = await fetch(url);
        const data = await response.json();
        setListChildrens(data.data || []);
      }

      
    useEffect(()=>{

        full_data_set();

        if(kgs_list && kgs_list.length > 0) return;

        const info = [];
            
        for(let el of data){
            const { kg_id, number, name, edrpou, property, contacts } = el._id;
    
            const groups = el.groups;
    
            const district = contacts.address.district || "Київ"
    
            const row_kg = {
                kg_id: kg_id,
                kg_number: number,
                kg_name: name,
                edrpou: edrpou,
                property: property,
                district: district,
                groups: groups
            };
            info.push(row_kg);
            }

            
            setKgList(info.sort());
  
      },[data, kg_selected, gr_selected, ch_selected, last_name, sum])

    const selectKg = (event, value)=>{
        setS(!update_s);
        if(!value) {
            setKg([]);
            setKgGroups([]);
            setListChildrensGr([]);
            setCh(null);
            return;
        }
        get_childrens_list(value.kg_id);
        setKg(value);
        setCh(null);
        setKgGroups(value['groups']);
        setListChildrensGr([]);
    }

    const full_data_set = ()=>{
//        console.log('kg_selected && gr_selected && ch_selected && last_name && sum', kg_selected && gr_selected && ch_selected && last_name && sum);
//        console.log('kg_selected, gr_selected, ch_selected, last_name, sum', kg_selected, gr_selected, ch_selected, last_name, sum);
        if (kg_selected && gr_selected && ch_selected && last_name && sum) {
            const data = { kg: kg_selected , gr: gr_selected, ch: ch_selected, lastName: last_name, sum: sum }
            changeChildrensInfo(data);
        } else full_data_clear()
    }

    const full_data_clear = () => {
        changeChildrensInfo(null);
    }

    const changeLastName = (e)=>{
        const value = e.target.value;
        const data = value.length > 1 ? { kg: kg_selected.kg_id , gr: gr_selected, ch: ch_selected, lastName: value } : null;
        setLastName(value);
    }

    const changeSum = (e)=>{
        const value = e.target.value.replace(",",".");
        if(isNaN(+value)) {
            if(!sum) setSum(0)
            return;
        }
        const sumV = value.match(/\.\d+/g) ? (+value).toFixed(2) : value;
        const data = value.length > 1 ? { kg: kg_selected , gr: gr_selected, ch: ch_selected, lastName: last_name, sum: value } : null;
        setSum(value);
    }
    
    const selectGr = (event, value)=>{

        if(!value) {
            setGr([]);
            setListChildrensGr([]);
            setCh(null)
            return;
        }
        setGr(value);
        setCh(null);
        const ch_list = (ch_list_all || [] ).filter(ch=>ch.groupId == value.gr_id);
        setListChildrensGr(ch_list);
    }

    const selectCh = (event, value)=>{
        setCh(value);
    }

  return (
    <React.Fragment>
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
            <SelectKg data={kgs_list} onChange={selectKg} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <SelectGr data={groups} onChange={selectGr} update_s={update_s}/>
        </Grid>
        <Grid item xs={12} sm={12}>
            <SelectChildrens data={ch_list_gr} onChange={selectCh} update_s={update_s}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField required id="childLastName" label="Введіть прізвище дитини" fullWidth autoComplete="cc-name" onChange={changeLastName} update_s={update_s}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField required id="childSum" label="Вкажіть суму оплати" fullWidth autoComplete="cc-name" value={sum} onChange={changeSum} update_s={update_s}/>
        </Grid>

      </Grid>
    </React.Fragment>
  );
}
