import React, { useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MenuInternal } from './InternalMenu';
import Topbar from '../Topbar';

import { headers } from '../../helpers/headers';
import { fetchInit } from '../../helpers/fetchinit';

import { store, useGlobalState } from 'state-pool';

import FormAddUser from './components/AddEditUserForm';

import AddBankAccount from './components/AddBankAccount';

import AddEditPayForm from './components/AddEditPayForm';

import { ListOfPayments } from './components/ListOfPayments';

import { ListOfBanks } from './components/ListOfBanks';

import { ListOfDivisions } from './components/Divisions/ListOfDivisions'

import AddTarif from './components/AddTarif';

import { initAuth } from '../../helpers/initauth';

import { ListOfPaymentsAuto } from './components/ListOfPaymentsAuto';

import { ListOfPaymentsAPI } from './components/ListOfPaymentsAPI';

import { ListOfPayDbf } from './components/AddPayDbf';

import { ListOfCorrection } from './components/ListOfCorrection';

import { AddEditCorrections } from './components/AddEditCorrections';

import { HelpPayments } from './components/help';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        IEFR-Studio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const screens = [
  'by_kgs', 'add_new_single', 'unallocated', 'bank_list', 'bank_account_add', 'auto', 'dbf', 'list_corrections', 'add_correction', 'help', 'api'
];

const screen_menu_link = {
  "by_kgs": 0,
  "unallocated": 2,
  "bank_list": 3,
  "auto": 5,
  "dbf": 6,
  "list_corrections": 7,
  "help": 9,
  "api": 10
}

const endpoints = {
  payments:{ url: "/v1/get_payments?per_page=5&page=1&ordirection=desc&ordby=created_at", type: "get", isLoad: false},
  save_new_pay: { url: "/v1/new_payment", type: "POST", isLoad: false },
  get_kgs: { url: "/v1/get_kgs", type: "get", isLoad: false },
  get_banks: { url: "/v1/get_bank_account", type: "get", isLoad: false },
  save_new_bank: { url: "/v1/new_bank_account", type: "POST", isLoad: false },
  groups:{ url: "/v1/get_all_gr", type: "get", isLoad: false},
  pay_check: { url: "/v1/pay_check", type: "get", isLoad: false},
  update_pay: { url: "/v1/payment", type: "PUT", isLoad: false },
  check_saldo: { url: "/v1/check_exists", type: "get", isLoad: false},
  save_new_cor: { url: "/v1/new_correction", type: "POST", isLoad: false},
  update_cor: { url: "/v1/correction", type: "PUT", isLoad: false},
  corrections: { url: "/v1/get_corrections", type: "get", isLoad: false}
};


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
   // minHeight:'22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    //...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100hv',
  },
}));

export default function Payments(props) {
  const [auth, updateAuth, setAuth] = useGlobalState("auth");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = React.useState(0);

  const [payments, setPayments] = useState({});

  const [kgs_list, setKgs] = useState({});

  const [banks, setBanks] = useState();

  const [headers_auth, setHeaders] = useState({});

  const [groups, setGroups] = useState([]);

  const [editdata, setEditData] = useState({});

  const [corrections, setCorrections] = useState([]);

  const [editdata_cor, setEditDataCor] = useState([]);



  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [display, setDisplay] = useState(3);

  const saveNewBank = async (data) => {
    
    
    const options = {
      method: endpoints.save_new_bank.type,
      headers: headers(auth),
      body: JSON.stringify({ bank_info: data })
    };
    
    const res = await fetch(endpoints.save_new_bank.url, options);
    if(!res.ok) {
      console.log("error by fetch", endpoints.save_new_bank);
      alert("Error by sending req");
      addBank(false);
      return;
    }

    const info = res.json();

    alert("New User Saved Successfully");

    addBank(false);

  }

  const saveNewPaySingle = async (data)=>{
    const { child, kginfo } = JSON.parse(data.customer);

    const bank_account = JSON.parse(data.bank_account);

    const isEditMode = Object.keys(editdata).length > 0;

    const area = isEditMode ? editdata.area : JSON.parse(auth.user.role.area)[0];
    
    const prepared = {
        bank_account: bank_account.id,
        iban: bank_account.iban,
        summ: data.summ.replace(",", "."),
        date_pay: data.date_pay, 
        status: data.status,
        recipients_id: kginfo._id || '',
        recipients_name: kginfo.name || 'без найменування',
        recipients_number: kginfo.number,
        group_number:  kginfo.groups.number,
        customer_name_f: child.name_first || 'без імені', 
        customer_name_l: child.name_last || 'без імені',
        purpose: data.purpose || 'не визначено',
        area: area,
        bank_from: data.bank_from || "не визначено",
        customer_f: data.customer_f,
        type_purpose: data.type_purpose || ''
      }

    if(kginfo.groups.children && kginfo.groups.children.childId) prepared['customer_id'] = kginfo.groups.children.childId;
    if(kginfo.groups.children && kginfo.groups.children.gioc_id) prepared['gioc_id'] = kginfo.groups.children.gioc_id;

    const ch = await check_exist_pay(prepared);

    if(!ch || ch.length > 0) return alert('Помилка при перевірці платежу');

    if(isEditMode) prepared.id = editdata.id;

    if(isEditMode && !check_change_edit(prepared, editdata)) return alert('Не внесено жодних змін до платежу');


    if(isEditMode) setEditData({});

    if(ch && ch.length > 0 && ch[0].created_at) {
      alert(`Дублікат. Платіж вже був зареєстрований ${ new Date(ch[0].created_at).toLocaleDateString('uk')} ${ new Date(ch[0].created_at).toLocaleTimeString('uk') } `);
      return;
    }


    addPay(false);

    await saveNewPaySingle_serv(prepared, isEditMode ? 'update' : 'new');

    if(isEditMode) alert('Оновіть сторінку для перегляду плтатежів');

  };

  const saveNewCorSingle = async (data)=>{
    const { child, kginfo } = JSON.parse(data.customer);

    const isEditMode = Object.keys(editdata_cor).length > 0;

    const area = isEditMode ? editdata.area : JSON.parse(auth.user.role.area)[0];
    
    const prepared = {
        summ: data.summ.replace(",", "."),
        date_pay: data.date_pay, 
        status: data.status,
        recipients_id: kginfo._id,
        recipients_name: kginfo.name || 'без найменування',
        recipients_number: kginfo.number,
        group_number:  kginfo.groups.number,
        customer_name_f: child.name_first || 'без імені', 
        customer_name_l: child.name_last || 'без імені',
        position: data.position,
        describe: data.describe || 'не визначено',
        area: area
      }

      if(kginfo.groups.children && kginfo.groups.children.childId) prepared['customer_id'] = kginfo.groups.children.childId;
      if(kginfo.groups.children && kginfo.groups.children.gioc_id) prepared['gioc_id'] = kginfo.groups.children.gioc_id;
  
    const ch = await check_exist_pay(prepared);

    if(!ch || ch.length > 0) return alert('Помилка при перевірці платежу');

    if(isEditMode) prepared.id = editdata.id;

    if(isEditMode && !check_change_edit(prepared, editdata)) return alert('Не внесено жодних змін до платежу');


    if(isEditMode) setEditData({});

    if(ch && ch.length > 0 && ch[0].created_at) {
      alert(`Дублікат. Платіж вже був зареєстрований ${ new Date(ch[0].created_at).toLocaleDateString('uk')} ${ new Date(ch[0].created_at).toLocaleTimeString('uk') } `);
      return;
    }


    addCor(false);

    await saveNewCorSingle_serv(prepared, isEditMode ? 'update' : 'new');

    if(isEditMode) alert('Оновіть сторінку для перегляду плтатежів');

  };

  const check_change_edit = ( new_data, orig_data)=>{
  
    for(let key in new_data){
      if(new_data[key] != orig_data[key]) return true;
    }

    return false;
  }

  const check_exist_pay = async (data)=>{
    
    const { summ, customer_id, date_pay, recipients_id, gioc_id } = data;



    const params = `?summ=${summ}&date_pay=${date_pay}&recipient_id=${recipients_id}${customer_id ? '&customer_id=' + customer_id : gioc_id ? '&gioc_id=' + gioc_id : ''}`;

    const options = {
      method: endpoints.pay_check.type,
      headers: headers(auth)
    };
    
    const res = await fetch(endpoints.pay_check.url + params, options);
    if(!res.ok) {
      console.log("error by fetch", endpoints.pay_check.url);
      alert("Error by sending req");
      addBank(false);
      return;
    }

    const info = await res.json();

    return info;

  }

  const saveNewPaySingleDbf = async (data, area)=>{
    const { child, kginfo } = JSON.parse(data.customer);

    console.log('saveNewPaySingleDbf child, kginfo, data', child, kginfo, data);

    const bank_account = JSON.parse(data.bank_account);
   
    const prepared = {
        bank_account: bank_account.id,
        iban: bank_account.iban,
        summ: data.summ.replace(",", "."),
        date_pay: data.date_pay, 
        status: data.status,
        recipients_id: kginfo._id,
        recipients_name: kginfo.name || 'без найменування',
        recipients_number: kginfo.number,
        group_number:  kginfo.groups.number,
        customer_name_f: child.name_first || 'без імені', 
        customer_name_l: child.name_last || 'без імені',
        purpose: data.purpose || 'не визначено',
        area: area,
        bank_from: data.bank_from || "не визначено",
        id_untag: data.id,
        type_purpose: data.type_purpose
      }

      if(kginfo.groups.children && kginfo.groups.children.childId) prepared['customer_id'] = kginfo.groups.children.childId;
      if(kginfo.groups.children && kginfo.groups.children.gioc_id) prepared['gioc_id'] = kginfo.groups.children.gioc_id;
  

      //addPay(false);
      const ch = await check_exist_pay(prepared);

      if(!ch || ch.length > 0) return alert('Помилка при перевірці платежу');

      if(ch && ch.length > 0 && ch[0].created_at) {
        alert(`Дублікат. Платіж вже був зареєстрований ${ new Date(ch[0].created_at).toLocaleDateString('uk')} ${ new Date(ch[0].created_at).toLocaleTimeString('uk') } `);
        return;
      }

    saveNewPaySingle_serv_dbf(prepared);

    
  };

  const saveNewPayDbf = (prepared)=>{
    //saveNewPaySingle_serv(prepared);
  }

  const saveNewPaySingle_serv_dbf = async (data)=>{
    
    const options = {
      method: endpoints.save_new_pay.type,
      headers: headers(auth),
      body: JSON.stringify({ pay_info: data })
    };
    
    const res = await fetch(endpoints.save_new_pay.url, options);
    if(!res.ok) {
      console.log("error by fetch", endpoints.save_new_pay);
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;
  }

  const saveNewPaySingle_serv = async (data, mode)=>{

    const endp = mode == 'new' ? endpoints.save_new_pay : endpoints.update_pay;
    
    const options = {
      method: endp.type,
      headers: headers(auth),
      body: JSON.stringify({ pay_info: data })
    };
    
    const res = await fetch(endp.url, options);
    if(!res.ok) {
      console.log("error by fetch", endp.url);
      alert("Error by sending req");
      addPay(false);
      return;
    }

    const info = res.json();
    
    getPayments();

    addPay(false);
  }

  const saveNewCorSingle_serv = async (data, mode)=>{

    const endp = mode == 'new' ? endpoints.save_new_cor : endpoints.update_cor;
    
    const options = {
      method: endp.type,
      headers: headers(auth),
      body: JSON.stringify({ pay_info: data })
    };
    
    const res = await fetch(endp.url, options);
    if(!res.ok) {
      console.log("error by fetch", endp.url);
      alert("Error by sending req");
      addCor(false);
      return;
    }

    const info = res.json();
    
    getCorrections();

    addCor(false);
  }

  const saveNewPayNext = async (data)=>{
    
  }

  const saveNewCorNext = async (data)=>{

  }

  const headers_get = ()=>({ Authorization: `Osvita ${auth.token}`, 'Content-type': "application/json" });

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const currentPath = props.location.pathname;


  const select_menu = (key)=>{
        setScreen(screen_menu_link[key]);
  }

  const addPay = (state)=>{
    setEditData({});
    if(state) return setScreen(screens[1]);
    setScreen(0);
  }

  const addCor = (state)=>{
    setEditDataCor({});
    if(state) return setScreen(screens[8]);
    setScreen(7);
  }

  const prepDataForCh = (data)=>{
    const child = {
      name_first: data.customer_name_f,
      name_last: data.customer_name_l
    };

    const kginfo = {
      _id: data.recipients_id,
      number: data.recipients_number,
      name: data.recipients_name,
      contacts: {
        address: {
          district: ''}
      },
      groups:{
        number: data.group_number,
        children:{
          smallNumber:'',
          childId: data.customer_id,
          gioc_id: data.gioc_id
        }
      }
    };

    return JSON.stringify({child, kginfo});
  }

  const check_saldo = async (kg_id, date_pay)=>{

    const options = {
      method: endpoints.check_saldo.type,
      headers: headers(auth)
    };

    const fetch_ret = await fetch(endpoints.check_saldo.url + `?kg_id=${kg_id}&date=${date_pay}`, options);

    const res = await fetch_ret.json();

    return res && res.count;

  }

  const editPay = async (data)=>{
    data['customer'] = prepDataForCh(data);

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            
    var localISOTime = (str)=>(new Date( new Date(str).getTime() - tzoffset )).toISOString().slice(0, -1);

    data.date_pay = localISOTime(data.date_pay).split('T')[0];

    const check_s = await check_saldo(data.recipients_id, data.created_at);

    if(+check_s > 0 ) return alert('Редагування заборонено. Платіж створений до формування сальдо. ');

    data.summ = data.summ.replace('$','').replace(/\,/g, '');

    setEditData(data);
    setScreen(screens[1]);
  }


  const addBank = (state)=>{
    if(state) return setScreen(screens[4]);
    setScreen(3);
  }

  
      //const getRoles = ()=>fetchInit(endpoints.roles, setRoles, headers(auth));
    const getKgs = ( params)=>fetchInit(endpoints.get_kgs, setKgs, headers_get(), params);
    const getPayments = ()=>fetchInit(endpoints.payments, setPayments, headers_get());
    const getBanks = ( params) => fetchInit(endpoints.get_banks, setBanks, headers_get(), params);
    const getGroups = async ()=> await fetchInit(endpoints.groups, setGroups, headers(auth));
    const getCorrections = ()=>fetchInit(endpoints.corrections, setCorrections, headers_get());

    
    useEffect(()=>{

      if(!auth || !auth.user || (Object.keys(auth.user).length < 1) ) return null;

      setHeaders( headers(auth) );

      try{

        getKgs("?district=Святошинський");
        //getPayments();
        getBanks();
        getGroups();
        getCorrections();
        return function cleanup(){
          for( let key in endpoints) {
            endpoints[key]['isLoad'] = false;
          }
      }
      } catch(err){
        console.log(err);
      }
    }, [Object.keys(endpoints).reduce( (acc,cur)=>acc&&endpoints[cur].isLoad, true ) ] 
    );

    //if(display != 0) return null;

  return (
    <React.Fragment>
    <CssBaseline />
    <Topbar currentPath={currentPath} />   
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <MenuInternal open={open} select_menu={select_menu}/>
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                <ListOfPayments show={screen == screen_menu_link["by_kgs"] } addNew={addPay} data={payments} listKgs={ ( kgs_list && kgs_list.data) || [] } edit={editPay} headers_auth={headers(auth)}/> 
                {screen == "add_new_single" && <AddEditPayForm show={screen == "add_new_single" } 
                  close={ ()=>addPay(false) }
                  listKgs={( kgs_list && kgs_list.data) || []} 
                  add_close={saveNewPaySingle} 
                  add_next={ saveNewPayNext }
                  headers_auth={headers_get()}
                  banks={banks && banks.data || []}
                  groups={groups}
                  editdata={editdata}
                  />}
                <AddBankAccount show={screen == "bank_account_add"} close={()=>addBank(false) } add_close={saveNewBank} />
                <ListOfBanks show={screen == screen_menu_link["bank_list"] } data={banks || []} addNew={addBank} />
                <ListOfPaymentsAuto show={screen == screen_menu_link["auto"]} headers={headers(auth)} role={JSON.parse(auth.user['role']['area']) || [] }/>
                <ListOfPaymentsAPI show={screen == screen_menu_link["api"]} headers={headers(auth)}/>
                <AddTarif />
                <ListOfPayDbf show={ screen == screen_menu_link["dbf"] } headers={headers(auth)} groups={groups} banks={banks && banks.data || []} add={saveNewPaySingleDbf} auto_save={saveNewPayDbf}/>
                <ListOfCorrection show={ screen == screen_menu_link["list_corrections"] } addNew={addCor} data={corrections} listKgs={ ( kgs_list && kgs_list.data) || [] } />
                {screen == "add_correction" && <AddEditCorrections show={screen == "add_correction" } 
                  close={ ()=>addCor(false) }
                  listKgs={( kgs_list && kgs_list.data) || []} 
                  add_close={saveNewCorSingle} 
                  add_next={ saveNewCorNext }
                  headers_auth={headers(auth)}
                  banks={banks && banks.data || []}
                  groups={groups}
                  editdata={editdata_cor}
                  />}
                <HelpPayments show={ screen == screen_menu_link["help"]} />
              
              
              </Paper>
            </Grid>
          </Grid>
          <footer>
          <Box pt={4}>
            
          </Box>
          </footer>
        </Container>
      </main>
    </div>
    </React.Fragment>
  );
}