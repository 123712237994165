import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import Grid from "@material-ui/core/Grid";
import {Link, Typography, IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';

import SelectKg from './SelectKg';

import AddEditSchool from './AddEditSchool';
import AddEditChildSchool from './AddEditChildSchool';
import AddTarif from './AddTarif';
import AddClass from './AddEditClass';

import { localDate, nextMonth } from '../../../helpers/timedata';

import { get_students_by_sh } from './actions';

const useStyles = makeStyles((theme) => ({

  selectKg:{
    maxWidth: '500px',
    paddingLeft: '10px',
    paddingBottom: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  labelLoad:{
    verticalAlign: '-27px'
  },
  select_period:{
    verticalAlign: 'bottom',
    paddingLeft: '20px',
    marginLeft: '10px'
  },
  date_saldo: {
    fontSize: 'small',
    padding: '10px',
    color: 'blue'
  },
  buttons_div: {
    cursor: 'pointer',
    "&:hover, &:focus": {
      fontWeight: "bold",
      color: "#303F9F"
    }
  },
  block_act: {
    display: 'block',
    textAlign: 'end'
  },
  size_control: {
    
  }

}));

export  function Schools(props) {

    const classes = useStyles();

    const { show, data, headers, role } = props;

    const [shSchool, setShowSchool] = useState(false);
    const [dataSchool, setDataSchool] = useState(false);

    const [shAddChildSchool, setShowAddChildSchool] =  useState(false);
    const [dataAddChildSchool, setDataAddChildSchool] =  useState(false);

    const [shAddTarif, setShowAddTarif] =  useState(false);
    const [dataAddTarif, setDataAddTarif] =  useState(null);

    const [shAddClass, setShowAddClass] =  useState(false);
    const [dataAddClass, setDataAddClass] =  useState(false);

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
      { title: 'Прізвище', field: 'lastName', editable: "never" },
      { title: 'Ім\'я', field: 'firstName', editable: "never" }, 
      { title: 'Група', field: 'groupNumber', editable: "never"},
      { title: 'Табельній номер', field: 'tab', editable: "never" },
      { title: 'ID номер', field: 'gioc_id_rono_child', editable: "never" },
      { title: 'Пільга', field: 'gioc_id_rono_lgota', editable: "never" }
    ];

    const [children_data, setTableData] = useState([]);
    
    const [kgs_list, setKgList] = useState();

    const [selected_kg, selectKg] = useState();

    const [size_of_content, setSizeContent ] = useState(17);
    const [dataChildSchool, setDataChildSchool] = useState(null);

    const[vis_control, setVisControl] = useState('hidden');



    const setEditStudent = (data)=>{

      const data_form = {
        id_class: data.id_class,
        firstName: data.firstName,
        lastName: data.lastName,
        tabNumber: data.tab + "",
        id_school: selected_kg
      }

      setDataChildSchool(data_form);
      setShowAddChildSchool(true);

    };


    const set_data_students = async () => {
      if(!selected_kg) return;

      const ch_data = await get_students_by_sh({id_school: selected_kg, period_start_year: 2023 }, headers);
      if(ch_data && ch_data.length > 0) {
        setVisControl('visible');
      } else {
        setVisControl('hidden');
      }
      setTableData(ch_data.map(r=>{
        //lastName,firstName,groupNumber,tab,gioc_id_rono_child,days,gioc_id_rono_lgota,typePlace
        //W1.id_school, W1.id_class, W1.name_first, W1.name_last, W1.tab_number, W1.gioc_id, W2.number, W2.name, W2.period_start_year
        const l = {
          id: r.id,
          id_class: r.id_class,
          lastName: r.name_last,
          firstName: r.name_first,
          groupNumber: r.number,
          tab: r.tab_number,
          gioc_id_rono_child: r.gioc_id,
          gioc_id_rono_lgota: '',
          typePlace: ''
        };

        return l;
      }));
    }

    const select_kg = (event, value) =>{
      if(!value) return selectKg(null);
      const { kg_id } = value;
      console.log(`select_kg kg_id ${JSON.stringify(kg_id)}`);
      selectKg(kg_id);
    }
    
    useEffect(()=>{

        const info = [];
      
    for(let el of data){
      const { kg_id, number, name, edrpou, property, contacts } = el._id;

      const groups = el.groups;

      const district = contacts.address.district || "Київ"

        const row_kg = {
          kg_id: kg_id,
          kg_number: number,
          kg_name: name,
          edrpou: edrpou,
          property: property,
          district: district
        };
        info.push(row_kg);
      }
      
      setKgList(info);

    },[data.length])


    useEffect(()=>{
      if(!selected_kg) return setTableData([]);
      set_data_students();
    }, [selected_kg]);


    const closeEditStudent = ()=>{
      setDataChildSchool(null);
      set_data_students();
      setShowAddChildSchool(false);
    };
    

    if(!show) return null;

    if(shAddChildSchool) return <AddEditChildSchool data_kg={kgs_list} data={dataChildSchool} show={shAddChildSchool} close={closeEditStudent} headers={headers}/>

    if(shSchool) return <AddEditSchool show={shSchool} close={()=>setShowSchool(false)} headers={headers} />
    if(shAddTarif) return <AddTarif data={kgs_list} show={shAddTarif} close={()=>setShowAddTarif(false)} headers={headers} />
    if(shAddClass) return <AddClass data={kgs_list} show={shAddClass} close={()=>setShowAddClass(false)} headers={headers} />

    return (
      <div style={{ maxWidth: "100%" }}>
        <Grid container spacing={10} alignItems="baseline">
          <Grid item xs={7}>
            <div className={ classes.selectKg } >
              <SelectKg data={kgs_list} setId={selected_kg} onChange={select_kg} />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={ classes.block_act}>
            <Typography className={ classes.selectKg }><div onClick = {()=>setShowSchool(true)} className={ classes.buttons_div}>Додати нову школу</div></Typography>
            {false && <Typography className={ classes.selectKg }><div onClick = {()=>setShowSchool(true)} className={ classes.buttons_div}>Редагувати школу</div></Typography>}
            </div>
          </Grid>
        </Grid>
        <div className={classes.size_control} style={{ visibility: vis_control}}>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content < 30) setSizeContent(size_of_content + 1) } }>+ Збільшити шрифт в таблиці</div>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content > 10) setSizeContent(size_of_content - 1) } }>- Зменьшити шрифт в таблиці</div>
        </div>

        <div style={{ fontSize: size_of_content + 'px'}}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={children_data}
          title="Школярі"
          
          
          actions={[
            {
              icon: 'add',
              tooltip: 'Додати учня',
              isFreeAction: true,
              onClick: (event) => setEditStudent({id_school: selected_kg})
            },
            rowData => ({
              icon: 'edit',
              tooltip: 'редагувати',
              onClick: ()=>setEditStudent(rowData),
//              disabled: rowData && rowData.gioc_id_rono_child
            })
          ]}
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50]
          }}
        />
        </div>
      </div>
    );

}