// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import TextField from "@material-ui/core/TextField";

import Button from '@material-ui/core/Button';
import AlertDialog from '../../../../helpers/alertdialog';

import SelectClass  from '../SelectClass';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import { add_tarif, upd_tarif } from './actions';


const infoAlertObj = { title: "Оновлення даних", text:"Перевирте та натисніть зберегти або відминити ", btn_agr:"Зберегти", btn_dsg: "Відмінити збереження" }; 



// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');


export default function FormAddTarif (props) {

    const { close, addTarif, data, data_tarif, headers, show } = props;

    const isEdit = data_tarif && data_tarif.id_school && data_tarif.id_class && data_tarif.type_tar|| false;

    const isPreSet = data_tarif && data_tarif.id_school && data_tarif.id_class || false;

    const formData = isEdit || isPreSet ? data_tarif : require('./formData.json');

    const [showAlert, setShowAlert] = useState(false);

    const [infoAlert, setInfoAlert] = useState(infoAlertObj);

    const [callbackAlert, setCallbackAlert] = useState(false);


    const SelectClassW = (props) =><SelectClass
        initSelKg = {data_tarif && data_tarif.id_school || null}
        readOnly={props.schema.readOnly || false}
        selClass={props.value}
        kg_options={data} 
        onChange={(event, value)=> {
            const id_class = value && value.id || "";
            props.onChange(id_class);
        }} 
        headers={headers}/>

    const widgets = {
        SelectClass: SelectClassW
    };

    const saveUpdate = ({ formData })=>{
        setCallbackAlert(()=>onSubmit(formData));
        setShowAlert(true);
    }


    const saveNewTarif = async (formData) => {
        const { tarif } = formData;
        formData.tarif = Math.trunc(tarif * 100);

        const result = await add_tarif(formData, headers);

        close('updated');
    }

    const updateTarif = async (formData) => {

        const { tarif } = formData;
        formData.tarif = Math.trunc(tarif * 100);

        const result = await upd_tarif(formData, headers);

        close('updated');

    }


    const onSubmit = async ({formData}) => {
        if(isEdit) return updateTarif(formData);
        saveNewTarif(formData)
    }
    
    const onCancel = () => {
        //console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        //console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        //console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    

    const closeAlert = (res, callback) =>{
        if(res) callback();
    }

    if(!show) return <div/>;

    schema.title = isEdit ? "Редагувати тарифний план" : "Створити новий тарифний план";

    schema.properties.id_class['readOnly'] = isEdit || isPreSet ? true : false;
    schema.properties.type_tar['readOnly'] = isEdit ? true : false;

    console.log(`schema ${JSON.stringify(schema)}`);

    return (
         <div>
             <AlertDialog open={showAlert} info={infoAlert} close={closeAlert} callback={closeAlert}/>
             <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={close}>Закрити без змін</Button>
         </Form>
         </div>
    );
}