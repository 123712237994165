// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import TextField from "@material-ui/core/TextField";

import Button from '@material-ui/core/Button';
import AlertDialog from '../../../../helpers/alertdialog';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import  { add_new_school }  from "./actions";


const infoAlertObj = { title: "Оновлення даних", text:"Перевирте та натисніть зберегти або відминити ", btn_agr:"Зберегти", btn_dsg: "Відмінити збереження" }; 



// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');

function ArrayFieldTemplate(props) {
    // There should be a better way to do this?

     const {uiSchema: {"ui:options": {addText, deleteText}}} = props;
     return (
       <div className={props.className}>
         {props.items &&
           props.items.map((element) => (
             <div key={element.key} className={element.className}>
               <div>{element.children}</div>
               {element.hasMoveDown && (
                 <Button variant="text"
                   onClick={element.onReorderClick(
                     element.index,
                     element.index + 1
                   )}
                 >
                   
                 </Button>
               )}
               {element.hasMoveUp && (
                 <Button variant="text"
                   onClick={element.onReorderClick(
                     element.index,
                     element.index - 1
                   )}
                 >
                   
                 </Button>
               )}
               <Button variant="text" onClick={element.onDropIndexClick(element.index)}>
                 {deleteText}
               </Button>
               <hr />
             </div>
           ))}
   
         {props.canAdd && (
           <div className="row">
             <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
               <Button variant="text" onClick={props.onAddClick} type="button">
                 {addText}
               </Button>
             </p>
           </div>
         )}
       </div>
     );
   }

export default function FormAddSchool (props) {

    const { close, addSchool, saveNewShool, data, update, headers } = props;

    const [value, setValue] = useState();

    const [dataForm, setDataForm] = useState({});

    const [showAlert, setShowAlert] = useState(false);

    const [infoAlert, setInfoAlert] = useState(infoAlertObj);

    const [callbackAlert, setCallbackAlert] = useState(false);


    const CurrencyInputW = (props)=><CurrencyTextField label="Сума"
    variant="standard"
    textAlign="left"
    value={value}
    currencySymbol="грн."
    //minimumValue="0"
    outputFormat="number"
    decimalCharacter="."
    digitGroupSeparator=" "
    onChange={(event, value)=> { setValue(value); props.onChange(value) ; } }
    />

    
    useEffect(()=>{

        const newDataForm = JSON.parse(JSON.stringify(formData)) ;

        if(data && data.id){
    
            for(let key in data) {
                const type_date = uiSchema[key] && uiSchema[key]["ui:widget"] || "string";
                const value = data[key];
    
                if(type_date  == "date" && value && value.length > 10){
                    newDataForm[key] = data[key].split("T")[0];
                    continue;
                }
    
                if( type_date == "date" ) continue;
    
                newDataForm[key] = data[key];
            }
        }

        setDataForm(newDataForm);

    },[data && data.id])
    

 

    const widgets = {
        CurrencyInput: CurrencyInputW
    };

    const saveUpdate = ({ formData })=>{
        setCallbackAlert(()=>onSubmit(formData));
        setShowAlert(true);
    }


    const onSubmit = async ({formData}) => {

        const { number, name, district, cb_id, classes, iban, edrpou } = formData;

        const [ year,month ] = new Date().toISOString().split("-");

        const cur_period = month > 8 ? +year : year - 1;

        const prep = {
            school_info: {
                number, name, district, cb_id, iban, edrpou
            }, classes: classes.map(c=>{c['period_start_year'] = cur_period; return c; })
        }

        const send = await add_new_school({data: prep, headers});

        close();
    }
    
    const onCancel = () => {
        //console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        //console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        //console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const closeAlert = (res, callback) =>{
        if(res) callback();
    }

    if(!props.show) return <div/>;
  
    return (
         <div>
             <AlertDialog open={showAlert} info={infoAlert} close={closeAlert} callback={closeAlert}/>
             <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={dataForm}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
              ArrayFieldTemplate={ArrayFieldTemplate}
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={close}>Закрити без змін</Button>
         </Form>
         </div>
    );
}