import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

import { makeStyles } from '@material-ui/core/styles';

import { get_payments } from './actions';

const useStyles = makeStyles((theme) => ({
    closeButton:{
        cursor: 'pointer',
        fontSize: '15px',
        padding: '5px',
        backgroundColor: '#eeeeee',
        display: 'inline-block',
        borderRadius: '2px',
        margin: '5px'
      }
}));

const columns = [
    { title: "Група", field: "group_number", editable: "never" },
    { title: "ІД", field: "gioc_id", editable: "never" },
    { title: "Дата оплати", field: "date_pay", editable: "never" },
    { title: "Сума", field: "summ", editable: "never" },
    { title: "Період", field: "period", editable: "never" },
    { title: "Джерело платежу", field: "source", editable: "never" },
    { title: "Дата коригування", field: "date_cor", editable: "never" },
    { title: "Сума коригування", field: "summ_cor", editable: "never" },
    { title: "Період коригування", field: "period_cor", editable: "never" },
  ]

  export function Payments(props) {
    const { data, close, headers } = props;
    const { id_child } = data;

    const classes = useStyles();

    const [info, setInfo] = useState([]);
    const tableRef = React.createRef();

    const getData = ()=>{
        get_payments(data, setInfo, (err)=>console.log(`error get info about payments`), headers);
    }

    useEffect(()=>{ getData()}, [id_child])

    return (
        <div>
            <div onClick={close} className={classes.closeButton}>Закрити та повернутись</div>
        <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={info}
        title="Оплати/коригування за період"
        editable={{
            isEditable: true 
        }}

        options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [5,10,20]
        }}

        />
        </div>
    )
}
