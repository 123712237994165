import React, { useReducer } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import PaymentSystems from '@material-ui/icons/Payment';

import TouchIcon from '@material-ui/icons/TouchApp';

import SettingsInput from '@material-ui/icons/SettingsInputComponent';

import DynamicFeed from '@material-ui/icons/DynamicFeed';

import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SchoolIcon from '@material-ui/icons/School';
import SearchIcon from '@material-ui/icons/Search';
import FastFoodIcon from '@material-ui/icons/Fastfood';


const menuItemsMain = [
  [
    { title: "Нарахування", key: "childrens", icon: <FastFoodIcon />},
    { title: "Групи(класи)", key: "groups", icon: <PeopleIcon />}
  ],[

    { title: "Облік школярів", key: "students", icon: <SchoolIcon />},
    { title: "Пошук в ЦБ", key: "searchCB", icon: <SearchIcon />},
    { title: "Відвідування", key: "attendance", icon: <BubbleChartIcon />},
    { title: "Рахунки", key: "bankacc", icon: <AccountBalanceWalletIcon />},
    { title: "Пільги", key: "benlist", icon: <AccessibleForwardIcon />},
    { title: "БФН", key: "bfnlist", icon: <ChildCareIcon />},
    { title: "Звітність", key: "reports", icon: <DynamicFeed />}

  ]
];

const menuItemsSecondary = [
  { title: "Навантаження", key: "users", icon: <TouchIcon />},
  { title: "Авторизація", key: "roles", icon: <SettingsInput />},
  { title: "Дії", key: "area", icon: <DynamicFeed />}
];

export const MenuInternal = (props)=>{

  const initialState = { index:0, key: menuItemsMain[0][0].key };

  const { role } = props;
  
  const reducer = (state, action)=>({ index: action.index, key: action.key })

  const  [ selected, dispatch ] = useReducer(reducer, initialState);

  const select_menu = (key, index)=>{
    props.select_menu(key);
    dispatch( {index: index, key: key} );
  }

  return (<List>
    { menuItemsMain.map( (block, index)=>{
      
      return ( <div> <Divider />
      { block.map( el=>(
        <Tooltip title={props.open ? '': el.title} placement="right" arrow>
        <MenuItem id={ el.key} key={ el.key } button onClick={ ()=>select_menu( el.key, index ) }  selected={selected.index == index && selected.key == el.key}>
            <ListItemIcon>{el.icon}</ListItemIcon>
            <ListItemText value={ el.key} primary={ el.title } />
        </MenuItem>
        </Tooltip>
      ) 
      ) } </div> ) }
    )}
    </List>)
};

