/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { get_list_classes } from './actions';
import { getCurrentPeriod } from '../../../helpers/kindergartens';

import SelectKg from './SelectKg';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectClass(props) {
  const classes = useStyles();

  const { kg_options, selClass, onChange, headers, initSelKg, readOnly} = props;

  const [selKg, setKg] = useState(initSelKg || null);

  const [classes_list, setClassesList] = useState([]);

  const set_new_list_classes = async() => {
    const list_class = await get_list_classes({id_school: selKg, period_start_year: getCurrentPeriod()}, headers);
    console.log(`set_new_list_classes ${list_class}`);
    if(!list_class || (list_class.length < 1) ) return setClassesList([]);
    setClassesList(list_class);
  }

  useEffect(()=>{
      if(!selKg) return;
      console.log(`useEffect set_new_list_classes`);
      set_new_list_classes();
  }, [selKg]);

  const onChangeKG = (event, value) => {
    if(readOnly) return;
    setKg(value && value.kg_id || '')
  }

  console.log(`SelectClass selKg ${selKg}`);
  
  return (
    <div>
    <div style={{marginBottom: '25px'}}><SelectKg data={kg_options} setId={selKg} onChange={onChangeKG} readOnly={readOnly}/></div>
    <Autocomplete
      id="select-classes"
      key={classes_list.length || 0}
      //style={{ width: 300 }}
      options={classes_list}
      classes={{
        option: classes.option,
      }}
      disableClearable={ readOnly }
      value={classes_list.find(r=>r.id == selClass) || null}
      onChange={readOnly ? null : onChange}
      autoHighlight
      getOptionLabel={(option) => `${option.number}  ${option.name}` }
      renderOption={(option) => (
        <React.Fragment>
          {option.number}  {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберить класс(групу)"
          //variant="outlined"
          disabled={readOnly}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
    </div>
  );
}

