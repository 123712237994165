import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { matchSorter } from 'match-sorter';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import SelectKg from './SelectKg';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const useStyles = makeStyles((theme) =>({
  wrapdiv:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icons:{
    verticalAlign: 'middle',
    position: 'relative',
    top: '-1px'
  }
}));

export default function SelectChildren(props) {

  const { headers_auth, onChange, changeGroup, info, editdata } = props;
  
  const classes = useStyles();
  
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  
  let loading = false;

  const [def_value, setDefault] = useState('');

  const [search, setSearch] = useState('');

  const [list_kgs, setListKg] = useState([]);

  const [child_name, setChildName] = useState({});

  const [info_ch, setInfoCh] = useState();

  const [def_kg, setDefKg] = useState();

  const set_kg_by_name = async ( {name_last, name_first} )=>{
    const response = await fetch(`/v1/find_child_by_name?name_last=${encodeURI(name_last)}&name_first=${encodeURI(name_first)}`, { headers: headers_auth });
    //const response = await fetch(`/v1/find_child_by_name?name_last=${encodeURI('Овсейчук')}&name_first=${encodeURI('Анастасія')}`, { headers: headers_auth });
    const data = await response.json();
    setChildName({ name_last: name_last, name_first: name_first });
    setListKg(data);
   };


  useEffect(() => {
    let active = false;

    if(info) setInfoCh(info);


    if (loading) {
      return undefined;
    }

    loading = true;

    if(editdata && editdata.length > 3){
      const obj = JSON.parse(editdata);
      const { child, kginfo} = obj;


      if(!child) return;

      setOptions([child]);
      setDefault(`${child.name_last} ${child.name_first}`);
      setDefKg([kginfo]);

      return;
    }

    const search_ch = info && info.child_id ? info.child_id : info && info.child_name ? info.child_name : '';


    (async () => {
      if(search_ch && search_ch.length > 3) {
        const response = await fetch(`/v1/find_children?find=${encodeURI(search_ch)}`, { headers: headers_auth });
        const data = await response.json();

          const prepData = prepare_child_info(data);

          if(!prepData || !prepData[0]) return;

          const { sezzdo_name_last, sezzdo_name_first, gioc_id_rono_child } = prepData[0];

          setOptions(prepData);
          //setDefault(`${sezzdo_name_last} ${sezzdo_name_first} ${gioc_id_rono_child ? '( '+ gioc_id_rono_child + ' )' : ''}`.trim());
      }
      
    })();

    return () => {
      active = false;
    };
  }, [info && info.child_id]);


  const prepare_child_info = (data)=>{
    const info = {};

    const info_arr = [];
    
    for(let ch of data){
      if(!info[ [ch.sezzdo_name_last, ch.sezzdo_name_first].join(' ') ]) info[ [ch.sezzdo_name_last, ch.sezzdo_name_first].join(' ') ] = { gioc_id_rono_child: [], sezzdo_name_last: ch.sezzdo_name_last, sezzdo_name_first: ch.sezzdo_name_first };
      if(ch.gioc_id_rono_child && ch.gioc_id_rono_child != '') info[ [ch.sezzdo_name_last, ch.sezzdo_name_first].join(' ') ]['gioc_id_rono_child'].push(ch.gioc_id_rono_child);
    }

    const arr_key = Object.keys(info).sort( (a,b)=> a.localeCompare(b) );
    
    for(let el of arr_key){
      info_arr.push(info[el]);
    }

    return info_arr;

  }

  const setSearchTerm = async (params)=>{
    //if(params.target.value.match(/\s/g)) return;
    const response = await fetch(`/v1/find_children?find=${encodeURI(params.target.value)}`, { headers: headers_auth });
    const data = await response.json();

    const prepData = prepare_child_info(data);

    setOptions(prepData);
    setListKg([]);

   };


  

  const log_in = (e) =>{
     setSearch(e.target.value);
  }

  const log_i = (event, value) =>{

    if(!value) return;

    set_kg_by_name({ name_last: value.sezzdo_name_last, name_first: value.sezzdo_name_first });

  }

  const debounce = (f, ms) => {

    let isCooldown = false;
  
    return function() {
      if (isCooldown) return;
  
      f.apply(this, arguments);
  
      isCooldown = true;
  
      setTimeout(() => isCooldown = false, ms);
    };  
  }

  const setChildrenKg = (event, value) => {
      onChange(JSON.stringify({ child: child_name, kginfo: value }));
  }

  const setSearchTermDebounced = debounce(setSearchTerm, 500);

  const AddEl = ()=><Select>{list_kgs.map(el=><MenuItem>{el.name}</MenuItem>)}</Select>

  const filterOptions = (options, { inputValue }) => {

    if(inputValue.length < 1 || options.length < 1) return options;
    const value = inputValue && inputValue.replace(/[\s,', ",`,(,),(\/),(\\)]/g, "").toLowerCase() || "";
    const len = value.length;
    
    const new_opt = options.filter(opt=>{
  
      const str_name = (opt.sezzdo_name_last + opt.sezzdo_name_first) && (opt.sezzdo_name_last + opt.sezzdo_name_first).slice(0,len).replace(/[\s,', ",`,(,)]/g, "").toLowerCase() || '';
      const number_id = opt.gioc_id_rono_child && opt.gioc_id_rono_child.length > 0 && opt.gioc_id_rono_child.join().toString() || "";

      const num_eq = number_id && number_id.match(new RegExp(value));
      const name_eq = value && value == str_name;

      return num_eq || name_eq;

    });


    return new_opt;

    //return options;
  }



  return (
    <Grid container spacing={3}>
        <Grid item xs={5}>
            <Autocomplete
            id="children-async-select"
            //style={{ width: 300 }}
            open={open}
            autoComplete='off'
            filterOptions={filterOptions}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option && option.sezzdo_name_last && ( (option.sezzdo_name_last.replace("'", "").trim() ==  
            value && value.sezzdo_name_last && value.sezzdo_name_last.replace("'", "").trim() ) || (option.gioc_id_rono_child && option.gioc_id_rono_child.length > 0 && option.gioc_id_rono_child.join().match(new RegExp(value.gioc_id_rono_child) ) ) ) }

            getOptionLabel={(option) => {
              const gioc_numb = option.gioc_id_rono_child && option.gioc_id_rono_child.length > 0 && option.gioc_id_rono_child.join() || '';
              const full_name = option && option.sezzdo_name_last && (option.sezzdo_name_last + " " + option.sezzdo_name_first) || '';

              const result = gioc_numb != '' ? full_name + ' (' + gioc_numb + ')' : full_name;

              return result;
            }}
            options={options}
            inputValue={ def_value }
            onInputChange={(_, newInputValue) => {
              setDefault(newInputValue)
            }}
            loading={loading}
            onChange={log_i}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Дитина"
                onChange={setSearchTermDebounced}
                //variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            )}
            /> </Grid>
            <Grid item xs={2}>
              <div className={classes.wrapdiv} onClick={changeGroup}>
                <CompareArrowsIcon className={classes.icons} fontSize="large"/>
              </div>
            </Grid>
            <Grid item xs={5}>
                <SelectKg data={list_kgs} onChange={setChildrenKg} default_v={def_kg}/>
            </Grid></Grid>  );
}
