// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import Box from '@material-ui/core/Box';

import InputMask from 'react-input-mask';

import Button from '@material-ui/core/Button';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TextField from "@material-ui/core/TextField";

import SelectKg from '../SelectKg';
import WidgetChildrenSelect from '../GroupSelectChildren';

import SelectBank from '../SelectBank';
import { store, useGlobalState, useLocalState } from 'state-pool';



// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');



export default function AddEditPay (props) {

    const { close, add_close, groups, listKgs, headers_auth, banks, editdata } = props;

    const [pay_opt, updatePayOpt, setPayOpt] = useGlobalState("addPay");

    const [option_kg, setOptionsKg] = useState([]);

    const CustomSelectKg = (props)=><SelectKg 
        data={option_kg}
        onChange={(event, value) => props.onChange(value && value._id || '') }
    />

    const CustomSelctBank = (props)=><SelectBank
        data={banks}
        default_val={ pay_opt && pay_opt.bank_account && !props.value ? JSON.parse(pay_opt.bank_account) : props.value && props.value.length > 29 ? JSON.parse(props.value):{} }
        onChange={(event, value) => props.onChange(JSON.stringify(value) ) }
    />

    const set_kg_by_name = async ( {name_last, name_first} )=>{
        const response = await fetch(`/v1/find_child_by_name?name_last=${encodeURI(name_last)}&name_first=${encodeURI(name_first)}`, { headers: headers_auth });
        const data = await response.json();
        setOptionsKg(data);
       };

    const WidgetSelectCh = (props)=><WidgetChildrenSelect headers_auth={headers_auth} set_kg_by_name={set_kg_by_name} groups={groups} list_kg={option_kg} onChange={(data) => props.onChange(data|| '')} editdata={props.value} />
        

    const MaskEDRPOU = (props)=><MuiThemeProvider>
            <InputMask 
                mask="99 99 99 99" 
                value={props.value}
                onChange={(event) => props.onChange(event.target.value.replace(/\s/g, "")) }
                disabled={false}
                children={<TextField label={props.schema.title}/>}/>
            </MuiThemeProvider>
    
    const MaskIBAN = (props)=><MuiThemeProvider>
    <InputMask 
        mask="UA999999999999999999999999999" 
        value={props.value}
        onChange={(event) => props.onChange(event.target.value) }
        disabled={false}
        children={<TextField label={props.schema.title}/>}/>
    </MuiThemeProvider>

    const widgets = {
        MaskEDRPOU: MaskEDRPOU,
        MaskIBAN: MaskIBAN,
        CustomSelectKg:CustomSelectKg,
        CustomSelectChildren:WidgetSelectCh,
        CustomSelctBank:CustomSelctBank
    };


    const onSubmit = (value, callback) => {

        const data_send = !editdata || !editdata.customer ? value.formData : Object.assign({}, { customer_f: JSON.stringify(JSON.parse(editdata.customer).kginfo.groups.children) }, value.formData);
                
        setPayOpt( value.formData )
        add_close(data_send);
    }
    
    const onCancel = () => {
    }
    
    const onFormChanged = ({ formData }) => {
    }
    
    const onUpload = (value) => {
    }

    const closeForm = () => {
        close();
    }

    const fill_info = ()=>{

//        console.log('fill_info editdata', editdata);

        if(!editdata || Object.keys(editdata).length == 0) {

            if(pay_opt && pay_opt.date_pay ) formData.date_pay = pay_opt.date_pay;
    
            if(pay_opt && pay_opt.bank_from ) formData.bank_from = pay_opt.bank_from;
    
            if(pay_opt && pay_opt.purpose ) formData.purpose = pay_opt.purpose;
    
            if(pay_opt && pay_opt.bank_account) formData.bank_account = pay_opt.bank_account;

            if(pay_opt && pay_opt.type_purpose) formData.type_purpose = pay_opt.type_purpose;

            formData.summ = 0;

            formData.customer = '';

            schema.title = 'Додати новий платіж';
    
        } else {
            
            const { date_pay, bank_from, purpose, iban, summ, status, type_purpose } = editdata;
    
            const bank = banks.find(el=>el.iban == iban);
    
            formData.date_pay = date_pay;
            formData.purpose = purpose;
            formData.summ = summ;
            formData.status = status;
            formData.bank_from = bank_from;
            formData.bank_account = JSON.stringify(bank);
            formData.customer = editdata.customer;
            formData.type_purpose = type_purpose;

            schema.title = 'Змінити дані платежу';
    
        }
    }

    fill_info();

    //useEffect(()=>fill_info());



    //if(!props.show) return <div/>;


    return (
         <Form
              schema={schema}
              autocomplete="off"
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={closeForm}>Закрити без змін</Button>
         </Form>
    );
}