/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectKg(props) {
  const classes = useStyles();

  const { data, onChange, default_v } = props;

  const [optionL, setOptions] = useState();

  const [def_value, setDefault] = useState();

  

  useEffect(()=>{

//    console.log('selectkg default_v', default_v);

      if(default_v && default_v.length == 1 && (!data || data.length == 0) ) {
        setOptions(default_v);
        setDefault(`ЗДО ${default_v[0].number} група ${default_v[0].groups.number} таб ${default_v[0].groups.children.smallNumber}`);
        return;
      }
      setOptions(data);
  })

  return (
    <Autocomplete
      id="country-select-demo"
      key={optionL && optionL.length || 0}
      //style={{ width: 300 }}
      options={(optionL || []).sort((a,b)=>a.number - b.number)}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}

      inputValue={ def_value }
      onInputChange={(_, newInputValue) => {
        setDefault(newInputValue)
      }}

      autoHighlight
      getOptionLabel={(option) => `ЗДО ${option.number} (${option.contacts && option.contacts.address.district.slice(0,4) || ''}.) група ${option.groups.number} таб ${option.groups.children.smallNumber}` }
      renderOption={(option) => (
        <React.Fragment>
          ЗДО {option.number} ({option.contacts.address.district.slice(0,4)}.) група {option.groups.number} таб {option.groups.children.smallNumber}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберіть садок"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

