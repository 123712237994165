/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectID(props) {
  const classes = useStyles();

  const { data, onChange } = props;

  const [optionL, setOptions] = useState();

  

  useEffect(()=>{
      console.log("SelectKg", data);
      setOptions(data);
  })

  return (
    <Autocomplete
      id="country-select-demo"
      key={optionL && optionL.length || 0}
      //style={{ width: 300 }}
      options={optionL}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      autoHighlight
      getOptionLabel={(option) => `ID ${option.id_rono_child}  ${option.fio_rono_child}  ${option.name_rono_group} ${option.full_name_rono_group}` }
      renderOption={(option) => (
        <React.Fragment>
          ID {option.id_rono_child}  {option.fio_rono_child}  {option.name_rono_group} {option.full_name_rono_group}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберить ID"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

