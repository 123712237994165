import React, { useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MTableAction } from "material-table";

import AddIcon from '@material-ui/icons/Add';

import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';

import AddButton from '../AddButton';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  function ListOfDivisions(props) {

    const classes = useStyles();

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
        {title:"Ім'я", field:"name_first"}, 
        {title:"Призвище", field:"name_last"},
        {
          title:"Роль", 
          field:"role",
          editComponent: x=>(
            <FormControl className={classes.formControl} size="small">
            <Select
              labelId="role_label"
              id="role"
              value={x.value}
              onChange={e => console.log(e, x)}
              
            >
              <MenuItem key={1} value="Not selected">
                Не обрано
              </MenuItem>
              <MenuItem key={2} value="superadmin">
                Суперадміністратор
              </MenuItem>
              <MenuItem key={3} value="buh">
                Бухгалтер
              </MenuItem>
              <MenuItem key={4} value="mod">
                Модератор району
              </MenuItem>
              <MenuItem key={5} value="kg">
                Директор садочку
              </MenuItem>
            </Select>
          </FormControl>

          )
        },
        {title:"Межи", field: "area"},
        {title:"РНКПО", field:"IPN"},
        {title:"E-mail", field:"email"},
        {title:"phone", field:"phone"},
/*        {
          title: "Додати нового користувача",
          field: "add_action",
          editable: false,
          render: (rowData) =>
            rowData && (
              <IconButton
                color="secondary"
                onClick={() => addActionRef.current.click()}
              >
                <AddIcon />
              </IconButton>
            )
        }
*/
    ];

    const [template_data, setTableData] = useState([
        {
            name_first:"Олексій",
            name_last:"Крівенко",
            role: "Суперадміністратор",
            area:"Система",
            IPN:2343376876,
            email:"slty@gioc.com",
            phone:"+380683646223"
        }
    ]);

    if(!props.show) return <div/>;

    return (
      <div style={{ maxWidth: "100%" }}>
        <div><Button >Додати нову роль </Button></div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Ролі"
          components={{
            Action: props=><AddButton {...props} />
          }}

          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }

          actions={[
            {
              icon: "save",
              tooltip: "Зберегти інформацію",
              onClick: (event, rowData) => alert(`Зберегти ${rowData.name_last}`)
            }
          ]}
          editable={{
            onRowAdd: (newData) =>
              Promise.resolve(setTableData([...template_data, newData]))
          }}
        />
      </div>
    );

}