import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {store, useGlobalState} from 'state-pool';
import jwt_decode from "jwt-decode";



export default function Sign(){

    const location = useLocation().search;
    const history = useHistory();

    const params = location.slice(1).split(",").map(el=>{ const pair = el.split("="); return ({key: pair[0], value: pair[1]}) } );

    const t=params.findIndex(p=>p.key == "t");
    const u=params.findIndex(p=>p.key == "u");

    const user_info = jwt_decode(params[t].value);
    const url = params[u].value;

    const [auth, updateAuth, setAuth] = useGlobalState("auth");

    updateAuth( auth=>({ user: user_info, token: params[t].value } ) );
    localStorage.removeItem('auth');
    


    useEffect( ()=>{
        localStorage.setItem('auth',  params[t].value );
        history.push(url);    
    });
    

    return null;

}