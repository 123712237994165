
export  const add_new_class = async ({data, headers})=>{

    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ data: data})
    };

    const link = `/v1/add_class`;

    const res = await fetch(link, options);

    if(!res.ok) {
      console.log("error by fetch", link);
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;

  }

export  const edit_class = async ({data, headers})=>{

  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify({ data: data})
  };

  const link = `/v1/edit_class`;

  const res = await fetch(link, options);

  if(!res.ok) {
    console.log("error by fetch", link);
    alert("Error by sending req");
    return;
  }

  const info = await res.json();

  return info;

}