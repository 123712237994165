const key_def = {
    child_id:['iд', 'ід', 'id'],
    buch_number:['цб'],
    kg_number: ['здо', 'сад', 'днз', 'садок', 'дз', 'заклад', 'зак', 'здшо'],
    group_number: ['група', 'гр', 'группа'],
    tab_number: ['таб'],
    tel_number: ['тел'],
    name_child: ['піб', 'дитини'],
    pin: ['пін']
};

const max_word_key = {
    child_id: 1,
    buch_number: 1,
    kg_number: 1,
    group_number: 1,
    tab_number: 1,
    tel_number: 1,
    name_child: 2,
    pin: 1
};

const min_word_length = {
    child_id: 5,
    buch_number: 1,
    kg_number: 1,
    group_number: 1,
    tab_number: 3,
    tel_number: 6,
    name_child: 2
};



const set_keys_index = (arr_word)=>{
    const keys_index = [];
    for(let index = 0; index < arr_word.length; index++){
        for(let key of Object.keys(key_def) ) {
//            console.log(key_def[key]);
            if( key_def[key].includes(arr_word[index]) ) {
                keys_index.push({index: index, key: key});
            } 
        }
    }

    return keys_index;
}

const set_values_index = (arr_word, keys_index)=>{
    
    const values_index = [];
    
    for(let index = 0; index < arr_word.length; index++){
     if(!keys_index.find(el=>el.index == index)){
        values_index.push({index: index, value: arr_word[index]});
      }
    }

    return values_index;
}

const fill_obj_field = (key_target, arr_length, keys_index, values_index)=>{

    let value = [];

    const key_el_indx = keys_index.findIndex(el=>el.key == key_target);

    if(key_el_indx < 0) return '';
    
    const key_el_index = keys_index[key_el_indx]['index'];
    
    const length_words_to_next = key_el_indx < (keys_index.length - 1) ? keys_index[key_el_indx + 1]['index'] - key_el_index : arr_length - 1 - key_el_index;

    if(length_words_to_next < 1) return '';

    const indx_val = values_index.find(el=>el.index == ( keys_index[key_el_indx]['index'] + 1 ) );
    if(!indx_val) return '';

//    console.log(`max_word_key[keys_index[key_el_indx]['key']]`, max_word_key[keys_index[key_el_indx]['key']]);

    const to_index = max_word_key[keys_index[key_el_indx]['key']] < length_words_to_next ? indx_val['index'] + max_word_key[keys_index[key_el_indx]['key']] : indx_val['index'] + length_words_to_next;

    for(let val_index = indx_val['index']; val_index < to_index; val_index++ ){
        const val_obj = values_index.findIndex(el=>el.index == val_index);
        if(val_obj > -1) value.push(values_index[val_obj]['value']);
        values_index.splice(val_obj, 1);    
    }
    
    

    return value.join(' ').trim();
}

const fill_obj = (arr_norm_word)=>{
    
    const obj_res = {
        child_id: "",
        child_name: "",
        child_tab: "",
        kg_number: "",
        group_number: ""
    };
    
    const keys_index = set_keys_index(arr_norm_word);
    const values_index = set_values_index(arr_norm_word, keys_index);

    const len_arr = arr_norm_word.length;

    const parse_pin = (pin)=>{
        if (pin.length != 11) return null;
        return ({ kg: pin.slice(3, 6) });
    }

    const ch_numb = {
        'пролісок': ['807'],
        'монтессорі сад': ['820'],
        'шдс \"перевесло\"': ['245'],
        'шдс \"радосинь\"': ['133'],
        'шдс \"паросток\"': ['802'],
        'шдс \"перлина\"': ['103'],
        'шдс \"дивоцвіт\"': ['695'],
        'шдс \"ім.с.русової\"': ['584'],
        'шдс поділля': ['734'],
        'шдс \"родзинка\"': ['765'],
        'НВК \"Вікторія\"':['813'],
        'Усмішка':['818']
    }

    const stop_words = ['сплата', 'сплату', 'плата', 'за', 'харчування', 'дитини', 'батьк', 'в', 'дитячому', 'дитячий',  'закаді', 'садочку', 'батьківська', 'подільське', 'уо', 'дит', 'док', 'від', 'грудень', 'року', 'освіти', 'без', 'комісії', 'рахунок', , 'оплата', 'суму', 'кс', 'на', 'нв', '', 'києв', 'червня'];

    obj_res.child_id = fill_obj_field('child_id', len_arr, keys_index, values_index);
    obj_res.child_name = fill_obj_field('name_child', len_arr, keys_index, values_index);
    obj_res.child_tab = fill_obj_field('tab_number', len_arr, keys_index, values_index);
    obj_res.kg_number = fill_obj_field('kg_number', len_arr, keys_index, values_index);
    obj_res.group_number = fill_obj_field('group_number', len_arr, keys_index, values_index);
    obj_res.pin = fill_obj_field('pin', len_arr, keys_index, values_index);
    obj_res.buch_number = fill_obj_field('buch_number', keys_index, values_index);

    obj_res.rest = values_index.filter(el=>el.value.match(/^[а-яiіїьє]{0,20}$/) ).map(el=>el.value).filter(el=>!stop_words.includes(el));

    if(!obj_res.kg_number && obj_res.pin) {
        const inf_obj = parse_pin(obj_res.pin);
        if(inf_obj) obj_res.kg_number = inf_obj.kg;
    }

    for(let k in ch_numb){
        if(ch_numb[k].includes(obj_res.kg_number)) obj_res.kg_number = k;
    }

    if(obj_res.child_id.length < 5) obj_res.child_id = '';

    if(obj_res.child_id == ''){
        const find_number_id = arr_norm_word.find( el=>{const v = el.match(/\d+/); return v && v[0] && v[0].length == 6 } );

        if(!!find_number_id) obj_res.child_id = find_number_id.match(/\d+/)[0];

    }

    return obj_res;
}

function check_online(str){
    const elements  = str.toLowerCase().replace(/i/g, 'і').split(';');

    if(elements.length !=9) return false;

    if(!elements[3].split(/\//) || elements[3].split(/\//).length != 3 ) return false;

    if(!elements[2].trim().match(/^[А-яіє]+$/)) return false;

    console.log('check_online true');

    return true;
}

function normale_online(str){
    const [f, name, name_last, brday, kg, gr, gr_name, tab, id]  = str.toLowerCase().replace(/i/g, 'і').split(';');

    const obj_res = {
        child_id: id || "",
        child_name: `${name_last.trim()} ${name.trim()}` || '',
        child_tab: tab && tab.split(' ')[1] || '',
        kg_number: kg.split(' ').slice(1).join(' '),
        group_number: gr.split(' ')[1],
        pin: '',
        buch_number: '',
        rest: []
    };


    return obj_res;
}

export function ParsePayInfo(info){
    if(!info || info.length < 5) return;
    if(check_online(info)) return normale_online(info);
    
    const normal_str = info.toLowerCase()
    .replace(/\?/g, 'і')
    .replace('харчування дитини в дитячому закладі', '')
    .replace('закдад ', 'заклад ').replace(/д\/с/, 'здо ')
    .replace(/заклад д\/о/, 'здо ')
    .replace('№заклад дошкільної освіти №', ' ')
    .replace('заклад дошкільної освіти', 'здо ')
    .replace('заклад дошкольної освiти', 'здо')
    .replace(/ґ/g, 'є')
    .replace(/@\d+\w+/, '')
    .replace(/[a-h,j-z.;/'№:\(\)]/g, " ")
    .replace(/i/g, "і")
    .replace(/\?/g, "і")
    .replace(' д з ', ' здо ')
    .replace('подільське уо', ' ')
    .replace('сад здо', 'здо ')
    .replace(/\s\s+/g, ' ')
    .replace('в дитячому садочку', 'здо ')
    .replace('в дитячому закладі ', 'здо ')
    .replace('в закладі дошкільної освіти', 'здо ')
    .replace('заклад дош освіти', 'здо ')
    .replace('заклад дошк освіти', 'здо ')
    .replace('заклад дошк осв', 'здо ')
    .replace('за харчування дитини в дитячому закладі №', 'здо ')
    .replace('за харчування дитини в дитячому закладі', ' ')
    .replace('дитячому дошкільному закладі ', 'здо ')
    .replace('дитини в дитячому закладі ', 'здо ')
    .replace('у дитячому закладі ', 'здо ')
    .replace('в дитячому закаді', ' ')
    .replace('дит закл ', 'здо ')
    .replace('дит садку', 'здо ')
    .replace("№", " ")
    .replace("#", " ")
    .replace(/iд/, 'ід ')
    .replace(/ид/, 'ід ')
    .replace(/цб/, 'цб ')
    .replace(/здо/, 'здо ')
    .replace("пiб дитини за", 'піб ')
    .replace("піб дитини", 'піб ')
    .replace(/піб/, ' піб ')
    .replace('заклад здо', 'здо ')
    .replace('садочок', 'здо ')
    .replace(/група/, 'група ')
    .replace('таб ном ', "таб ")
    .replace("табельний номер ", "таб ")
    .replace("таб номер ", "таб ")
    .replace(/таб/, 'таб ')
    .replace(/тел/, 'тел ').replace(/\s\s+/g, ' ')
    .replace('власні надходження розпорядників', ' ')
    .replace('оплата за харчування', ' ')
    .replace('пiб дитини', 'піб ')
    .replace('заклад дошкiльної освiти', 'здо')
    .replace('заклад дошкільної освіти', 'здо')
    .replace('заклад', 'здо')
    .trim();
    const arr_norm_word = normal_str.split(/\s/);

    return fill_obj(arr_norm_word);
} 