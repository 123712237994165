import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import EditTarif from '../AddTarif';

import { get_tarifs } from './actions';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selStudents: {
    display: 'block',
    textAlign: 'end'
  },
  selectKg:{
    maxWidth: '500px',
    paddingLeft: '10px',
    paddingBottom: '10px'
  },
  closeButton:{
    cursor: 'pointer',
    fontSize: '15px',
    padding: '5px',
    backgroundColor: '#eeeeee',
    display: 'inline-block',
    borderRadius: '2px',
    margin: '5px'
  }
}));

const getLocalTime = (str) => {
  const [day, month, year] = new Date(str).toLocaleDateString('uk').split('.');
  return [year,month,day].join("-");
}

const types_tarif = {
  "eat": "Батьківська сплата за харчування","bfn":"Послуга утримання дитини"
};

export default function Tarifs(props) {

    const classes = useStyles();

    const { show, data, close, headers, kgs_list } = props;

    const tableRef = React.createRef();

    const columns = [
      {title:'Тариф', field: 'tarif'},
      {title:'Послуга', render: (data)=>types_tarif[data.type_tar]},
      {title:'Дата початку дії', field: 'date_begin'},
      {title:'Дата закінчення дії', field: 'date_end'}
    ];

    

    const [template_data, setTableData] = useState([]);

    const [shEditTarif, setSchowEditTarif] = useState();

    const [tarifData, setTarifData] = useState([]);

    const setDataTarifs = async ()=> {
      
      const { id_school, id_class } = data;

      const info = await get_tarifs({ id_school, id_class }, headers);

      if(!info || info.length < 1) {
        setTableData([]);
        return;
      }

      setTableData(info.map(r=>{
        const tarif = r.tarif + "";
        r.date_begin = getLocalTime(r.date_begin);
        r.date_end = getLocalTime(r.date_end);
        r.tarif = +`${tarif.slice(0, tarif.length - 2 )}.${tarif.slice(tarif.length - 2 )}`;
        r.type_tar = r.type_tar.trim();
        return r;
      }));

    };

    useEffect(()=>{
      console.log(`data '${JSON.stringify(data) }'`)
      if(!data || !data.id_class) return;
      setDataTarifs();
    },[data && data.id_class])




    const setEditTarif = (data)=>{
      setTarifData(data);
      setSchowEditTarif(true);
    }

    const closeEditTarif = (state) =>{
      if(state == 'updated') setDataTarifs();
      setSchowEditTarif(false);
    }

    if(!show) return null;

    if(shEditTarif) return <EditTarif data={kgs_list} data_tarif={tarifData} show={shEditTarif} close={closeEditTarif} headers={headers} />

    console.log(`template_data ${JSON.stringify(template_data)}`);

    return (
      <div style={{ maxWidth: "100%" }}>
        <div onClick={close} className={classes.closeButton}>Закрити та повернутись</div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Редагування тарифу"
          actions={[
            {
              icon: 'add',
              tooltip: 'Додати новий тариф',
              isFreeAction: true,
              onClick: (event) => setEditTarif(data)
            },
            rowData => ({
              icon: 'edit',
              tooltip: 'редагувати',
              onClick: ()=>setEditTarif(rowData)
            })
          ]}
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50]
          }}
        />
      </div>
    );

}