// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import Button from '@material-ui/core/Button';

import SelectKg from '../../../payments/components/SelectKg';
import WidgetChildrenSelect from '../../../payments/components/GroupSelectChildren';

import { store, useGlobalState, useLocalState } from 'state-pool';

import { localDate } from '../../../../helpers/timedata';



// Internals
const uiSchema = require('./ui-schema.json');




export function AddEditBFN (props) {

    const { close, addBfn, groups, listKgs, headers_auth,  editdata } = props;

    const [pay_opt, updatePayOpt, setPayOpt] = useGlobalState("addPay");

    const [option_kg, setOptionsKg] = useState([]);

    const [benefits_list, setBenList] = useState([]);

    let formData = {};
    let schema = {};


    
    const CustomSelectKg = (props)=><SelectKg 
        data={option_kg}
        onChange={(event, value) => props.onChange(value && value._id || '') }
    />


    const set_kg_by_name = async ( {name_last, name_first} )=>{
        const response = await fetch(`/v1/find_child_by_name?name_last=${encodeURI(name_last)}&name_first=${encodeURI(name_first)}`, { headers: headers_auth });
        const data = await response.json();
        setOptionsKg(data);
       };

    const WidgetSelectCh = (props)=><WidgetChildrenSelect headers_auth={headers_auth} set_kg_by_name={set_kg_by_name} groups={groups} list_kg={option_kg} onChange={(data) => props.onChange(data|| '')} editdata={props.value} />
        


    const widgets = {
        CustomSelectKg:CustomSelectKg,
        CustomSelectChildren:WidgetSelectCh
        };


    const onSubmit = (value, callback) => {

        const info = Object.assign({}, value.formData );

        const { child, kginfo } = JSON.parse(info.customer);

        info['child_id'] = kginfo.groups.children.childId;
        info['kg_id'] = kginfo._id;
        info['name_last'] = child.name_last;
        info['name_first'] = child.name_first;
        info['kg_number'] = kginfo.number;
        addBfn(info);
    }
    
    const onCancel = () => {
    }
    
    const onFormChanged = ({ formData }) => {
    }
    
    const onUpload = (value) => {
    }

    const closeForm = () => {
        close();
    }

    const fill_info = ()=>{


        schema = JSON.parse(JSON.stringify( require('./schema.json') ) );

        formData = JSON.parse(JSON.stringify( require('./formData.json') ) );

        const curDate = new Date().toISOString().split('T')[0];

        const [year, month] = curDate.split('-');


        if(!editdata || Object.keys(editdata).length == 0) {

            formData.customer = '';

            formData.date_from = curDate;
            formData.date_to = `${year}-01-01`;
    
        } else {
            
            const { date_from, date_to, customer, status } = editdata;

            const customer_obj = JSON.parse(customer);

            if(customer_obj && !customer_obj.child['sezzdo_name_last']) customer_obj.child['sezzdo_name_last'] = customer_obj.child.name_last;
            if(customer_obj && !customer_obj.child['sezzdo_name_first']) customer_obj.child['sezzdo_name_first'] = customer_obj.child.name_first;

            if( customer_obj && !customer_obj.kginfo.contacts) customer_obj.kginfo['contacts'] = { address: { district: '' }};


            formData.date_from = localDate( date_from );
            formData.date_to = localDate( date_to );
            formData.customer = JSON.stringify(customer_obj);
            formData.status = status;

            schema.title = 'Змінити інформацію про платника БФН';

        }
    }

    fill_info();

    return (
         <Form
              schema={schema}
              autocomplete="off"
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={closeForm}>Закрити без змін</Button>
         </Form>
    );
}