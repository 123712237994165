/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export function SelectKg(props) {


  const { data, labels, onChange, value } = props;


  const [options, setOptions] = useState({});

  useEffect(() => {

    if(options && Object.keys(options) && Object.keys(options).length > 1) return;

      const lblInfos = {};

      data.forEach((key, index)=>{
          lblInfos[key] = labels[index];
      })

      setOptions(lblInfos);

  }, [data && data.length]);



  return (
    <Autocomplete
      id="country-select-demo"
      options={data}
      onChange={onChange}
      value={ value }
      autoHighlight
      getOptionLabel={(option) => options[option]}
      renderOption={(option) => (
        <React.Fragment>
          {options[option]}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберить садочок"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

