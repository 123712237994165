// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import TextField from "@material-ui/core/TextField";

import Button from '@material-ui/core/Button';
import AlertDialog from '../../../../helpers/alertdialog';
import SelectClass from '../SelectClass';

import { add_student, set_student } from './actions';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";


const infoAlertObj = { title: "Оновлення даних", text:"Перевирте та натисніть зберегти або відминити ", btn_agr:"Зберегти", btn_dsg: "Відмінити збереження" }; 



// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');

export default function FormAddChildrenSchool (props) {

    const { close, addSchool, saveNewShool, data_kg,  data, update, headers } = props;

    const isEdit = data && data.id_class;

    const formData =  isEdit ? data : require('./formData.json');

    const [valueСl, setValue] = useState();

    const [showAlert, setShowAlert] = useState(false);

    const [infoAlert, setInfoAlert] = useState(infoAlertObj);

    const [callbackAlert, setCallbackAlert] = useState(false);


    const SelectClassW = (props) => <SelectClass
        initSelKg = {data && data.id_school || null}
        selClass={props.value}
        kg_options={data_kg} 
        onChange={(event, value)=> {
            const id_class = value && value.id || "";
            props.onChange(id_class+"");
        }} 
        headers={headers}/>

    

    const widgets = {
        SelectClass: SelectClassW
    };

    const saveUpdate = ({ formData })=>{
        setCallbackAlert(()=>onSubmit(formData));
        setShowAlert(true);
    }


    const onSubmit = async ({formData}) => {
        const { id_class, firstName, lastName, tabNumber } = formData;

        console.log(`formData ${JSON.stringify(formData) }`);
        const result = isEdit ? await set_student(formData, headers) : await add_student(formData, headers);
        close();
    }
    
    const onCancel = () => {
        //console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        //console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        //console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const closeAlert = (res, callback) =>{
        if(res) callback();
    }

    if(!props.show) return <div/>;

    if(isEdit) schema.title = "Внести зміни до картки дитини"
    if(!isEdit) schema.title = "Додати дитину до класу(групи)"
  
    return (
         <div>
             <AlertDialog open={showAlert} info={infoAlert} close={closeAlert} callback={closeAlert}/>
             <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={close}>Закрити без змін</Button>
         </Form>
         </div>
    );
}