import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import {Grid} from '@material-ui/core';
import { ChargeBill } from './SearchCB/chargebill';
import { Payments } from './SearchCB/payments';

import { get_accounts_list } from './actions';


const columns = [
    { title: 'ЦБ', field: 'buch_id', editable: 'never' },
    { title: 'Заклад', field: 'kg_number', editable: 'never' },
    { title: 'Група', field: 'group_number', editable: 'never' },
    { title: 'ІД', field: 'gioc_id', editable: 'never' },
    { title: 'ПІБ', field: 'fullname', editable: 'never' },
    { title: 'Таб. номер', field: 'tab', editable: 'never' },
]


  const useStyles = makeStyles((theme) => ({

    selectKg:{
      maxWidth: '500px',
      paddingLeft: '10px',
      paddingBottom: '10px'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    labelLoad:{
      verticalAlign: '-27px'
    },
    label:{
      verticalAlign: 'super',
      padding: '10px'
    },
    go_button:{
      paddingLeft: '35px'
    },
    buttons_div: {
      cursor: 'pointer',
      "&:hover, &:focus": {
        fontWeight: "bold",
        color: "#303F9F"
      }
    }
  }));

  const check_filters = (filters)=>{
    let result = true;
    filters.forEach(f=>{
      const { column, value } = f;
      const { field, type, tableData } = column;
      if(type == 'number' && isNaN(value)) result = false;
    })
    return result;
  }



  export  function SearchCB(props) {

    const tableRef = React.createRef();

    const classes = useStyles();

    const[shScreen, setShScreen] = useState('main');
    const[childData, setChildData] = useState(null);

    const [size_of_content, setSizeContent ] = useState(17);

    const[vis_control, setVisControl] = useState('hidden');

    const[timer, setPromT] = useState(0);

    const { show, data, headers } = props;



    const later = (delay, value) => {
      let reject = null;
      const promise = new Promise((resolve, _reject) => {
          reject = _reject;
          setPromT(setTimeout(resolve, delay, value));
      });
      return {
          get promise() {
            if(timer) clearTimeout(timer);
            return promise; 
          },
          cancel() {
              if (timer) {
                  clearTimeout(timer);
                  timer = 0;
                  reject();
                  reject = null;
              }
          }
      };
  };


    const getData = (query) => {
      return later(2000).promise
          .then(()=>getData_b(query));
    }
    
    const getData_b = (query) => 
    { if(!headers) return;

      if(!check_filters(query.filters)) return ({
        data: [],
        page: 0,
        totalCount: 0,
      });


      let { search, filters, page, pageSize, orderDirection,  orderBy } = query;

      if(!orderBy || !orderBy.field) {
        orderBy = {field: 'created_at'};
        orderDirection = 'desc';
      };

      let filterq = filters.map(filt=>{
        
        const { column, value } = filt;
        const { field, type, tableData } = column;

        return `${field ? field : 'col_' + tableData.columnOrder}=${type == 'date' ? new Date(value).toISOString() : value}`;

      }).join('&');

      return new Promise((resolve, reject) => {

      let url = '/v1/get_childrens_saldo?'
      url += 'per_page=' + pageSize
      url += '&page=' + (page + 1)
      //url += '&buchid=' + buch_sel
      if(filters && filterq) url += `&${filterq}`;

      if(query && search) url += `&search=${search}`;

      if(query && orderDirection) url += `&ordirection=${orderDirection}`;
      if(query && orderBy && orderBy.field) url += `&ordby=${orderBy.field}`;

      fetch(url, { headers: headers})
        .then(response => response.json())
        .then(result => {
          if(result && result.data && result.data.length > 0) {
            setVisControl('visible');
          } else {
            setVisControl('hidden');
          }
          resolve({
            data: result.data,
            page: result.page - 1,
            totalCount: result.totalCount,
          })
        })
    });
    }

    const closeAddInfo = ()=>{
      setChildData(null);
      setShScreen('main');
    }

    const openBillInfo = (data) => {
      setChildData(data);
      setShScreen('bill');
    }

    const openPaymentsInfo = (data) => {
      setChildData(data);
      setShScreen('payments');
    }

    if(!show) return null;

    return (
            <div style={{ maxWidth: "100%" }}>
              <Grid container spacing={1} alignItems="baseline">
              <Grid item xs={12}>
              <div className={classes.size_control} style={{ visibility: vis_control}}>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content < 30) setSizeContent(size_of_content + 1) } }>+ Збільшити шрифт в таблиці</div>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content > 10) setSizeContent(size_of_content - 1) } }>- Зменьшити шрифт в таблиці</div>
        </div>

        <div style={{ fontSize: size_of_content + 'px'}}>
              { shScreen == 'main' && <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={getData}
                title=""
                editable={{
                    isEditable: true 
                }}

                options={{
                    filtering: true,
                    pageSize: 10,
                    pageSizeOptions: [5,10,20],
                    cellStyle: {
                      lineHeight: '0.9em',
                      marginTop: '1px'
                    }
                }}

                actions={[
                {
                    icon: 'account_balance_wallet',
                    tooltip: 'Поточні нарахування',
                    onClick: (event, rowData) => {
                      openBillInfo(rowData)
                    }
                },
                {
                    icon: 'how_to_vote',
                    tooltip: 'Оплати/коригування за період',
                    onClick: (event, rowData) => {
                      openPaymentsInfo(rowData)
                    }
                }]
            }

                />}
                </div>
                {shScreen == 'bill' && <ChargeBill data={childData} close={closeAddInfo} headers={headers} show={shScreen == 'bill'}/>}
                {shScreen == 'payments' && <Payments data={childData} close={closeAddInfo} headers={headers} show={shScreen == 'payments'}/>}
                </Grid>
              </Grid>
        </div>
    )
}