import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import Grid from "@material-ui/core/Grid";
import {Link, Typography, Select, MenuItem, TextField} from '@material-ui/core';
import AddButton from './AddButton';

import BaseDialog from "../../dialogs/BaseDialog";

import SelectKg from './SelectKg';

import  EditChildren  from './EditChildren';

import { localDate, nextMonth } from '../../../helpers/timedata';


const endpoints = {
  saldo:{ url: "/v1/push_saldo", type: "POST", isLoad: false },
  saldo_date:{ url: "/v1/get_saldo_date", type: "GET", isLoad: false },
  saldo_report: { url: "/v1/saldo_report", type: "GET", isLoad: false }
};


const useStyles = makeStyles((theme) => ({

  selectKg:{
    maxWidth: '500px',
    paddingLeft: '10px',
    paddingBottom: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  labelLoad:{
    verticalAlign: '-27px'
  },
  select_period:{
    verticalAlign: 'bottom',
    paddingLeft: '20px',
    marginLeft: '10px'
  },
  date_saldo: {
    fontSize: 'small',
    padding: '10px',
    color: 'blue'
  },
  buttons_div: {
    cursor: 'pointer',
    "&:hover, &:focus": {
      fontWeight: "bold",
      color: "#303F9F"
    }
  }
}));



export  function Childrens(props) {

    const classes = useStyles();

    const { show, data, headers, role } = props;

    const [show_edit, setShowEdit] = useState({show:false, data:{}});

    const [saldo_date, setSaldoDate] = useState();

    const [selectedKg, setSelectedKg] = useState();

    const [size_of_content, setSizeContent ] = useState(17);
    const[vis_control, setVisControl] = useState('hidden');

    const [shLoadReport, setShLoadReport] = useState(false);


    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
      { title: 'Прізвище, Імꞌя', field: 'fullname' },
      { title: 'Пільга', field: 'pilga'},
      { title: 'Група', field: 'group', defaultSort: 'asc'},
      { title: 'Табельній номер', field: 'tab'},
      { title: 'Номер ГІОЦ', field: 'gioc_id'},
      { title: 'Сплачено за період', field: 'payment' },
      { title: 'Тариф', field: 'tarif'},
      { title: 'Тип місця', field: 'place' },
      { title: 'дебет на початок м', field: 'deb_begin'},
      { title: 'кредит на початок м', field: 'cred_begin'},
      { title: 'Дні відвідування', field: 'days' },
      { title: 'Фактично нараховано', field: 'charged' },
      { title: 'Сума пільг', field: 'bensum' },
      { title: 'Батьківська сплата', field: 'parpay' },
      { title: 'Кор нар дебет', field: 'correction_deb' },
      { title: 'Кор нар кредит', field: 'correction_cred' },
      { title: 'Кор плат дебет', field: 'correction_deb_pay' },
      { title: 'Кор плат кредит', field: 'correction_cred_pay' },
      { title: 'дебет на кінець м', field: 'deb_end' },
      { title: 'кредит на кінець м', field: 'cred_end' },
      { title: 'До сплати', field: 'to_payd' },
    ];

   
    const [children_data, setTableData] = useState([]);
    
    const [kgs_list, setKgList] = useState();

    const [selected_kg, selectKg] = useState();

    const [display, setShow] = useState(false);

    const [periods, setPeriods] = useState([]);

    const [period_sel_op, setPeriodSelOp] = useState([]);
    const [period_sel_sl, setPeriodSelSl] = useState([]);

    const [show_table, setShowTable] = useState(false);
    const [chReport, setChReport] = useState({});

    
    useEffect(()=>{
      set_period();
      setShow(show);
    },[show])


    useEffect(()=>{

      if(!period_sel_sl || !selectedKg) return;

      set_state_saldo_date(selectedKg, period_sel_sl);

    },[period_sel_sl, selectedKg])


    const get_childrens_list = async (kg) =>{
      const cur_date = new Date();
      const dateTo = cur_date.getMonth() == 0 ? `${cur_date.getFullYear() - 11 }-12-1` : `${cur_date.getFullYear()}-${cur_date.getMonth()}-1`;
      const url = `/v1/get_children_by_kg?kg_id=${kg}&period=${cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 }&date_to=${dateTo}`;
      const response = await fetch(url, { headers: headers });
      const data = await response.json();
      return data.data || [];
    }

    const get_childrens_list_1 = async (kg) =>{

      console.log('get_childrens_list', kg);
      const cur_date = new Date();
      const dateTo = cur_date.getMonth() == 0 ? `${cur_date.getFullYear() - 11 }-12-1` : `${cur_date.getFullYear()}-${cur_date.getMonth()}-1`;
      const url = `/v1/get_children_by_kg?kg_id=${kg}&period=${cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 }&date_to=${dateTo}`;
      const response = await fetch(url, { headers: headers });
      const data = await response.json();

      if(cur_date.getMonth == 7 && cur_date.getDate > 15) {
        const url_1 = `/v1/get_children_by_kg?kg_id=${kg}&period=${cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 }&date_to=${dateTo}`;
        const response_1 = await fetch(url_1, { headers: headers });
        const data_1 = await response.json();

        const ch_l_1 = data.data.map(ch=>ch._id);

        const filtr = data_1.data.filter(el=>!ch_l_1.includes(el._id));

        data.data = [...data.data, ...filtr.data ]; 
      }

      return data.data || [];
    }

    const get_report = async (date_for)=>{
      const periodDate = new Date(date_for);
      const periodStartYear = periodDate.getMonth() > 8 ? periodDate.getFullYear() : periodDate.getFullYear() - 1 ;
      
      const date_to= `${date_for.split('-')[1]}.${date_for.split('-')[0]}`;

      const url = `/v1/rep_consolidate_kg?kg_id=${selected_kg}&period=${periodStartYear}&date_to=${date_to}`;
      const file = await fetch(url, { headers: headers });
      if(file.status != 200) return;
      const file_name = file.headers.get('content-disposition').split("filename=")[1];
      const blob = await file.blob(); 
      let url_file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
          a.href = url_file;
          a.id = 'rep_file'
          a.download = file_name;
          a.click();
    }


    const set_period = ()=>{
      
      const start_pos = new Date('2021-01-01');

      const curPeriod = new Date(new Date().setDate(1)) ;

      const months = ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень']

      const arr_periods = [];

      for( let pos = start_pos; pos <= curPeriod; pos = new Date(pos.getFullYear(), pos.getMonth() + 1, 1, 2, 59)){
        arr_periods.push({ value: localDate(pos), label: `${months[pos.getMonth()]} ${pos.getFullYear() }`})
      }


      setPeriods(arr_periods);
      setPeriodSelOp(arr_periods[arr_periods.length -1]['value']);
      setPeriodSelSl(arr_periods[arr_periods.length -2]['value']);
    }



    const fix_saldo = async (date_for)=>{

      if(saldo_date && !window.confirm('Дані будуть оновлені на поточний стан. Продовжити?')) return;

      const options = {
        method: endpoints.saldo.type,
        headers: headers,
        body: JSON.stringify({ info: {} })
      };

      const [year, month ] = nextMonth(date_for).split('-');

      const numb_days = new Date(year, month -1, 0).getDate();

      const period_gr = date_for.split('-')[1] > 8 ? date_for.split('-')[0] : +date_for.split('-')[0] - 1;

      const params = `?kg_id=${selected_kg}&period=${period_gr}&period_days=${numb_days}&date_to=${month}.${year}`;
      
      const res = await fetch(endpoints.saldo.url + params, options);

      if(!res.ok) {
        console.log("error by fetch", endpoints.saldo);
        alert("Error by sending req");
        return;
      }
  
      const info = await res.json();
  
      return info;
    }

    const get_saldo_date = async (kg_id, date_to)=>{

      const period_days = new Date( new Date(date_to).setDate(0) ).getDate();

      const options = {
        method: endpoints.saldo_date.type,
        headers: headers
      };

      const params = `?kg_id=${kg_id}&period_days=${period_days}&date_to=${date_to}`;
      
      const res = await fetch(endpoints.saldo_date.url + params, options);

      if(!res.ok) {
        console.log("error by fetch", endpoints.saldo_date);
        alert("Error by sending req");
        return;
      }
  
      const info = await res.json();
  
      return info;

    }

    const get_saldo_report = async (period)=>{
      const url = `/v1/saldo_report?kg_id=${selected_kg}&period=${period}`;
      const file = await fetch(url, { headers: headers });
      if(file.status != 200) return alert('Не має даних для цього періоду');
      const file_name = file.headers.get('content-disposition').split("filename=")[1];
      const blob = await file.blob(); 
      let url_file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
          a.href = url_file;
          a.id = 'rep_file'
          a.download = file_name;
          a.click();
    }

    const set_state_saldo_date = async (kg_id, date)=>{
      const res = await get_saldo_date(kg_id, nextMonth(date) );
      setSaldoDate( res && res.length > 0 ? res[0]['created_at'] : null );
    }

    const get_invites = async (e) => {
      e.preventDefault();
      const cur_date = new Date();

      const per_learn = cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1;

      const per_year = cur_date.getDate() > 25 && cur_date.getMonth() == 11  ? cur_date.getFullYear() + 1 : cur_date.getFullYear();

      const per_month = cur_date.getDate() > 25 && cur_date.getMonth() == 11  ? 1 : cur_date.getDate() > 25 ? cur_date.getMonth() + 2 : cur_date.getMonth() + 1;

      const url = `/v1/invites_list_kg?kg_id=${selected_kg}&period=${per_learn}&date_to=${per_month}.${per_year}`;

      const file = await fetch(url, { headers: headers });

      if(file.status != 200) return;

      const file_name = file.headers.get('content-disposition').split("filename=")[1];
      const blob = await file.blob(); 
      let url_file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
          a.href = url_file;
          a.id = 'rep_file'
          a.download = file_name;
          a.click();
    }

    const get_periods_kg = async (e) => {
        e.preventDefault();
        const cur_date = new Date();

        const url = `/v1/periods_kg?kg_id=${selected_kg}&period_from=2021-01-01&period_to=${cur_date.toISOString().split('T')[0]}`;


        const file = await fetch(url, { headers: headers });

        if(file.status != 200) return;

        const file_name = file.headers.get('content-disposition').split("filename=")[1];
        const blob = await file.blob(); 
        let url_file = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
            a.href = url_file;
            a.id = 'rep_file'
            a.download = file_name;
            a.click();
    }

    const get_periods_child = async () => {

        const { child_id, gioc_id, date_from, date_to } = chReport;

        const url = `/v1/rep_child?child_id=${child_id || ''}&gioc_id=${gioc_id || ''}&period_from=${date_from}&period_to=${date_to}`;

        const file = await fetch(url, { headers: headers });

        if(file.status != 200) return;

        const file_name = file.headers.get('content-disposition').split("filename=")[1];
        const blob = await file.blob(); 
        let url_file = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
            a.href = url_file;
            a.id = 'rep_file'
            a.download = file_name;
            a.click();
        setShLoadReport(false);
    }

    const constr_data = async (kg)=>{
      const data_sez_zdo = await get_childrens_list(kg);

      

      //setTableData(data_sez_zdo);
    }

    const select_kg = (event, value) =>{

     if(!value) return;

      const { kg_id } = value;

      if(!kg_id) {
        
      } else {
        setSelectedKg(kg_id);
        selectKg(kg_id);
        //constr_data( kg_id );
        info_table(kg_id);
      }
    }

    const addNewBenefit = ()=>{
      props.addBenefit(true);
    }

    
    useEffect(()=>{

      if(show_edit.show) return;
        const info = [];
      
    for(let el of data){
      const { kg_id, number, name, edrpou, property, contacts } = el._id;

      const groups = el.groups;

      const district = contacts.address.district || "Київ"

        const row_kg = {
          kg_id: kg_id,
          kg_number: number,
          kg_name: name,
          edrpou: edrpou,
          property: property,
          district: district
        };
        info.push(row_kg);
      }
      
      setKgList(info);

    },[data.length, show_edit])

    const load_report_pre = (e)=>{
      e.preventDefault();
      get_report(new Date());
    }

    const load_report_op = (e)=>{
      e.preventDefault();

      get_report( nextMonth(period_sel_op) );
    };



    const load_report_sl = (e)=>{
      e.preventDefault();
      get_saldo_report(nextMonth(period_sel_sl) );
    }

    const edit_child = (event, rowData) => {
      // Do save operation
      setShowEdit({ show: true, data: rowData});
    }

    const close_edchild = ()=>{
      constr_data(selected_kg);
      setShowEdit({ show: false, data:{}});
    }

    const fix_saldo_per = async (e)=>{
      e.preventDefault();
      if(role[0] != 'kyiv') return;
      await fix_saldo(period_sel_sl);
      set_state_saldo_date(selected_kg, period_sel_sl);
    }

    let title_saldo = '';
    
    if(role[0] == 'kyiv' && periods && periods.length > 0 ) title_saldo = saldo_date ? `Сальдо за ${ periods.find(pr=>pr.value == period_sel_sl).label} ${new Date(saldo_date).toLocaleDateString('uk')} ${new Date( new Date(saldo_date).getTime() ).toLocaleTimeString('uk')} (Оновити?)`
            :`Зафіксувати сальдо за ${periods.find(pr=>pr.value == period_sel_sl).label}`;
    
    if(role[0] != 'kyiv' && periods && periods.length > 0 ) title_saldo = saldo_date ? `Сальдо за ${ periods.find(pr=>pr.value == period_sel_sl).label } зафіксовоно від ${new Date(saldo_date).toLocaleDateString('uk')} ${new Date( new Date(saldo_date).getTime() ).toLocaleTimeString('uk')}`
      :`Cальдо за ${periods.find(pr=>pr.value == period_sel_sl).label}  ще не сформовано`;

    const handlePeriodSelectOp = (e)=>{
      setPeriodSelOp(e.target.value);
    }

    const handlePeriodSelectSl = (e)=>{
      const {value } = e.target;
      setPeriodSelSl(value);
    }

    useEffect(()=>{

      if(!selectedKg || !period_sel_op) return;

      info_table(selectedKg);

    },[period_sel_op])

    const info_table = async (kg_id)=>{

      const date_for = nextMonth(period_sel_op);

      const periodDate = new Date(date_for);
      const periodStartYear = periodDate.getMonth() > 8 ? periodDate.getFullYear() : periodDate.getFullYear() - 1 ;
      
      const date_to= `${date_for.split('-')[1]}.${date_for.split('-')[0]}`;
      
        let url = `/v1/consol_inforeport_kg?kg_id=${kg_id}&period=${periodStartYear}&date_to=${date_to}`
        
        fetch(url, { headers: headers})
          .then(response => response.json())
          .then(result => {
                if(result && result.length > 0) {
                  setVisControl('visible');
                } else {
                  setVisControl('hidden');
                }
                setTableData(result);
                setShowTable(true);
              }
            )
    }


    if(!display) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <Grid container spacing={10} alignItems="baseline">
          <Grid item xs={7}>
            <div className={ classes.selectKg } >
              <SelectKg data={kgs_list} setId={selected_kg} onChange={select_kg} />
            </div>
          </Grid>
          <Grid item xs={5}>
          <Typography className={ classes.selectKg }>
          { selected_kg && <Link href="#" onClick={load_report_op} className={classes.labelLoad}> Завантажити оп.звіт за </Link>}
          { selected_kg &&  <Select className={classes.select_period} labelId="demo-simple-select-label"
                  id="buch-select"
                  value={period_sel_op}
                  onChange={handlePeriodSelectOp}>
                    { periods.map(period=><MenuItem value={period.value}>{period.label}</MenuItem>)}
              </Select> }
            </Typography>

            <Typography className={ classes.selectKg }>
            { selected_kg && <Link href="#" onClick={load_report_sl} className={classes.labelLoad}> Завантажити сальдо за </Link>}
            { selected_kg &&  <Select className={classes.select_period} labelId="demo-simple-select-label"
                  id="buch-select"
                  value={period_sel_sl}
                  onChange={handlePeriodSelectSl}>
                    { periods.slice(0,periods.length - 1).map(period=><MenuItem value={period.value}>{period.label}</MenuItem>)}
              </Select> }
            </Typography>
            <Typography>
            { selected_kg && role[0] == 'kyiv'? <Link href="#" onClick={fix_saldo_per} className={classes.labelLoad}>
                { title_saldo }
              </Link>:  selected_kg  && role[0] != 'kyiv' ? <span className={classes.date_saldo}>{title_saldo}</span> : ''
              }
            </Typography>
            <Typography >
              { selected_kg && <Link href="#" onClick={get_invites} className={classes.labelLoad}>
                { (new Date()).getDate() > 25 ? 'Завантажити рахунки на сплату за поточний місяць' : 'Завантажити рахунки на сплату за попередній місяць' }
              </Link>
              }
            </Typography>
            <Typography>
              {
                selected_kg && <Link href="#" onClick={get_periods_kg} className={classes.labelLoad}>'Завантажити звіт по періодам'</Link>
              }
            </Typography>
          </Grid>
        </Grid>
        <EditChildren close={ close_edchild } open={show_edit && show_edit['show']} data={show_edit['data']} headers={headers} kg_id={selected_kg}/>
        <div className={classes.size_control} style={{ visibility: vis_control}}>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content < 30) setSizeContent(size_of_content + 1) } }>+ Збільшити шрифт в таблиці</div>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content > 10) setSizeContent(size_of_content - 1) } }>- Зменьшити шрифт в таблиці</div>
        </div>

        <BaseDialog open={shLoadReport} onClose={()=>setShLoadReport(false)} submit={{text: "Отримати звiт", action: get_periods_child}} title="Звiт по нарахуванню за перiод">
          <TextField
            autoFocus
            margin="dense"
            id="datefromreport"
            label="Дата початку"
            type="date"
            value={chReport.date_from}
            onChange={(event)=>setChReport(Object.assign({}, chReport, { date_from: event.target.value}))}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="datefromreport"
            label="Дата закiнчення"
            type="date"
            value={chReport.date_to}
            onChange={(event)=>setChReport(Object.assign({}, chReport, { date_to: event.target.value}))}
            fullWidth
          />
        </BaseDialog>

        <div style={{ fontSize: size_of_content + 'px'}}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={children_data}
          title="Поточний стан"
          editable={{
            isEditable: true 
          }}

          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [5,10,20],
            cellStyle: {
              lineHeight: '0.9em',
              marginTop: '1px'
            }
          }}

          actions={[
          {
            icon: 'library_add',
            tooltip: 'Отримати звіт по нарахуванню дитини',
            onClick: (event, rowData) => {
              const curDate = new Date().toISOString().split('T')[0];

              const [yy, mm] = curDate.split('-');

              setChReport({child_id: rowData.child_id, gioc_id: rowData.gioc_id, date_from: `${yy}-01-01`, date_to: curDate });
              setShLoadReport(true);
            }
          }]}

        />
        </div>
      </div>
    );

}