import React, { useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MenuInternal } from './InternalMenu';
import Topbar from '../Topbar';

import { headers } from '../../helpers/headers';
import { fetchInit } from '../../helpers/fetchinit';

import { store, useGlobalState } from 'state-pool';

import FormAddBenefit from './components/AddBenefit';

import { Groups } from './components/Groups';

import { Attendance } from './components/Attendance.js'

import { Childrens } from './components/Childrens';

import { Schools  } from './components/Schools';

import { AccountsBank } from './components/Accounts';

import {Reports} from './components/Reports';

import { Benlist } from './components/Benlist';

import { AddEditBenefit } from './components/AddEditBenefit';

import { AddEditBenefitMass } from './components/AddEditBenefitMass';

import { SearchCB } from './components/SearchCB';

import { Bfnlist } from './components/BFNList';
import { AddEditBFN } from './components/AddEditBFN';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        IEFR-Studio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const screens = [
  'childrens', 'groups', 'schools','attendance', 'bankacc', 'reports', 'benlist', 'addbenefit', 'updbenefits', 'bfnlist', 'editbfn'
];


const endpoints = {
  groups:{ url: "/v1/get_all_gr", type: "get", isLoad: false},
  save_new_tarif: { url: "/v1/new_tarif", type: "POST", isLoad: false },
  benefits: { url: "/v1/get_benefits", type: "get", isLoad: false },
  save_new_benefit: { url: "/v1/new_benefit", type: "POST", isLoad: false },
  update_tarif: {url: "/v1/update_tarif", type: "PUT", isLoad: false},
  get_kgs:{ url: "/v1/get_kgs", type: "get", isLoad: false},
  set_benefit: { url: "/v1/set_benefit", type: "POST", isLoad: false },
  upd_benefit: { url: "/v1/customerbenefit", type: "PUT", isLoad: false},
  upd_ben_mass: { url: "/v1/cbenefitmass", type: "PUT", isLoad: false },
  set_bfn: {url: "/v1/set_bfn", type: "POST", isLoad: false}
}


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
   // minHeight:'22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    //...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100hv',
  },
}));

export default function Kindergartens(props) {
  const [auth, updateAuth, setAuth] = useGlobalState("auth");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = React.useState(screens[0]);

  const [groups, setGroups] = useState([]);
  const [benlist, setBenlist] = useState([]);
  const [boolUpdateTarifs, setBoolUpdateTarifs] = useState(false);
  const [beninfo, setBeninfo] = useState({});
  const [update_tarif_data, setTarifsUpdateData] = useState([]);
  const [kgs_list, setKgsList] = useState([]);
  const [bfninfo, setBFNinfo] = useState({});
  const [bfnlist, setBFNlist] = useState([]);

  const isEditMode = Object.keys(beninfo).length > 0;
  const isEditModeBfn = Object.keys(bfninfo).length > 0;
  const area = isEditMode ? beninfo.area : JSON.parse(auth.user.role.area)[0];



  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getGroups = async ()=> await fetchInit(endpoints.groups, setGroups, headers(auth));
  const getKgs = async (params)=> await fetchInit(endpoints.get_kgs, setKgsList, headers(auth), params);

  const reqSaveBenefit = async (data) => {

    const endp = isEditMode ? endpoints.upd_benefit : endpoints.set_benefit;

    const options = {
      method: endp.type,
      headers: headers(auth),
      body: JSON.stringify( { benefit_info: data } )
    };
    
    const res = await fetch(endp.url, options);
    if(!res.ok) {
      console.log("error by fetch", endp.url);
      if(res.status == 400) return alert('У користувача вже є встановлена пільга в цьому періоді');
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;

  }

  const reqSaveBfn = async (data) => {

    const endp = endpoints.set_bfn;

    const options = {
      method: endp.type,
      headers: headers(auth),
      body: JSON.stringify( { bfn_info: data } )
    };
    
    const res = await fetch(endp.url, options);
    if(!res.ok) {
      console.log("error by fetch", endp.url);
      if(res.status == 400) return alert('Дитина вже додана');
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;

  }

  const updateBenefitsMass = async (data) => {

    const endp = endpoints.upd_ben_mass;

    const options = {
      method: endp.type,
      headers: headers(auth),
      body: JSON.stringify( { benefit_info: data } )
    };
    
    const res = await fetch(endp.url, options);
    if(!res.ok) {
      console.log("error by fetch", endp.url);
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;
  }


  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const currentPath = props.location.pathname;


  const select_menu = (key)=>{
        setTarifsUpdateData({});
        setScreen(key);
  }

  const addTarif = (state)=>{
    setTarifsUpdateData({});
    if(state) return setScreen(screens[1]);
    setScreen(screens[0]);
  }

  const addBenefit = (state)=>{
    if(state) return setScreen(screens[7]);
    if(Object.keys(beninfo).length > 3 ) setBeninfo({});
    setScreen(screens[6]);
  }

  const updBenefits = (state)=>{
    if(state) return setScreen(screens[8]);
    if(Object.keys(beninfo).length > 3 ) setBeninfo({});
    setScreen(screens[6]);
  }

  const addBfn = (state)=>{
    if(state) return setScreen(screens[10]);
    if(Object.keys(bfninfo).length > 3 ) setBFNinfo({});
    setScreen(screens[9]);
  }

  const editBenefit = (data)=>{
      setBeninfo(data);
      setScreen(screens[7]);
  }

  const editBfn = (data)=>{
    console.log(`editBfn ${JSON.stringify(data)}`);
    setBFNinfo(data);
    setScreen(screens[10]);
  }

  const updBfn = (state)=>{
    if(state) return setScreen(screens[10]);
    if(Object.keys(bfninfo).length > 3 ) setBFNinfo({});
    setScreen(screens[9]);
  }

  const check_dates = (info)=>{
    const { date_set, date_fin} = info;

    const dateSet = new Date(date_set);
    const dateFin = new Date(date_fin);

    const minDate = new Date('2021-01-01');

    const maxVal = 5 * 365 * 24 * 60 * 60 * 1000;

    if(dateSet < minDate) return false;
    if(dateFin <= dateSet) return false;
    if(dateFin > new Date( dateSet.getTime() + maxVal )) return false

    return true;

  }

  const saveBenefit = async (info)=>{
    if(!check_dates(info)) return alert('Перевірте уважно дати встановлення та закінчення дії пільги. Дата встановлення не може бути меншою за 1 січня 2021 року. Максимальний термін дії пільги один рік.');
    info['area'] = area;
    if(isEditMode) info['id'] = beninfo.id;
    const res_save = await reqSaveBenefit(info);
    endpoints.upd_benefit.isLoad = false;
    endpoints.upd_benefit.isLoad = true;
    addBenefit(false);
  }

  const saveBFN = async (info)=>{
    if(!check_dates(info)) return alert('Перевірте уважно дати встановлення та закінчення дії БФН. Дата встановлення не може бути меншою за 1 січня 2021 року. Максимальний термін дії пільги один рік.');
    info['area'] = area;
    if(isEditModeBfn) info['id'] = bfninfo.id;
    const res_save = await reqSaveBfn(info);//await reqSaveBenefit(info);
    addBfn(false);
  }

  const updBenefitsMass = async (info)=>{

    const result = await updateBenefitsMass(info);
    updBenefits(false);

    if(!result.rowCount) return;
    
    return alert('оновлено ' + result.rowCount + ' записів про пільги');
  }

    useEffect(()=>{
      getGroups();
      if(kgs_list.length == 0) getKgs("?district=" + area);
      return function cleanup(){
        for( let key in endpoints) {
          endpoints[key]['isLoad'] = false;
        }
      }
    }, [Object.keys(endpoints).reduce( (acc,cur)=>acc&&endpoints[cur].isLoad, true ) ] 
    );



  return (
    <React.Fragment>
    <CssBaseline />
    <Topbar currentPath={currentPath} />   
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <MenuInternal open={open} select_menu={select_menu} role={JSON.parse(auth.user['role']['area']) || [] }/>
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                <Groups show={screen == "groups" } addTarif={addTarif} data={groups} update={()=>{}} callUpdate={boolUpdateTarifs} headers={headers(auth)}/> 
                <Childrens show={screen == "childrens" } data={groups} addBenefit={addBenefit} headers={headers(auth)} role={JSON.parse(auth.user['role']['area']) || [] }/>
                <Schools show={screen == "students" } data={groups} addBenefit={addBenefit} headers={headers(auth)} role={JSON.parse(auth.user['role']['area']) || [] } />
                <Attendance show={screen == "attendance" } data={groups} addBenefit={addBenefit} headers={headers(auth)} />
                <SearchCB show={screen == "searchCB"} data={groups} area={area} headers={headers(auth)}/>
                <AccountsBank show={screen == "bankacc"} headers={headers(auth)} />
                <Reports show={screen == "reports" } headers={headers(auth)} role={JSON.parse(auth.user['role']['area']) || [] }/>
                <Benlist show={screen == "benlist"} add={addBenefit} data={benlist} edit={editBenefit} upd={updBenefits} role={JSON.parse(auth.user['role']['area']) || [] } />
                {screen == "addbenefit" && <AddEditBenefit show={screen == "addbenefit"} groups={groups} close={addBenefit} editdata={beninfo} addBenefit={saveBenefit} listKgs={kgs_list} headers_auth={headers(auth)} /> }
                {screen == "updbenefits" && <AddEditBenefitMass show={screen == "updbenefits"} groups={groups} close={updBenefits} editdata={beninfo} addBenefit={updBenefitsMass} listKgs={kgs_list} headers_auth={headers(auth)} /> }
                <Bfnlist show={screen == "bfnlist"} add={addBfn} data={bfnlist} edit={editBfn} upd={updBfn} role={JSON.parse(auth.user['role']['area']) || [] }/>
                {screen == "editbfn" && <AddEditBFN show={screen == "editbfn"} groups={groups} close={addBfn} editdata={bfninfo} addBfn={saveBFN} listKgs={kgs_list} headers_auth={headers(auth)} /> }
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            
          </Box>
        </Container>
      </main>
    </div>
    </React.Fragment>
  );
}