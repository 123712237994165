import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  function ListOfBenefit(props) {

    const classes = useStyles();

    const { show, data, addBenefit } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
      { title: 'Найменування', field: 'name' },
      { title: 'Тип знижки', field: 'type' }, // Тип скидки в процентах, в денежной скидке, фиксировання сумма
      { title: 'Знижка', field: 'value' },
      { title: 'Тип застосування', field: 'type_accept' },
      { title: 'Перелік послуг', field: 'service_list_accept' },
      { title: 'Обмеження диї', field: 'area' },
      { title: 'Дата початку дії', field: 'date_from', type: 'date' },
      { title: 'Дата закинчення дії', field: 'date_to', type: 'date' },
      { title: 'Статус', field: 'status' }
    ];    

    const [template_data, setTableData] = useState(data.data);
    
    const [initDataState, setInitDataState] = useState(false);
    

    const [display, setShow] = useState(false);


    
    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{ setTableData(data.data)}, [data.data, show])



    const addNewBenefit = ()=>{
      props.addBenefit(true);
    }

    if(!display) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <div><Button onClick={addNewBenefit}>Додати нову пільгу </Button></div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Пільги"
          components={{
            Action: props=><AddButton {...props} />
          }}

          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }

          actions={[
            {
              icon: "save",
              tooltip: "Зберегти інформацію",
              onClick: (event, rowData) => alert(`Зберегти ${rowData.name_last}`)
            }
          ]}
          editable={{
            onRowAdd: (newData) =>
              Promise.resolve(setTableData([...template_data, newData]))
          }}
        />
      </div>
    );

}