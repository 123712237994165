import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, Switch, FormControlLabel} from '@material-ui/core';
import AddButton from './AddButton';
import SelectKg from './SelectKg';
import EditClass from './AddEditClass';
import EditTarifs from './Tarifs';

import { get_list_classes } from './actions';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selStudents: {
    display: 'block',
    textAlign: 'end'
  },
  selectKg:{
    maxWidth: '500px',
    paddingLeft: '10px',
    paddingBottom: '10px'
  },
  labelTarif:{
    fontSize: '19px'
  },
  buttons_div: {
    cursor: 'pointer',
    "&:hover, &:focus": {
      fontWeight: "bold",
      color: "#303F9F"
    }
  }
}));

export  function Groups(props) {

    const classes = useStyles();

    const { show, data, update, callUpdate, headers, area } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
      {title:'Номер', field: 'number'},
      {title:'Найменування', field: 'name'},
      {title:'Навчальний рік', field: 'period_start_year'}
    ];    

    const [template_data, setTableData] = useState([]);

    const [kgs_list, setKgsList] = useState([]);

    const [shEditClass, setSchowEditClass] = useState();

    const [classData, setDataClass] = useState([]);

    const [isStudents, setUseStudents] = useState(false);

    const [selectedKg, setSelectKg] = useState(null);

    const [shEditTarif, setSchowEditTarif] = useState(false);
  
    const [display, setShow] = useState(false);

    const [size_of_content, setSizeContent ] = useState(17);
    const[vis_control, setVisControl] = useState('hidden');



    
    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{

    if(!data || data.length < 1) return;
    const info = [];
      
    for(let el of data){
      const { kg_id, number, name, edrpou, property, contacts } = el._id;

      const groups = el.groups;

      const district = contacts.address.district || "Київ"

        const row_kg = {
          kg_id: kg_id,
          kg_number: number,
          kg_name: name,
          edrpou: edrpou,
          property: property,
          district: district
        };
        info.push(row_kg);
      }
      
      setKgsList(info);

    },[data.length])

    useEffect(()=>{
      if(!selectedKg || isStudents) return;
      const info = [];
        const el = data.find(kg=>kg._id.kg_id == selectedKg);
        const { kg_id, number, name, edrpou, property, contacts } = el['_id'];
        const groups = el.groups;
        const district = contacts.address.district || "Київ"

        for(let item of groups){
          const { gr_id, gr_number, gr_name, children_count, price, periodStartYear } = item;
          if (!gr_id) continue;
          const row_kg = {
            kg_id: kg_id,
            kg_number: number,
            kg_name: name,
            edrpou: edrpou,
            property: property,
            district: district,
            gr_id: gr_id,
            number: gr_number,
            name: gr_name,
            children_count: children_count,
            price: price && price[0] && price[0].averageForDay || 0,
            period_start_year: periodStartYear
          };
          const indx = info.findIndex(el=>el.kg_id == kg_id && el.gr_number == gr_number);
          if(indx == -1) info.push(row_kg);
          if(indx >= 0) info[indx]['children_count'] += children_count;
        }

        if(info && info.length > 0) {
          setVisControl('visible');
        } else {
          setVisControl('hidden');
        }

      setTableData(info);

    }, [selectedKg, isStudents])

    const setDataStudents = async ()=> {
      const info = await get_list_classes({ id_school: selectedKg, period_start_year: '2023' }, headers);

      if(!info || info.length < 1) {
        setTableData([]);
        return;
      }

      setTableData(info);

    };
    
    useEffect(()=>{
      if(!selectedKg || !isStudents) return;
      setDataStudents();
    }, [selectedKg, isStudents])

    const editTarif = (data)=>{
      update(data.id);
    }


    const addNewUser = ()=>{
      props.addTarif(true);
    }

    const setKg = (event, value) => {
      console.log(`setKg ${JSON.stringify(value)}`);
      setSelectKg(value && value.kg_id || null);
    }

    const setEditClass = (data)=>{
      setDataClass(data);
      setSchowEditClass(true);
    }

    const setEditTarif = (data)=>{
      const {id} = data;
      setDataClass(id);
      setSchowEditTarif(true);
    }

    const closeEditClass = (state) =>{
      if(state == 'updated') setDataStudents();
      setSchowEditClass(false);
    }

    const closeEditTarifs = (state) =>{
      if(state == 'updated') setDataStudents();
      setSchowEditTarif(false);
    }

    if(!display) return null;

    if(shEditClass) return <EditClass data={kgs_list} classdata={classData} show={shEditClass} close={closeEditClass} headers={headers} />
    if(shEditTarif) return <EditTarifs data={{id_school: selectedKg, id_class: classData}} kgs_list={kgs_list} show={shEditTarif} close={closeEditTarifs} headers={headers} />

    return (
      <div style={{ maxWidth: "100%" }}>
      <div className={ classes.selectKg } >
            <SelectKg data={kgs_list} setId={selectedKg} onChange={setKg} />
      </div>
      <div className={classes.selStudents}><FormControlLabel
              control={
             <Switch
                checked={isStudents}
                onChange={(event)=>setUseStudents(event.target.checked)}
                name="students"
                color="primary"/>}
              label={isStudents ? "Школярі" : "Садочки" } /></div>

        <div className={classes.size_control} style={{ visibility: vis_control}}>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content < 30) setSizeContent(size_of_content + 1) } }>+ Збільшити шрифт в таблиці</div>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content > 10) setSizeContent(size_of_content - 1) } }>- Зменьшити шрифт в таблиці</div>
        </div>
        <div style={{ fontSize: size_of_content + 'px'}}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title={isStudents ? "Класи в закладах" : "Групи в закладах" }
          actions={[
            {
              icon: 'add',
              tooltip: 'Додати новий клас(групу)',
              isFreeAction: true,
              onClick: (event) => setEditClass({id_school: selectedKg})
            },
            {
              icon: 'edit',
              tooltip: 'редагувати',
              onClick: isStudents ? (event, rowData)=>setEditClass(rowData) :  null,
              disabled: !isStudents
            },
            {
              icon: ()=><span className={classes.labelTarif}>T</span>,
              tooltip: 'Тарифи',
              onClick: isStudents ? (event, rowData)=>setEditTarif(rowData) :  null,
              disabled: !isStudents
            }
          ]}
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50]
          }}
        />
        </div>
      </div>
    );

}