import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import { FilterNumericBetween, isDateInRange } from '../../common/Daterangematerialtable';
import AddButton from './AddButton';
import SelectKg from './SelectKg';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  function ListOfCorrection(props) {

    const classes = useStyles();

    const { show, data, listKgs, edit } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const style_column = [
      {
      },
      {
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 100,
        maxWidth: 200
      },{
        width: 30,
        maxWidth: 60
      },{
        width: 20,
        maxWidth: 20
      },
    ];

    const columns = [
        {title:"Дата", field:"date_pay", render: (row)=>new Date(row.date_pay).toLocaleDateString('uk'), filterComponent: FilterNumericBetween, customFilterAndSearch: (term, rowData)=>isDateInRange(term.dateFrom, term.dateTo, rowData.date_pay)},
        {title:"Сума", field:"summ", render: (row)=>`${row.summ.replace('$','')}`},
        {title: "Заклад", field:"recipients_number"},
        {title: "Група", field:"group_number"},
        {title:"Дитина", render: rowData => `${rowData.customer_name_l} ${rowData.customer_name_f} `, customFilterAndSearch: (term, rowData) => (rowData.customer_name_l + " " + rowData.customer_name_f).toLowerCase().match(new RegExp(term.toLowerCase()))},
        {title: "Тип", field: "position" },
        {title: "Причина", field: "describe" }, 
        {title: "Статус", field: "status"},
        {title: "Додано", field: "created_at", type: "date", defaultSort: "desc"}
    ];

    for(let col in columns){
      columns[col]['headerStyle'] = style_column[col];
      columns[col]['cellStyle'] =  style_column[col];
    }



    const [template_data, setTableData] = useState();

    const [initDataState, setInitDataState] = useState(false);

    const [display, setShow] = useState(false);


    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{ 
      setTableData(data.data)
      }, [data.data, show]
    );



    const addNew = ()=>{
      props.addNew(true);
    }

    if(!display || !listKgs || listKgs.length < 1) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <div><Button onClick={addNew}>Додати нове корегування </Button></div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Корегування"
          components={{
            Action: props=><AddButton {...props} />
          }}
          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }
          editable={{
            isEditable: false
          }}
          options={{
            actionsColumnIndex: -1,
            filtering: true
          }}
        />
      </div>
    );

}