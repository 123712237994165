import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

import { makeStyles } from '@material-ui/core/styles';

import { get_charge_bill } from './actions';

const useStyles = makeStyles((theme) => ({
    closeButton:{
        cursor: 'pointer',
        fontSize: '15px',
        padding: '5px',
        backgroundColor: '#eeeeee',
        display: 'inline-block',
        borderRadius: '2px',
        margin: '5px'
      }
}));

const columns = [
    { title: "Заклад", field: "kg_number", editable: "never" },
    { title: "Група", field: "group", editable: "never" },
    { title: "Тариф", field: "tarif", editable: "never" },
    { title: "Сальдо на початок", field: "saldo_beg", editable: "never" },
    { title: "Дні відвід.", field: "days", editable: "never" },
    { title: "Фактично нараховано", field: "charged", editable: "never" },
    { title: "Сума пільг", field: "bensum", editable: "never" },
    { title: "Сплачено", field: "payment", editable: "never" },
    { title: "Коригування", field: "correction", editable: "never" },
    { title: "Сальдо на кінець", field: "saldo_fin", editable: "never"}
  ]

 
export function ChargeBill(props) {
    const { data, close, headers } = props;
    const { id_child } = data;

    const classes = useStyles();

    const [info, setInfo] = useState([]);
    const tableRef = React.createRef();

    const getData = ()=>{
        get_charge_bill(data, setInfo, (err)=>console.log(`error get info about payments`), headers);
    }

    useEffect(()=>{ getData()}, [id_child])

    return (
        <div>
            <div onClick={close} className={classes.closeButton}>Закрити та повернутись</div>
        
        <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={info}
        title="Поточні нарахування"
        editable={{
            isEditable: true 
        }}

        options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [5,10,20]
        }}

        />
        </div>
    )
}