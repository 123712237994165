import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';

import { links_banks } from './components/paybankinfo';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'КМДА © '}
      <Link color="inherit" href="https://osvita.kyivcity.gov.ua">
        Портал дошкільної освіти
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

  

const endpoints = {
    groups:{ url: "/v1/get_all_gr_pay", type: "get", isLoad: false},
    send_pay_req: { url: "/v1/init_new_pay_ipay", type: "POST", isLoad: false },
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  inline_links: {
    display: 'inline-block',
    padding: '10px'
  },
  block_links: {
      textAlign: 'center'
  },
  title_links: {
    textAlign: 'center',
    fontSize: '17px',
    fontWeight: '700',
    padding: '15px'
  },
  icons_link: {
    maxWidth: '110px'
  }
}));

const steps = ['Оберіть заклад, групу та дитину'] //, 'Обрати дитину', 'перевірити та сплатити'];

function getStepContent(step, groups, changeChildrensInfo) {
  switch (step) {
    case 0:
      return <AddressForm data={groups} changeChildrensInfo={changeChildrensInfo} />;
    case 1:
      return <PaymentForm />;
    case 2:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Paysys() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [groups, setGroups] = React.useState([]);

  const [childInfo, setChildInfo] = React.useState();
  

  const handlePay = () => {
      sendPay(childInfo)
  }


  const sendPay = async (data) => {
      
    const options = {
      method: endpoints.send_pay_req.type,
      headers:{
        'Content-Type': 'application/json'
      },
      body: `${JSON.stringify({ pay_info: data })}`
    };
    
    const response = await fetch(endpoints.send_pay_req.url, options);

    if(!response.ok) return alert('Error send pay');

    const redirect = await response.text();


    if (redirect.match(/ipay.ua/)) {
        window.location.href = redirect;
    }

  }
  
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getGroups = async ()=> {
      if(endpoints.groups.isLoad || groups.length > 0) return;
      endpoints.groups.isLoad = true;
      const response = await fetch(endpoints.groups.url);
      if(!response.status == 200) return;
      const data = await response.json();
      endpoints.groups.isLoad = false;

      const infokg = data.filter(el=>!el._id.property.match(/риватн/));

      setGroups(infokg)
  }

  React.useEffect(()=>{
      getGroups();
  }, [groups.length])
  
  const changeChildrensInfo = (data)=>{
    setChildInfo(data);
  }

  const ExternalPaymentSystems = () => <div className={classes.title_links}><span>або через банки партнери </span><div className={classes.block_links}>{links_banks.map(l=><div className={classes.inline_links} title={l.title}><Link color="inherit" href={l.url}><img style={l.style} className={classes.icons_link} src={l.icon} alt={l.title}/></Link></div>)}</div></div>

  const InfoPay = () => <Typography component="h5" variant="h5" align="center">Сплатити за харчування в садочку</Typography>

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Заклади дошкільної освіти Києва
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        { true && <Paper className={classes.paper}>
          <Typography component="h5" variant="h5" align="center">
             Сплатити за харчування в садочку 
          </Typography>
          { false && <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>}
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #2001539. We have emailed your order confirmation, and will
                  send you an update when your order has shipped.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, groups, changeChildrensInfo)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!childInfo}
                    onClick={handlePay}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Сплатити' : 'Наступний'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper> }
        {false && <InfoPay />}
        <ExternalPaymentSystems />
        <Copyright />
      </main>
    </React.Fragment>
  );
}
