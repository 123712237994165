export const get_tarifs = async (data, headers)=>{
    const { id_school, id_class } = data;

    const options = {
        method: 'GET',
        headers: headers
      };
  
      const link = `/v1/tarifs_sh?id_school=${id_school}&id_class=${id_class}`;
  
      const res = await fetch(link, options);
  
      if(!res.ok) {
        console.log("error by fetch", link);
        alert("Error by sending req");
        return;
      }
  
      const info = await res.json();
  
      return info;
}