import React, { useState} from 'react'
import { render } from 'react-dom'
import ReactMarkdown from 'react-markdown'
import  gfm  from 'remark-gfm';

import { MainText }  from './main'

import { M1 } from './m1'

export function HelpPayments(props){

    const { show } = props;

    const [text, setText] = useState('');


    if(!show) return null;

    console.log('M1', M1);

    return(
        <ReactMarkdown remarkPlugins={[gfm]}>{M1}</ReactMarkdown>
    )

}