/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectGr(props) {
  const classes = useStyles();

  const { data, onChange } = props;

  const [list_options, setList] = useState([]);

  useEffect(()=>{
    if( (!data || data.length < 1) && list_options.length > 0) { setList([]); return };
    if(!data || data.length < 1) return ;
    const sort_list = data.sort((a,b)=>a.gr_number - b.gr_number)
    setList(sort_list);
  })

  return (
    <Autocomplete
      id="country-select-demo"
      //style={{ width: 300 }}
      options={list_options}
      key={ list_options && list_options.length || 0}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      autoHighlight
      getOptionLabel={(option) => `${option.gr_number}${option.gr_name ? ' ,' + option.gr_name : ''}` }
      renderOption={(option) => (
        <React.Fragment>
          {`${option.gr_number}${option.gr_name ? ' ,' + option.gr_name : ''}`} 
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберіть групу в садочку"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

