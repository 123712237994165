import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  function ListOfTarifs(props) {

    const classes = useStyles();

    const { show, data, update, callUpdate } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
      {title:'Найменування', field: 'name'},
      {title:'Тип', field: 'type'},
      {title:'Сума', field: 'sum'},
      {title:'Послуга', field: 'service'},
      {title:'Дата початку дії', field: 'date_from', type: 'date'},
      {title:'Дата завершення', field: 'date_to', type: 'date'},
      {title:'Статус', field: 'status'}
    ];    

    const [template_data, setTableData] = useState(data.data);
    
    const [initDataState, setInitDataState] = useState(false);
    

    const [display, setShow] = useState(false);

    
    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{ 
      setTableData(data.data)
    }, [data.data, show, callUpdate] )

    const editTarif = (data)=>{
      update(data.id);
    }


    const addNewUser = ()=>{
      props.addTarif(true);
    }

    if(!display) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <div><Button onClick={addNewUser}>Додати новий тарифний план </Button></div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Тарифи"
          components={{
            Action: props=><AddButton {...props} />
          }}

          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }

          actions={[
            {
              icon: "edit",
              tooltip: "Редагуватиінформацію",
              onClick: (event, rowData) => editTarif(rowData)
            }
          ]}
          editable={{
            onRowAdd: (newData) =>
              Promise.resolve(setTableData([...template_data, newData]))
          }}
        />
      </div>
    );

}