import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  
    const { open, close, callback, info } = props; 

    //info - { title: '', text: '', btn_agr: '', btn_dsg: '' }

    const { title, text, btn_agr, btn_dsg } = info;
  

    const handleAgree = () => {
        close(true, callback);
    };

    const handleDissagree = () => {
        close(false, callback);
    }

  return (
      <Dialog
        open={open}
        onClose={handleDissagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDissagree} color="primary">
            {btn_dsg}
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            {btn_agr}
          </Button>
        </DialogActions>
      </Dialog>
  );
}