import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons_div: {
    cursor: 'pointer',
    "&:hover, &:focus": {
      fontWeight: "bold",
      color: "#303F9F"
    }
  }
}));

export  function AccountsBank(props) {

    const classes = useStyles();

    const { show, headers } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const sort_string = (a, b, field)=> {
      if (a[field] > b[field]) {
        return 1;
      }
      if (a[field] < b[field]) {
        return -1;
      }
      
      return 0; 
    }

    //sezzdo_name, sezzdo_number, gioc_name, gioc_distr, sezzdo_distr, buch_id, iban, edrpou
    const columns = [
      {title:'Заклад', render:(row)=>[row.sezzdo_name, row.sezzdo_number, "(", row.gioc_name, ")"].join(" "), customFilterAndSearch: (term, row)=> row.sezzdo_name && row.sezzdo_name.toLowerCase().match(new RegExp(term.toLowerCase(), 'g')) || row.sezzdo_number.toLowerCase().match(new RegExp(term.toLowerCase(), 'g')) || row.gioc_name.toLowerCase().match(new RegExp(term.toLowerCase(), 'g')), customSort: (a, b)=>sort_string(a,b, 'sezzdo_number') },
      {title:'Район', render:(row)=>[row.sezzdo_distr, "(", row.gioc_distr, ")"].join(" "), customFilterAndSearch: (term, row)=> row.sezzdo_distr.toLowerCase().match(new RegExp(term.toLowerCase(), 'g')) || row.gioc_distr.toLowerCase().match(new RegExp(term.toLowerCase(), 'g')), customSort: (a, b) => sort_string(a,b, 'sezzdo_distr')},
      {title:'ЄДРПОУ', field: 'edrpou'},
      {title:'IBAN', field: 'iban'},
      {title: 'ЦБ', field: 'buch_id'}
    ];    

    const [template_data, setTableData] = useState([]);
    
    const [initDataState, setInitDataState] = useState(false);
    

    let isLoading = false;

    const [display, setShow] = useState(false);

    const get_accounts_list = async () =>{
      if(isLoading) return;
      isLoading = true;
      const url = `/v1/accounts_kgs`;
      const response = await fetch(url, { headers: headers });
      if(!response.ok) {
        setTimeout(()=>[],30*1000);
        isLoading = false;
      };
      const data = await response.json();
      isLoading = false;
      return data.data || [];
    }

    
    useEffect(()=>{
      setShow(show);
    },[show])


    const set_data = async ()=>{
       const info = await get_accounts_list();
       setTableData(info);
    }

    useEffect(()=>{
      if(template_data.length > 0 || !show) return;
      set_data();
    }, [template_data, show] )

  

    if(!display) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Рахунки для оплати послуг в закладах"
          
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50]
          }}
        />
      </div>
    );

}