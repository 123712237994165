// Library
import React from 'react';
import Form from '@rjsf/material-ui';

import InputMask from 'react-input-mask';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');

export function AddEditPaySystem (props) {

    const { info, addUser, saveNewUser } = props;

    if(!props.show) return null;


    const sh = schema.properties;

    const fdata = Object.assign({}, formData, info);


    const MaskEDRPOU = (props)=><MuiThemeProvider>
    <InputMask 
        mask="99 99 99 99" 
        value={props.value}
        onChange={(event) => {}}
        disabled={false}
        children={<TextField label={props.schema.title}/>}/>
    </MuiThemeProvider>


    const widgets = {
        MaskEDRPOU: MaskEDRPOU
    };


    const onSubmit = (value, callback) => {
        console.log('onSubmit: %s', JSON.stringify(value)); 
        setTimeout(() => callback && callback(), 2000); 
    }
    
    const onCancel = () => {
        console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const handleClose = ()=>{
        addUser(false);
    }

    const onSubmitForm = ({ formData}) => {
        
        formData.area = JSON.stringify(formData.area);
        formData.role = JSON.stringify(formData.role);
        formData.inn = +(formData.inn.replace(/\s/g, ""));
        
        console.log("onsubmit form data", JSON.stringify(formData) );

        saveNewUser( { user_info: formData } );
    };

    return (
         <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={fdata}
              onCancel={onCancel}
              onSubmit={onSubmitForm}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="button" onClick={handleClose} className="btn btn-secondary">Закрити без збереження</Button>
             <Button type="submit">Додати установу</Button>
         </Form>
    );
}