import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  function ListOfUsers(props) {

    const classes = useStyles();

    const { show, data } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
        {title:"Ім'я", field:"first_name"}, 
        {title:"Призвище", field:"last_name"},
        {
          title:"Роль", 
          field:"role"
        },
        {title:"Межи", field: "area"},
        {title:"РНКПО", field:"inn"},
        {title:"E-mail", field:"email"},
        {title:"phone", field:"phone"},
/*        {
          title: "Додати нового користувача",
          field: "add_action",
          editable: false,
          render: (rowData) =>
            rowData && (
              <IconButton
                color="secondary"
                onClick={() => addActionRef.current.click()}
              >
                <AddIcon />
              </IconButton>
            )
        }
*/
    ];

    const [template_data, setTableData] = useState(data.data);
    
    const [initDataState, setInitDataState] = useState(false);
    

    const [display, setShow] = useState(false);


    
    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{ setTableData(data.data)}, [data.data, show])



    const addNewUser = ()=>{
      props.addUser(true);
    }

    if(!display) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <div><Button onClick={addNewUser}>Додати нового користувача </Button></div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Користувачі"
          components={{
            Action: props=><AddButton {...props} />
          }}

          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }

          actions={[
            {
              icon: "save",
              tooltip: "Зберегти інформацію",
              onClick: (event, rowData) => alert(`Зберегти ${rowData.name_last}`)
            }
          ]}
          editable={{
            onRowAdd: (newData) =>
              Promise.resolve(setTableData([...template_data, newData]))
          }}
        />
      </div>
    );

}