
export  const add_new_school = async ({data, headers})=>{

    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ data: data})
    };

    const link = `/v1/add_school`;

    const res = await fetch(link, options);

    if(!res.ok) {
      console.log("error by fetch", link);
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;

  }