/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectChildrens(props) {
  const classes = useStyles();

  const { data, onChange } = props;

  const [optionL, setOptions] = useState([]);


  useEffect(()=>{
    if( (!data || data.length < 1) && optionL && optionL.length > 0) { setOptions([]); return };
    if(!data || data.length < 1 || data.length == optionL.length) return ;

    const sort_list = data.sort((a,b)=>a.firstName.localeCompare(b.firstName))
    setOptions(sort_list);
  })

  return (
    <Autocomplete
      id="country-select-demo"
      key={optionL && optionL.length || 0}
      //style={{ width: 300 }}
      options={optionL}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      autoHighlight
      getOptionLabel={(option) => `${option.firstName}, ${option.birthDate && new Date(option.birthDate).toLocaleDateString('uk') || ''} група ${option.groupNumber} ${option.groupName} ${option.tabelNumber ? 'таб ' + option.tabelNumber : '' }` }
      renderOption={(option) => (
        <React.Fragment>
          {`${option.firstName}, ${option.birthDate && new Date(option.birthDate).toLocaleDateString('uk') || ''} ${option.groupNumber} ${option.groupName ? 'група ' + option.groupName : ''} ${option.tabelNumber ? 'таб ' + option.tabelNumber : '' }`}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберіть дитину"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

