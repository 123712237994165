import React, { useState, useEffect } from "react";

import SelectChildrenByKG from './SelectChildrenByKG';

import SelectChildren from './SelectChildren';

export default function WidgetChildrenSelect(props){

    const [isByChild, setIsByChild] = useState(true);

    const change_group = ()=>{
        setIsByChild(!isByChild)
    };


    return isByChild ? <SelectChildren {...props} changeGroup={change_group}/> : <SelectChildrenByKG {...props} changeGroup={change_group}/>
}