import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import Grid from "@material-ui/core/Grid";
import {Link, Typography, IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';

import SelectKg from './SelectKg';

import  EditChildren  from './EditChildren';
import { Label } from "recharts";

import { localDate, nextMonth, prevMonth } from '../../../helpers/timedata';


const endpoints = {
  saldo:{ url: "/v1/push_saldo", type: "POST", isLoad: false },
  saldo_date:{ url: "/v1/get_saldo_date", type: "GET", isLoad: false },
  saldo_report: { url: "/v1/saldo_report", type: "GET", isLoad: false },
  check_saldo: { url: "/v1/check_saldo_buch", type: "GET", isLoad: false},
  saldo_fix_buch: { url: "/v1/push_saldo_buch", type: "POST", isLoad: false}
};


const useStyles = makeStyles((theme) => ({

  selectKg:{
    maxWidth: '500px',
    paddingLeft: '10px',
    paddingBottom: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  labelLoad:{
    verticalAlign: '-27px'
  },
  label:{
    verticalAlign: 'super',
    padding: '10px'
  },
  go_button:{
    paddingLeft: '35px'
  },
  buttons_div: {
    cursor: 'pointer',
    "&:hover, &:focus": {
      fontWeight: "bold",
      color: "#303F9F"
    }
  },
  labelBFN:{
    fontSize: '19px'
  }
}));



export  function Reports(props) {

    const classes = useStyles();

    const { show, data, headers, role } = props;

    const [show_edit, setShowEdit] = useState({show:false, data:{}});

    const [saldo_date, setSaldoDate] = useState();


    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
      { title: 'Назва', field: 'sezzdo_name' },
      { title: 'Номер', field: 'sezzdo_number' },
      { title: 'Дата формування', field: 'date'}
    ];

    const [children_data, setTableData] = useState([]);
    
  

    const [selected_kg, selectKg] = useState();

    const [display, setShow] = useState(false);

    const [buch_sel, setBuch] = useState('');

    const [buch_list, setBuchList] = useState([]);

    const [periods, setPeriods] = useState([]);

    const [period_sel, setPeriodSel] = useState('');

    const [action_sel, setAction] = useState('memo');

    const [op_data, setDataOp] = useState([]);

    const [op_list, setOpList] = useState([]);

    const [size_of_content, setSizeContent ] = useState(17);
    const[vis_control, setVisControl] = useState('hidden');



    const actions = [
      { value: 'memo', label: 'Зведена відомість по ЗДО', level: 'all'},
      { value: 'memo15', label: 'Меморіальний ордер № 15.1', level: 'all'},
      { value: 'clistkgs', label: 'Зведений звіт по садочкам', level: 'all'},
      { value: 'accinvites', label: 'Звіт на кінець розрахункового періоду', level: 'kyiv'},
      { value: 'benefit', label: 'Звіт щодо пільг', level: 'all'},
      { value: 'child_out', label: 'Реєстр вибувших дітей по ЦБ ', level: 'all'},
      { value: 'saldo', label: 'Сформувати сальдо', level: 'kyiv'}

    ];

    let isLoading = false;

    const get_accounts_list = async () =>{
      if(isLoading) return;
      isLoading = true;
      const url = `/v1/accounts_kgs`;
      const response = await fetch(url, { headers: headers });
      if(!response.ok) {
        setTimeout(()=>[],30*1000);
        isLoading = false;
      };
      const data = await response.json();
      isLoading = false;
      return data.data || [];
    }

    const setListOp = (buch_id)=>{

      const data = op_list.filter(op=>op.buch_id == buch_id)
      if(data && data.length > 0) {
        setVisControl('visible');
      } else {
        setVisControl('hidden');
      }
      setDataOp(data);
    };

    const set_period = ()=>{
      
      const start_pos = new Date('2021-01-01');

      const curPeriod = new Date(new Date().setDate(1)) ;

      const months = ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень']

      const arr_periods = [];

      for( let pos = start_pos; pos <= curPeriod; pos = new Date(pos.getFullYear(), pos.getMonth() + 1, 1, 3, 59)){
        arr_periods.push({ value: localDate(pos), label: `${months[pos.getMonth()]} ${pos.getFullYear() }`})
      }

      setPeriods(arr_periods);
      setPeriodSel(arr_periods[arr_periods.length -1]['value']);

    }

    const init_data = async ()=>{
      const accounts = await get_accounts_list();

      if(accounts.length < 1) return;

      const buch_list_obj = {};

      const buch_list_arr = [];
 
      for(let a of accounts){
        if(!buch_list_obj[a.buch_id]) buch_list_obj[a.buch_id] = { distr: a.sezzdo_distr };
      }

      for(let b in buch_list_obj){
        buch_list_arr.push({ number: b, distr: buch_list_obj[b]['distr']});
      }

      setBuchList(buch_list_arr);

      setBuch(buch_list_arr[0]['number']);

      setOpList(accounts);

      setListOp(buch_list_arr[0]['number']);
    }

    
    useEffect(()=>{
      init_data();
      set_period();
      setShow(show);
    },[show])


    const check_saldo_exists = async (buch_id, period) =>{
      const url = endpoints.check_saldo.url + `?buch_id=${buch_id}&period=${period}`;
      const response = await fetch(url, { headers: headers });
      const data = await response.json();
  
      return data;
    }



    const get_childrens_list = async (kg) =>{
      const cur_date = new Date();
      const dateTo = cur_date.getMonth() == 0 ? `${cur_date.getFullYear() - 11 }-12-1` : `${cur_date.getFullYear()}-${cur_date.getMonth()}-1`;
      const url = `/v1/get_children_by_kg?kg_id=${kg}&period=${cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 }&date_to=${dateTo}`;
      const response = await fetch(url, { headers: headers });
      const data = await response.json();
      return data.data || [];
    }

    const get_report = async (date_for)=>{
      const url = `/v1/rep_consolidate_kg?kg_id=${selected_kg}&period=${date_for.getMonth() > 7 ? date_for.getFullYear() : date_for.getFullYear() - 1 }&date_to=${date_for.getMonth() + 1 }.${date_for.getFullYear()}`;
      const file = await fetch(url, { headers: headers });
      if(file.status != 200) return;
      const file_name = file.headers.get('content-disposition').split("filename=")[1];
      const blob = await file.blob(); 
      let url_file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
          a.href = url_file;
          a.id = 'rep_file'
          a.download = file_name;
          a.click();
    }

    const fix_saldo = async (buch_id, period)=>{

      if(isLoading) return;

      if(buch_id && !window.confirm('Дані будуть оновлені на поточний стан. Продовжити?')) return;

      isLoading = true;

      const options = {
        method: endpoints.saldo_fix_buch.type,
        headers: headers,
        body: JSON.stringify({ info: {} })
      };

      const params = `?buch_id=${buch_id}&period=${period}`;
      
      const res = await fetch(endpoints.saldo_fix_buch.url + params, options);

      if(!res.ok) {
        console.log("error by fetch", endpoints.saldo);
        alert("Error by sending req");
        isLoading = false;
        return;
      }
  
      const info = await res.json();

      isLoading = false;
  
      return info;
    }

    const get_saldo_date = async (kg_id)=>{

      const options = {
        method: endpoints.saldo_date.type,
        headers: headers
      };

      const params = `?kg_id=${kg_id}&period_days=31&date_to=2021-02-01`;
      
      const res = await fetch(endpoints.saldo_date.url + params, options);

      if(!res.ok) {
        console.log("error by fetch", endpoints.saldo_date);
        alert("Error by sending req");
        return;
      }
  
      const info = await res.json();
  
      return info;

    }

    const get_memo_report = async (period, cb)=>{
      const url = `/v1/rep_memo?&period=${period}&cb=${cb}`;
      get_memo_report_body(url);
    }

    const get_memo_report_15 = async (period, cb)=>{
      const url = `/v1/rep_memorial15cb?&period=${period}&cb=${cb}`;
      get_memo_report_body(url);
    }

    const get_cons_list_kgs_cb = async (period, cb)=>{
      const url = `/v1/cons_list_kgs_cb?period=${period}&cb=${cb}`;
      get_memo_report_body(url);
    }

    const get_acc_invites_cbs = async (period)=>{
      const url = `/v1/acc_invites_cbs?period=${period}`;
      get_memo_report_body(url);
    }

    const get_memo_report_body = async (url)=>{
      const file = await fetch(url, { headers: headers });
      if(file.status != 200) { return alert('Не має даних за цей період') };
      const file_name = file.headers.get('content-disposition').split("filename=")[1];
      const blob = await file.blob(); 
      let url_file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
          a.href = url_file;
          a.id = 'rep_file'
          a.download = file_name;
          a.click();
    }

    const get_benefit_report = async (period, cb)=>{
      const url = `/v1/rep_benefit?&period=${period}&cb=${cb}`;
      const file = await fetch(url, { headers: headers });
      if(file.status != 200) { return alert('Не має даних за цей період') };
      const file_name = file.headers.get('content-disposition').split("filename=")[1];
      const blob = await file.blob(); 
      let url_file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
          a.href = url_file;
          a.id = 'rep_file'
          a.download = file_name;
          a.click();
    }



    const set_state_saldo_date = async (kg_id)=>{
      const res = await get_saldo_date(kg_id);
      setSaldoDate( res && res.length > 0 ? res[0]['created_at'] : null );
    }

    const get_invites = async (e) => {
      e.preventDefault();
      const cur_date = new Date();
      const url = `/v1/invites_list_kg?kg_id=${selected_kg}&period=${cur_date.getMonth() > 7 ? cur_date.getFullYear() : cur_date.getFullYear() - 1 }&date_to=${cur_date.getMonth() + 1 }.${cur_date.getFullYear()}`;
      const file = await fetch(url, { headers: headers });

      if(file.status != 200) return;

      const file_name = file.headers.get('content-disposition').split("filename=")[1];
      const blob = await file.blob(); 
      let url_file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
          a.href = url_file;
          a.id = 'rep_file'
          a.download = file_name;
          a.click();
    }

    const constr_data = async (kg)=>{
      const data_sez_zdo = await get_childrens_list(kg);

      setTableData(data_sez_zdo);
    }

    const select_kg = (event, value) =>{

     if(!value) return;

      const { kg_id } = value;

      if(!kg_id) {
        
      } else {
        selectKg(kg_id);
        constr_data( kg_id );
        set_state_saldo_date( kg_id );
      }
    }

    const addNewBenefit = ()=>{
      props.addBenefit(true);
    }

    const load_report_pre = (e)=>{
      e.preventDefault();
      get_report(new Date());
    }

    const load_report_jan = (e)=>{
      e.preventDefault();
      get_report(new Date('2021-02-01'));
    }



    const edit_kg = (event, rowData) => {
      // Do save operation
      setShowEdit({ show: true, data: rowData});
    }

    const close_edchild = ()=>{
      constr_data(selected_kg);
      setShowEdit({ show: false, data:{}});
    }

    const fix_saldo_jan = async (e)=>{
      e.preventDefault();
      if(role[0] != 'kyiv') return;
      await fix_saldo(new Date('2021-01-01'));
      set_state_saldo_date(selected_kg);
    }

    const handleBuch = (e)=>{

      const {value } = e.target;
      
      setBuch(value);

      setListOp(value);
    }

    const handlePeriodSelect = (e)=>{
      setPeriodSel(e.target.value);
    }

    const handleActionSelect = (e)=>{
      setAction(e.target.value);
    }

    const get_memo_xlsx_per = (isExists) =>{
      
      if(!isExists) return alert('На даний період ще не створено сальдо. Завантажити звіт можна тільки за наявним сальдо.')

      get_memo_report( nextMonth(period_sel), buch_sel);
      
    }

    const get_memo_xlsx_per_15 = (isExists) =>{
      
      if(!isExists) return alert('На даний період ще не створено сальдо. Завантажити звіт можна тільки за наявним сальдо.')

      get_memo_report_15( nextMonth(period_sel), buch_sel);
      
    }

    const get_cons_list_kgs = (isExists) => {
        
        if(!isExists) return alert('На даний період ще не створено сальдо. Завантажити звіт можна тільки за наявним сальдо.')

         get_cons_list_kgs_cb( nextMonth(period_sel), buch_sel);
    }

    const get_benefit_xlsx_per = (isExists) =>{
      
      if(!isExists) return alert('На даний період ще не створено сальдо. Завантажити звіт можна тільки за наявним сальдо.')

      get_benefit_report( nextMonth(period_sel), buch_sel);
      
    }


    const get_rep_acc_invites_cbs = (isExists) => {
      
      if(!isExists) return alert('На даний період ще не створено сальдо. Завантажити звіт можна тільки за наявним сальдо.')
      get_acc_invites_cbs(nextMonth(period_sel))
    
    }

    const get_child_out_xlsx = (isExists) =>{
      if(!isExists) return alert('На даний період ще не створено сальдо. Завантажити звіт можна тільки за наявним сальдо.')

      const url = `/v1/ch_out?&period=${nextMonth(period_sel)}&cb=${buch_sel}`;
      get_memo_report_body(url);
    }

    const get_bfn_report = (kg_id) => {
      const need_role = actions.find(el=>el.value == action_sel);

      if(need_role.level == 'kyiv' && role[0] != 'kyiv') return;

      const url = `/v1/rep_bfn?kg_id=${kg_id}&period=${nextMonth(period_sel)}`;
      get_memo_report_body(url);
    }

    const get_days_report = (kg_id) => {
      const need_role = actions.find(el=>el.value == action_sel);

      if(need_role.level == 'kyiv' && role[0] != 'kyiv') return;

      const url = `/v1/rep_days?kg_id=${kg_id}&period=${nextMonth(period_sel)}`;
      get_memo_report_body(url);
    }

    const onClickAction = async ()=>{

      const need_role = actions.find(el=>el.value == action_sel);

      if(need_role.level == 'kyiv' && role[0] != 'kyiv') return;

      const res_check = await check_saldo_exists(buch_sel, nextMonth(period_sel));

      const isExists = res_check && res_check.count && +res_check.count > 0;

      if( action_sel == 'memo' ) return get_memo_xlsx_per(isExists);

      if( action_sel == 'memo15' ) return get_memo_xlsx_per_15(isExists);

      if( action_sel == 'clistkgs' ) return get_cons_list_kgs(isExists);

      if( action_sel == 'benefit' ) return get_benefit_xlsx_per(isExists);

      if(action_sel == 'accinvites') return get_rep_acc_invites_cbs(isExists);
      
      if(action_sel == 'child_out') return get_child_out_xlsx(isExists);

      if(isExists) alert('За обраний період вже є сформовані сальдо');

      if( action_sel != 'saldo' ) return;

      if(!window.confirm('Дані будуть оновлені на поточний стан. Продовжити?')) return;

      const res_check_pre = await check_saldo_exists(buch_sel, period_sel);

      const isReady = res_check_pre && res_check_pre.count && +res_check_pre.count == op_data.length;

      if(!isReady) return alert('Не сформовані сальдо за попередній період');

      const res = await fix_saldo(buch_sel, nextMonth(period_sel));


      if(res && res.info) alert('Процес формування сальдо по ЦБ розпочався. Перевірте сформовані звіти через 10 хвилин.')

    }

 

    if(!display) return null;



    return (
      <div style={{ maxWidth: "100%" }}>
        <Grid container spacing={1} alignItems="baseline">
          <Grid item xs={4}>
          <Typography className={ classes.selectKg }>
          <span className={ classes.label}>  ЦБ </span>
            <Select labelId="demo-simple-select-label"
                  id="buch-select"
                  value={buch_sel}
                  onChange={handleBuch}>
                    { buch_list.map(buch=><MenuItem value={buch.number}>{buch.number + ' ('+ buch.distr + ')'}</MenuItem>)}
              </Select>
            </Typography>
          </Grid>
          <Grid item xs={2}>
          <Typography className={ classes.selectKg }>
          <span className={ classes.label}>  Пер. </span>
            <Select labelId="demo-simple-select-label"
                  id="buch-select"
                  value={period_sel}
                  onChange={handlePeriodSelect}>
                    { periods.map(period=><MenuItem value={period.value}>{period.label}</MenuItem>)}
              </Select>
            </Typography>
          </Grid>

          <Grid item xs={5}>
          <Typography className={ classes.selectKg }>
            <span className={ classes.label}>Дія</span>
            <Select labelId="demo-simple-select-label"
                  id="buch-select"
                  value={action_sel}
                  onChange={handleActionSelect}>
                    { actions.filter(f=>role[0] == 'kyiv' || f.level == 'all').map(action=><MenuItem value={action.value}>{action.label}</MenuItem>)}
              </Select>
              <Button className={classes.go_button} onClick={onClickAction}>Запустити</Button>
            </Typography>
          </Grid>
        </Grid>

        <EditChildren close={ close_edchild } open={show_edit && show_edit['show']} data={show_edit['data']} headers={headers} kg_id={selected_kg}/>
        <div className={classes.size_control} style={{ visibility: vis_control}}>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content < 30) setSizeContent(size_of_content + 1) } }>+ Збільшити шрифт в таблиці</div>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content > 10) setSizeContent(size_of_content - 1) } }>- Зменьшити шрифт в таблиці</div>
        </div>

        <div style={{ fontSize: size_of_content + 'px'}}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={op_data}
          title="Садочки"
          editable={{
            isEditable: true 
          }}
          actions={[
            {
              icon: 'edit',
              tooltip: 'редагувати',
              onClick: (event, rowData) =>edit_kg,
              disabled: (event, rowData) => rowData && !rowData.id_school
            },
            {
              icon: ()=><span className={classes.labelBFN}>Б</span>,
              tooltip: 'БФН',
              onClick: (event, rowData) => {
                get_bfn_report(rowData.sezzdo_id);
              }
            },
            {
              icon: ()=><span className={classes.labelBFN}>В</span>,
              tooltip: 'Вiдвiдування',
              onClick: (event, rowData) => {
                get_days_report(rowData.sezzdo_id);
              }
            }
          ]}
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50]
          }}
        /></div>
      </div>
    );

}