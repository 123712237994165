import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';

import { FilterNumericBetween, isDateInRange } from '../../../common/Daterangematerialtable';

import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from '../AddButton';
//import Dbf   from '../../../../helpers/dbf-reader/dbf.js';
import { Dbf } from 'dbf-reader';

import { utils, write, WorkBook, read } from 'xlsx';

import { ParsePayInfo } from './parsePayInfo';

import { AddEditPayForm } from './AddEditPayForm';

const iconv = require('iconv-lite');

let win1251decoder = new TextDecoder('windows-1251');

const areas = {
  "kyiv":{ "title": "Київ" , "short": "KV" },
  "golosievo":{ "title": "Голосіївський" , "short": "GS" },
  "darnitsa":{ "title": "Дарницький" , "short": "DRN" },
  "desnantskiy":{ "title": "Деснянський" , "short": "DSN" },
  "dniprovskiy":{ "title": "Дніпровський" , "short": "DNP" },
  "obolon":{ "title": "Оболонський" , "short": "OBL" },
  "pechersk":{ "title": "Печерський" , "short": "PCH" },
  "podil":{ "title": "Подільський" , "short": "PDL" },
  "sviatoshino":{ "title": "Святошинський" , "short": "SVT" },
  "solomianka":{ "title": "Солом’янський" , "short": "SLM" },
  "shevchenkovskiy":{ "title": "Шевченківський" , "short": "SHV" }
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {},
  isLoad: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    }
}));

export  function ListOfPayDbf(props) {

    const classes = useStyles();

    const { show, groups, headers, add, banks, auto_save } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const style_column = [
      {
      },
      {
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 100,
        maxWidth: 200
      },{
        width: 30,
        maxWidth: 60
      },{
        width: 20,
        maxWidth: 20
      },
    ];
/*    
    const [column, setColumn] = useState([
      {title: 'Дата', field: 'startDate',  filterComponent: FilterNumericBetween, customFilterAndSearch: (filter, rowData) => isDateInRange(filter.dateFrom, filter.dateTo, rowData.startDate)},
    ])
*/

    const columns = [
        {title:"Дата", field: "date", render: (rowData)=>new Date(rowData.date).toLocaleDateString('uk'), filterComponent: FilterNumericBetween},
        {title:"Сума", render: rowData=>{ const sum = '' + rowData.s; return `${sum.slice(0, sum.length - 2)}.${sum.slice(-2)}`}},
        {title: "Отримувач", field:"nazb"},
        {title: "Призначення", field:"naz"},
        {title: "Додано", field: "created_at", type: "date", defaultSort: "desc"}
    ];

    for(let col in columns){
      columns[col]['headerStyle'] = style_column[col];
      columns[col]['cellStyle'] =  style_column[col];
    }



    const [template_data, setTableData] = useState();
    const [initDataState, setInitDataState] = useState(false);
    const [display, setShow] = useState(false);
    const [showAddDbf, setShowAddDbf] = useState(false);
    const [dbfInfo, setDbfInfo] = useState({});
    const [dbfRecord, setDbfRecord] = useState({});
    const [dbfParts, setDbfParts] = useState([]);
    const [pointerPartDbf, setPointerPartDbf] = useState(-1);
    const [pointerRecord, setPointerRecord] = useState(0);

    const [isLoadedDBF, setLoaderDBF] = useState(false);

    const [area, setArea] = useState();


    const find_childrens = async (value)=>{
      //if(params.target.value.match(/\s/g)) return;
      if(!value || value.length < 3) return;


      const response = await fetch(`/v1/find_children?find=${encodeURI(value)}`, { headers: headers });
      const data = await response.json();
      return data || [];
     };

     const find_child_kg = async ( name_last, name_first )=>{
      const response = await fetch(`/v1/find_child_by_name?name_last=${encodeURI(name_last)}&name_first=${encodeURI(name_first)}`, { headers: headers });
      const data = await response.json();
      return data || [];
     };

     const autosave_arr_info = async (arr)=>{
      const response = await fetch(`/v1/pay_dbf`, { headers: headers, method: 'POST', body: JSON.stringify({arr: arr} ) });
      const data = await response.json();
      return data;
     }


     const check_info = async (info)=>{

       const {child_id, child_name, child_tab, group_number, kg_number } = info;
       
       if( !(child_id && child_name) ) return false;
       
       const search_ch = info && info.child_id ? info.child_id : info && info.child_name ? info.child_name : '';

       const res_search = await find_childrens(search_ch);

       if(res_search.length != 1) return false;

       const { sezzdo_name_first, sezzdo_name_last } = res_search[0];
       
       const res_kg = await find_child_kg(sezzdo_name_last, sezzdo_name_first);

       if(res_search.length < 1) return false;

       let indexKg = -1;

       if(!!kg_number) indexKg = res_kg.findIndex(el=>el.number == kg_number);


       if(indexKg >= 0) prepare_for_autosave(info, res_search[0], res_kg[indexKg] );

       return true;

     }

     const prepare_for_autosave = (info, child, kginfo ) =>{
      
      const data = Object.assign({}, info);
      const ms_date = (date)=> new Date(Math.round((date - 25569)*86400*1000)).toISOString().split('T')[0];

      const { da, namer, naza, op, datd, IBANA, naz, s, child_id, child_name, child_tab, group_number,kg_number} = data;

      const bank_account = banks.find(el=>el.iban == IBANA);

      if(!bank_account) return alert('IBAN не знайдений в довіднику');

      const res = {
        bank_account: bank_account.id,
        iban: bank_account.iban,
        summ: s,
        date_pay: ms_date(da), 
        status: 'accept',
        recipients_id: kginfo._id,
        recipients_name: kginfo.name || 'без найменування',
        recipients_number: kginfo.number,
        group_number:  kginfo.groups.number,
        customer_id: kginfo.groups.children.childId, 
        customer_name_f: child.sezzdo_name_first || 'без імені', 
        customer_name_l: child.sezzdo_name_last || 'без імені',
        purpose: naz || 'не визначено',
        area: area,
        bank_from: naza || "не визначено"
      }

      auto_save(res);

     }


    useEffect(()=>{
      setShow(show);
    },[show])


    useEffect(()=>{ start_work(); console.log('dbfParts.length', dbfParts.length)}, [dbfParts]);


    const start_work = ()=>setPointerPartDbf(0);

    const clear_dbf_process = ()=>{
      setShowAddDbf(false);
      setDbfParts([]);
      setPointerPartDbf(-1);
    }

    useEffect(()=>{if(dbfParts && dbfParts.length > 0) a_save()}, [pointerPartDbf, dbfParts.length]);

    const toNormData = ( data, is_big_or_small) =>{
      if(!is_big_or_small) return data;

      const excludes = [
        "IBANA",
        "IBANK"
      ];

      const obj_keys = {};

      for(let key in data[0]) {
        obj_keys[key] = key.toLowerCase();
      }

      return data.map(row=>{
        const new_row = {};
        for( let key in obj_keys) {
          if(!excludes.includes(key)) {
            new_row[obj_keys[key]] = row[key]; 
          } else {
            new_row[key] = row[key];
          }
        }
        return new_row;
      });

    }


    const onFileChange = (event) => {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {

            var arrayBuffer = reader.result;
            if (arrayBuffer) {
                const buffer = Buffer.from(arrayBuffer);


                let data = new Uint8Array(arrayBuffer);
                let wb = read(data, {type: 'buffer', codepage: 'cp1251' });

                let ws_name = wb.SheetNames[0];

                let ws = wb.Sheets[ws_name];

                let obj_data = utils.sheet_to_row_object_array(ws);


                const is_big_or_small = !obj_data[0]['namer'] && obj_data[0]['NAMER'];

                const norm_data = toNormData(obj_data, is_big_or_small);

                const data_prep = convWin1251(norm_data);

                if(!data_prep[0]['namer']) return alert('Пошкоджений файл. Обробка припинена.');

                const add_info = fillInfo( data_prep.filter(el=>el.op == 0) );

                if(!add_info || add_info.length == 0) return alert('У файлі відсутні дані для обробки. Поле \'op\' не дорівнює 0. Обробка припинена.');

                let ind_part = 0;

                let dbf_parts = [];


                const chank_size = 100;

//                const start_position_chunk = 0;


                for(let i=0; i< add_info.length; i = i + chank_size){
                  dbf_parts[ind_part] = add_info.slice(i, i + chank_size)
                  ind_part += 1;
                }

                //if( ( ( dbf_parts.length - 1) * chank_size ) < add_info.length ) dbf_parts[ind_part] = add_info.slice( dbf_parts.length * chank_size); 

                setDbfParts(dbf_parts);

/*
                setPointerPartDbf(start_position_chunk);

                setLoaderDBF(true);

                const autosave_res = await autosave_arr_info(dbf_parts[start_position_chunk]);

                setLoaderDBF(false);

                alert(`Опрацьовано ${dbf_parts[start_position_chunk].length} зі ${add_info.length} ( частина ${start_position_chunk +1}/${dbf_parts.length} ) записів. Знайдено ${autosave_res.double_rec} раніше зафіксованих платежів (дублікатів). Автоматично записано ${autosave_res.number_save} платежів. До обробки залишилось ${autosave_res.untag.length}`);

                if(autosave_res.untag.length == 0) return a_save(start_position_chunk + 1);
                
                setDbfInfo(autosave_res.untag);

                setArea(getAreas(autosave_res.untag[0]));
                setDbfRecord(autosave_res.untag[0]);
                setPointerRecord(0);

                setShowAddDbf(true);
*/

                }
            };

            setLoaderDBF(false);
        }
    }


    const a_save = async () => {


      if(pointerPartDbf == (dbfParts.length)) {
        alert('Обробка файлу DBF завершена');
        return clear_dbf_process();
      }

      if(isLoadedDBF) return;

      setLoaderDBF(true);

      const autosave_res = await autosave_arr_info(dbfParts[pointerPartDbf]);

      alert(`Опрацьовано ${dbfParts[pointerPartDbf].length} зі ${dbfParts.slice(pointerPartDbf).reduce( (acc,cur)=> acc + cur.length, 0 )} ( частина ${pointerPartDbf+1}/${dbfParts.length} )записів. Знайдено ${autosave_res.double_rec} раніше зафіксованих платежів (дублікатів). Автоматично записано ${autosave_res.number_save} платежів. До обробки залишилось ${autosave_res.untag.length}`);
      
      
      if(autosave_res.untag.length == 0) {
        
        setLoaderDBF(false);
      
        return setPointerPartDbf(pointerPartDbf + 1);
      }

      setDbfInfo(autosave_res.untag);
      setArea(getAreas(autosave_res.untag[0]));
      setDbfRecord(autosave_res.untag[0]);
      setPointerRecord(0);

      setShowAddDbf(true);

      setLoaderDBF(false);
    }

    const init_part_dbf = () => {
 
      //untag: arr_untag, number_save: arr_target.length, res_save: res_save, double_rec
      

    }

    const getAreas = (rec)=>{
      
      const { namer } = rec;
      
      const area = Object.keys(areas).find(el=>namer.toLowerCase().match(new RegExp(areas[el].title.slice(0,4).toLowerCase())));

      if(!area) return "kyiv";
      
      return area;
    }

    const prevRecordDbf = async ()=>{
      
      if( (pointerRecord  < 1) ) {
        return alert('Це перший запис для обробки.')
      }

      const nextPointer = pointerRecord - 1;

      setArea(getAreas(dbfInfo[nextPointer]));
      setDbfRecord( dbfInfo[nextPointer]);
      setPointerRecord(nextPointer);

      setShowAddDbf(false);
      setShowAddDbf(true);

    }

    const nextRecordDbf = async ()=>{

      if(!dbfInfo || dbfInfo.length == 1) return closeAddPayForm();

      
      if( (pointerRecord  ==  dbfInfo.length - 1) && (pointerRecord +1 == dbfParts[pointerPartDbf].length ) ) {

        closeAddPayForm();
        
        return alert('Немає записів для обробки.')
      }

      if(pointerRecord  ==  dbfInfo.length - 1) {
        return setPointerPartDbf(pointerPartDbf + 1);
      }

      const nextPointer = pointerRecord + 1;
      if(!dbfInfo.length || nextPointer == dbfInfo.length) return;

      setArea(getAreas(dbfInfo[nextPointer]));
      setDbfRecord( dbfInfo[nextPointer]);
      setPointerRecord(nextPointer);

      //const res_check = await check_info(dbfInfo[nextPointer]);

      //if(res_check) nextRecordDbf();

      setShowAddDbf(false);
      setShowAddDbf(true);

    }

    const convWin1251 = (arr_obj)=>{
        const new_arr = [];

        let index = 0;

        const fields_conv = ['namer', 'naz', 'naza', 'nazb', 'polut'];

        const str2ab = (str) => {
            var buf = new ArrayBuffer(str.length);
            var bufView = new Uint8Array(buf);
            for (var i=0, strLen=str.length; i < strLen; i++) {
              bufView[i] = str.charCodeAt(i);
            }
            return buf;
          }
        

        if(!arr_obj[0]['namer']) return arr_obj;

        for(let row of arr_obj){
            const new_obj = Object.assign({}, row);
            fields_conv.forEach(key=>{
                new_obj[key] = win1251decoder.decode(str2ab(new_obj[key]));
            })

            new_arr.push(new_obj);

            index += 1;
        }



        return new_arr;
    }

    const fillInfo = (arr_info) =>{
        const field_props =  'naz';
        const out_obj = [];

        for(let row of arr_info){
            const pay_info_parse = ParsePayInfo(row[field_props]);
            row = Object.assign({}, row, pay_info_parse);
            out_obj.push(row);
        }
        
        return out_obj;
    }

    const InputFile = ()=><div>
        <input
            accept=".dbf"
            className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={onFileChange}
        />
        <label htmlFor="raised-button-file">
            <Button variant="raised" component="span" className={classes.button}>
                Завантажити файл
            </Button>
        </label>
    </div> 

    const addNew = ()=>{
      props.addNew(true);
    }

    const closeAddPayForm = ()=>{
      if(pointerPartDbf < (dbfParts.length - 1)) {

      }
      setShowAddDbf(false);
    }

    const add_pay = async (data)=>{      
      const { customer } = data;
      const cust_obj = JSON.parse(customer);

      const { kginfo } = cust_obj;


      if(!kginfo || !kginfo._id || !kginfo.number || !kginfo.groups || !kginfo.groups.children || ( !kginfo.groups.children.childId && !kginfo.groups.children.gioc_id) ) return;

      const bank = banks.find(el=>el.iban == data.bank_account);
      
      if(!bank) return alert('IBAN не знайдений в довіднику');

      data.bank_account = JSON.stringify(bank);

      await add(data, area);
      nextRecordDbf();

    }

    const check_filters = (filters)=>{
      let result = true;
      filters.forEach(f=>{
        const { column, value } = f;
        const { field, type, tableData } = column;
        if(type == 'number' && isNaN(value)) result = false;
      })
      return result;
    }

    const prep_data = (data)=>{
      const ms_date = (date)=> new Date(Math.round((date - 25569)*86400*1000)).toISOString().split('T')[0];
      return data.map(r=>{r['date'] = ms_date(r.da); return r});
    }


    const getData = async (query) => 
    { if(!headers) return;

      if(!check_filters(query.filters)) return ({
        data: [],
        page: 0,
        totalCount: 0,
      });


      let { search, filters, page, pageSize, orderDirection,  orderBy } = query;


      if(orderBy && orderBy.tableData && orderBy.tableData.columnOrder == 1) orderBy['field'] = 's';

      if(!orderBy || !orderBy.field) {
        orderBy = {field: 'created_at'};
        orderDirection = 'desc';
      };



      if(orderBy && orderBy.field && orderBy.field == 'date') orderBy.field = 'da';

      let filterq = filters.map(filt=>{
        
        const { column, value } = filt;
        const { field, type, tableData } = column;

        if(typeof value == 'object') {
          const r = [];
          Object.keys(value).forEach(k=>r.push(`${k}=${value[k]}`));
          return r.join('&');
        }


        return `${field ? field : 'col_' + tableData.columnOrder}=${type == 'date' ? new Date(value).toISOString() : value}`;

      }).join('&');

      return new Promise((resolve, reject) => {

      let url = '/v1/get_untag_payments?'
      url += 'per_page=' + pageSize
      url += '&page=' + (page + 1)
      if(filters && filterq) url += `&${filterq}`;

      if(query && search) url += `&search=${search}`;

      if(query && orderDirection) url += `&ordirection=${orderDirection}`;
      if(query && orderBy && orderBy.field) url += `&ordby=${orderBy.field}`;

      fetch(url, { headers: headers})
        .then(response => response.json())
        .then(result => {
          const prep = prep_data(result.data);
          console.log(`prep ${JSON.stringify(prep.map(r=>r.date))}`);
          resolve({
            data: prep,
            page: result.page - 1,
            totalCount: result.totalCount,
          })
        })
    });
    }


    const remUntag = async (row) => {
      if(!headers) return;
      const {id} = row;
      const url = `/v1/del_untag_payment/${id}`;
      const res_1 = await fetch(url, { method: 'DELETE', headers });
      const res = await res_1.json();
      tableRef.current && tableRef.current.onQueryChange();
      return;
    }

    const editUntag = (data) => {
      data['IBANA'] = data.ibana;
      setArea(data.area);
      setDbfRecord(data);
      setDbfInfo([data]);
      setShowAddDbf(true);
    }

    if(!display ) return null;

    if(showAddDbf) return <div>
         { isLoadedDBF && <div className={classes.isLoad}>
            <LinearProgress /><Paper><Typography variant="h5" component="h6" align="center" noWrap>
          Зачекайте. Оброблюється наступна частина записів зі DBF виписки.
        </Typography></Paper>
        </div> }
        { !isLoadedDBF && <AddEditPayForm show={showAddDbf} data={dbfRecord} close={closeAddPayForm} pointer={pointerRecord} next={nextRecordDbf} groups={groups} headers_auth={headers} add={add_pay} length={dbfInfo.length} prev={prevRecordDbf}/> }
      </div>

    return (
      <div style={{ maxWidth: "100%" }}>
        <InputFile />
        { isLoadedDBF && <div className={classes.isLoad}>
            <LinearProgress />
        </div> }
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={getData}
          title="Реєстр незʼясованих платежів"
          components={{
            Action: props=><AddButton {...props} />
          }}
          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }
          actions={[
            {
              icon: 'edit',
              title: 'Дія',
              tooltip: 'Редагувати інформацію',
              onClick: (event, rowData) => { console.log("onclick edit row rowData", rowData); editUntag(rowData)}
            },
            {
              icon: 'delete',
              title: 'Дія',
              tooltip: 'Видалити зі списку',
              onClick: (event, rowData) => remUntag(rowData)
            }
          ]}
          editable={{
            isEditable: false
          }}
          options={{
            actionsColumnIndex: -1,
            filtering: true
          }}
        />
      </div>
    );

}



 
