/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectKg(props) {
  const classes = useStyles();

  const { data, onChange } = props;

  const [optionL, setOptions] = useState();

  

  useEffect(()=>{
      setOptions(data);
  })

  return (
    <Autocomplete
      id="country-select-demo"
      key={optionL && optionL.length || 0}
      //style={{ width: 300 }}
      options={optionL}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      autoHighlight
      getOptionLabel={(option) => `${option.lastName} ${option.firstName} група ${option.groupNumber} ${option.groupName || ''} таб ${option.tabelNumber}` }
      renderOption={(option) => (
        <React.Fragment>
          {`${option.lastName} ${option.firstName}  група ${option.groupNumber} ${option.groupName || ''} таб ${option.tabelNumber}`}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберіть дитину"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

