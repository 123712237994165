import React, { useEffect} from 'react';

export default function AuthPoint(props){


    useEffect(()=>{
        window.location.replace(`/auth?state=${props.state.pathname}`)
    })

    return null;
}