import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import { FilterNumericBetween } from '../../common/Daterangematerialtable';
import AddButton from './AddButton';
import SelectKg from './SelectKg';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  function ListOfPaymentsAPI(props) {

    const classes = useStyles();

    const { show, headers } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const [data, setData] = useState([]);

    const [listKgs, setListKgs] = useState();

    const style_column = [
      {
        width: 15,
        maxWidth: 15
      },
      {
        width: 15,
        maxWidth: 15
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 100,
        maxWidth: 200
      },{
        width: 400,
        maxWidth: 400
      },{
        width: 400,
        maxWidth: 400
      },
    ];

    //date_pay, summ, recipients_name, recipients_number, customer_name_f, customer_name_l, group_name, group_number, purpose, iban

    const columns = [
        {title:"Дата", field:"date_pay", render: (row)=>new Date(row.date_pay).toLocaleDateString('uk'), filterComponent: FilterNumericBetween},
        {title:"Сума", field:"summ", type: "number"},
        {title:"Заклад", field:"kg_number"},
        {title:"Група", field:"gr_number"},
        {title:"Дитина", field:"children_name"},
        {title:"Рахунок", field: "iban"},
        {title:"Призначення", field: "description" },
        {title:"Банк", field: "identname"}
      ];

    for(let col in columns){
      columns[col]['headerStyle'] = style_column[col];
      columns[col]['cellStyle'] =  style_column[col];
    }



    const [template_data, setTableData] = useState();

    const [initDataState, setInitDataState] = useState(false);

    const [display, setShow] = useState(false);

    let isLoading = false;

    const check_filters = (filters)=>{
      let result = true;
      filters.forEach(f=>{
        const { column, value } = f;
        const { field, type, tableData } = column;
        if(type == 'number' && isNaN(value)) result = false;
      })
      return result;
    }


    const get_payments_list = async (query) =>{
      
      if(isLoading) return;

      if(!check_filters(query.filters)) return ({
        data: [],
        page: 0,
        totalCount: 0,
      });
      


        let { search, filters, page, pageSize, orderDirection,  orderBy } = query;
        
        if(!orderBy || !orderBy.field) {
          orderBy = {field: 'date_pay'};
          orderDirection = 'desc';
        };
        
        isLoading = true;
        
        let filterq = filters.map(filt=>{
          
          const { column, value } = filt;
          const { field, type, tableData } = column;

          if(typeof value == 'object') {
            const r = [];
            Object.keys(value).forEach(k=>r.push(`${k}=${value[k]}`));
            return r.join('&');
          }

          if(field == 'summ') return 'amount=' + parseInt(+value*100);

          return `${field ? field : 'col_' + tableData.columnOrder}=${type == 'date' ? new Date(value).toISOString() : value}`;

        }).join('&');

      return new Promise((resolve, reject) => {

      let url = `/v1/get_payments_api?`;
      
      url += 'per_page=' + pageSize
      url += '&page=' + (page + 1)
      if(filters && filterq) url += `&${filterq}`;

      if(query && search) url += `&search=${search}`;

      if(query && orderDirection) url += `&ordirection=${orderDirection}`;
      if(query && orderBy && orderBy.field) url += `&ordby=${orderBy.field}`;

      fetch(url, { headers: headers })
      .then(response => response.json())
      .then(result => {
        isLoading = false;
        resolve({
          data: result.data.map(row=>{
            console.log('row', row);
            const newRow = Object.assign({}, row, {summ: (+row['amount']/100).toFixed(2), date_pay: new Date(+row.timestamp_tr*1000).toISOString() });
            console.log('newRow', newRow);
            return newRow;
          }),
          page: result.page - 1,
          totalCount: result.totalCount,
        })
      })
      .catch(err=>{
        isLoading = false;
        console.log('Error fetch', url, err)
      })
    })
  }

    const set_data = async ()=>{
      const info = await get_payments_list();
      setTableData(info);
   }



    useEffect(()=>{
      //set_data();
      setShow(show);
    },[show])

    const addNew = ()=>{
      props.addNew(true);
    }

    if(!display) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={get_payments_list}
          title="Платежі API"
          editable={{
            isEditable: false
          }}
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50]
          }}
        />
      </div>
    );

}