// Library
import React from 'react';
import Form from '@rjsf/material-ui';

import InputMask from 'react-input-mask';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');

export default function FormAddUser (props) {

    const { roles, areas, addUser, saveNewUser } = props;

    if(!props.show) return null;


    const sh = schema.properties;

    console.log("FormAddUser props", props);


    sh.role.items.enum = Object.keys(roles).map(role=>role);
    sh.role.items.enumNames = Object.keys(roles).map(role=>roles[role].title);

    sh.area.items.enum = Object.keys(areas).map(area=>area);
    sh.area.items.enumNames = Object.keys(areas).map(area=>areas[area].title);

    const MaskIPN = (props)=><MuiThemeProvider>
            <InputMask 
                mask="99 99 99 99 99" 
                value={props.value}
                onChange={(event) =>props.onChange(event.target.value) }
                disabled={false}
                children={<TextField label={props.schema.title}/>}/>
            </MuiThemeProvider>
    
    const MaskPhone = (props)=><MuiThemeProvider>
    <InputMask 
        mask="+380 (99) 999 99 99" 
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        disabled={false}
        children={<TextField label={props.schema.title}/>}/>
    </MuiThemeProvider>

    const MaskEmail = (props)=>
    {
        const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
        const letter = /(?!.*[DFIOQU])[A-Z]/i;
        const digit = /[0-9]/;
        const mask = [firstLetter, digit, letter, " ", digit, letter, digit];

        //const mask = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    return <MuiThemeProvider>
    <InputMask 
        mask={mask}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        disabled={false}
        children={<TextField label={props.schema.title}/>}/>
    </MuiThemeProvider>
    }


    const widgets = {
        MaskIPN: MaskIPN,
        MaskPhone: MaskPhone,
        MaskEmail: MaskEmail
    };


    const onSubmit = (value, callback) => {
        console.log('onSubmit: %s', JSON.stringify(value)); 
        setTimeout(() => callback && callback(), 2000); 
    }
    
    const onCancel = () => {
        console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const handleClose = ()=>{
        addUser(false);
    }

    const onSubmitForm = ({ formData}) => {
        
        formData.area = JSON.stringify(formData.area);
        formData.role = JSON.stringify(formData.role);
        formData.inn = +(formData.inn.replace(/\s/g, ""));
        
        console.log("onsubmit form data", JSON.stringify(formData) );

        saveNewUser( { user_info: formData } );
    };



    return (
         <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmitForm}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="button" onClick={handleClose} className="btn btn-secondary">Закрити без збереження</Button>
             <Button type="submit">Додати користувача</Button>
         </Form>
    );
}