import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function DatePickers(props) {

  const { setdate, label, defdate, value } = props;

  const classes = useStyles();

  const choise_date = (e)=>{
      
    if(!e.target.value) return;
    setdate(e.target.value);

  }

  return (
    <form className={classes.container} noValidate>
      <TextField
        id={"date" + label}
        label={label}
        type="date"
        value={value}
        defaultValue={defdate}
        onChange={choise_date}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}