// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import TextField from "@material-ui/core/TextField";

import Button from '@material-ui/core/Button';
import AlertDialog from '../../../../helpers/alertdialog';

import { getCurrentPeriod } from '../../../../helpers/kindergartens';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import  { add_new_class, edit_class }  from "./actions";

import SelectKg from '../SelectKg';


const infoAlertObj = { title: "Оновлення даних", text:"Перевирте та натисніть зберегти або відминити ", btn_agr:"Зберегти", btn_dsg: "Відмінити збереження" }; 



// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');


export default function FormAddClass(props) {

    const { close, data, classdata, headers } = props;

    const isEdit = classdata && classdata.id_school && classdata.number || false;

    const isPreSet = classdata && classdata.id_school || false;

    const formData = isEdit || isPreSet ? classdata : require('./formData.json');

    const [value, setValue] = useState();

    const [dataForm, setDataForm] = useState({});

    const [showAlert, setShowAlert] = useState(false);

    const [infoAlert, setInfoAlert] = useState(infoAlertObj);

    const [callbackAlert, setCallbackAlert] = useState(false);

    const CustomSelectKg = (props)=><SelectKg 
        data={data}
        onChange={(event, value) => {
            props.onChange(value && value.kg_id || '')
        } }
        setId={props.value}
    />

    const widgets = {
        CustomSelectKg:CustomSelectKg
    };


    const CurrencyInputW = (props)=><CurrencyTextField label="Сума"
    variant="standard"
    textAlign="left"
    value={value}
    currencySymbol="грн."
    //minimumValue="0"
    outputFormat="number"
    decimalCharacter="."
    digitGroupSeparator=" "
    onChange={(event, value)=> { setValue(value); props.onChange(value) ; } }
    />

    
    useEffect(()=>{

        const newDataForm = JSON.parse(JSON.stringify(formData)) ;

        if(data && data.id){
    
            for(let key in data) {
                const type_date = uiSchema[key] && uiSchema[key]["ui:widget"] || "string";
                const value = data[key];
    
                if(type_date  == "date" && value && value.length > 10){
                    newDataForm[key] = data[key].split("T")[0];
                    continue;
                }
    
                if( type_date == "date" ) continue;
    
                newDataForm[key] = data[key];
            }
        }

        setDataForm(newDataForm);

    },[data && data.id])
    


    const saveUpdate = ({ formData })=>{
        setCallbackAlert(()=>onSubmit(formData));
        setShowAlert(true);
    }


    const saveNewClass = async (formData) => {
        
        const { id_school, number, name } = formData;

        if(!id_school || !number) return;

        const prep = { id_school, number, name, period_start_year: getCurrentPeriod()};

        const send = await add_new_class({data: prep, headers});

        close('updated');
    }

    const saveUpdateClass = async (formData) => {

        const { id_school, number, name } = formData;

        if(!id_school || !number) return;

        const prep = { id: classdata.id, id_school, number, name};

        const send = await edit_class({data: prep, headers});

        close('updated');

    }


    const onSubmit = ({formData}) => {

        console.log(`formData ${JSON.stringify(formData)}`);

        if(!isEdit) return saveNewClass(formData);
        saveUpdateClass(formData);
    }
    
    const onCancel = () => {
        //console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        //console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        //console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const closeAlert = (res, callback) =>{
        if(res) callback();
    }

    if(!props.show) return <div/>;

    schema.title = isEdit ? "Змінити опис класу(групи)" : "Додати новий клас(групу) до закладу освіти"
  
    return (
         <div>
             <AlertDialog open={showAlert} info={infoAlert} close={closeAlert} callback={closeAlert}/>
             <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={dataForm}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={close}>Закрити без змін</Button>
         </Form>
         </div>
    );
}