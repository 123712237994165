// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import TextField from "@material-ui/core/TextField";

import Button from '@material-ui/core/Button';
import AlertDialog from '../../../../helpers/alertdialog';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";


const infoAlertObj = { title: "Оновлення даних", text:"Перевирте та натисніть зберегти або відминити ", btn_agr:"Зберегти", btn_dsg: "Відмінити збереження" }; 



// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');

export default function FormAddTarif (props) {

    const { close, addTarif, saveNewTarif, data, update } = props;

    const [value, setValue] = useState();

    const [dataForm, setDataForm] = useState({});

    const [showAlert, setShowAlert] = useState(false);

    const [infoAlert, setInfoAlert] = useState(infoAlertObj);

    const [callbackAlert, setCallbackAlert] = useState(false);


    const CurrencyInputW = (props)=><CurrencyTextField label="Сума"
    variant="standard"
    textAlign="left"
    value={value}
    currencySymbol="грн."
    //minimumValue="0"
    outputFormat="number"
    decimalCharacter="."
    digitGroupSeparator=" "
    onChange={(event, value)=> { setValue(value); props.onChange(value) ; } }
    />

    
    useEffect(()=>{

        const newDataForm = JSON.parse(JSON.stringify(formData)) ;

        if(data && data.id){
    
            for(let key in data) {
                const type_date = uiSchema[key] && uiSchema[key]["ui:widget"] || "string";
                const value = data[key];
    
                if(type_date  == "date" && value && value.length > 10){
                    newDataForm[key] = data[key].split("T")[0];
                    continue;
                }
    
                if( type_date == "date" ) continue;
    
                newDataForm[key] = data[key];
            }
        }

        setDataForm(newDataForm);

    },[data && data.id])
    

 

    const widgets = {
        CurrencyInput: CurrencyInputW
    };

    const saveUpdate = ({ formData })=>{
        setCallbackAlert(()=>onSubmit(formData));
        setShowAlert(true);
    }


    const onSubmit = ({formData}) => {

        const value = formData;
        
        if(data && Object.keys(data).length > 0 ) 
        {
            value['date_from'] = new Date(value['date_from']).toISOString();
            if(value['date_to']) value['date_to'] = new Date(value['date_to']).toISOString;

            if(value&&value['tableData']) delete value.tableData
            update(value);
            return addTarif(false);
        }

        saveNewTarif( formData )
        addTarif(false);
    }
    
    const onCancel = () => {
        //console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        //console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        //console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const closeForm = () => {
        addTarif(false);
    }

    const closeAlert = (res, callback) =>{
        if(res) callback();
    }

    if(!props.show) return <div/>;
  
    return (
         <div>
             <AlertDialog open={showAlert} info={infoAlert} close={closeAlert} callback={closeAlert}/>
             <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={dataForm}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={closeForm}>Закрити без змін</Button>
         </Form>
         </div>
    );
}