import React from "react";
import { Icon, IconButton, Tooltip } from '@material-ui/core';

export default  function AddButton(props) {
  
    let action = props.action;
    if (typeof action === 'function') {
      action = action(props.data);
      if (!action) {
        return null;
      }
    }

    const handleOnClick = event => {
      if (action.onClick) {
        action.onClick(event, props.data);
        event.stopPropagation();
      }
    };

    const button = (
      <span>
        <IconButton
          color="inherit"
          disabled={action.disabled}
          disableRipple
          onClick={(event) => handleOnClick(event)}
        >
          {typeof action.icon === "string" ? (
            <Icon {...action.iconProps} fontSize="small">{action.icon}</Icon>
          ) : (
              <action.icon
                {...action.iconProps}
                disabled={action.disabled}                
              />
            )
          }
        </IconButton>
      </span>
    );

    if (action.tooltip) {
      return <Tooltip title={action.tooltip}>{button}</Tooltip>;
    } else {
      return button;
    }
  
}