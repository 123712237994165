// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';

import Box from '@material-ui/core/Box';

import InputMask from 'react-input-mask';

import Button from '@material-ui/core/Button';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TextField from "@material-ui/core/TextField";

import SelectKg from '../../../payments/components/SelectKg';
import WidgetChildrenSelect from '../../../payments/components/GroupSelectChildren';

import { store, useGlobalState, useLocalState } from 'state-pool';

import { localDate } from '../../../../helpers/timedata';



// Internals
const uiSchema = require('./ui-schema.json');


const benefits_list = [
    {code: 1, name: "Діти учасників АТО (одна гривня)"},
    {code: 2, name: "Малий дохід членів сім’ї (100%)"},
    {code: 6, name: "Діти з багатодітних сімей (50%)"},
    {code: 7, name: "Діти ВПО (100%)"},
    {code: 8, name: "Малозабезпечені сім’ї (100%)"},
    {code: 9, name: "Діти з інвалідністю (100%)"},
    {code: 10, name: "Опіка (100%)"},
    //{code: 11, name: "Постраждалі від аварії на ЧАЕС"},
    {code: 12, name: "Діти з особливими освітними потребами (100%)"},
    {code: 13, name: "Діти загиблих(померлих) Захисників і Захисниць України (100%)"},
    {code: 14, name: "Діти киян, які брали( беруть) участь у заходах, необхідних для забезпечення оборони України (100%)"},
    {code: 15, name: "Діти киян, які брали( беруть) участь у заходах, необхідних для забезпечення оборони України (одна гривня)"},
    {code: 16, name: "Діти учасників АТО (100%)"},
    {code: 17, name: "Діти членів сімей осіб, які  брали( беруть) участь у заходах, необхідних для забезпечення оборони України (100%)"},
    {code: 18, name: "Діти, які постраждали внаслідок воєнних дій і збройних конфліктів (100%)"},
    {code: 19, name: "Діти членів сімей Захисників і Захисниць України, які перебувають в полоні або зникли безвісті (100%)"},
    {code: 20, name: "Діти членів сімей киян-Захисників і киянок-Захисниць України, які перебувають у полоні або зникли безвісті (100%)"},
    {code: 21, name: "Діти, які постраждалі в наслідок Чорнобильської катастрофи (100%)"},
    {code: 22, name: "Діти киян - Героїв Небесної Сотні (100%)"},
    {code: 23, name: "Діти киян - постраждалих учасників Революції Гідності (100%)"},
    {code: 24, name: "Діти загиблих( померлих) ветеранів війни (100%)"}
  ];

  //console.log(benefits_list);


const doc_types = [
        { key: "certificate", name: "Посвідчення"},
        { key: "reference", name: "Довідка"}
];


export function AddEditBenefit (props) {

    const { close, addBenefit, groups, listKgs, headers_auth,  editdata } = props;

    const [pay_opt, updatePayOpt, setPayOpt] = useGlobalState("addPay");

    const [option_kg, setOptionsKg] = useState([]);

    const [benefits_list, setBenList] = useState([]);

    let formData = {};
    let schema = {};

    const setBenDesc = async ()=>{
    
        const req = await fetch(`/v1/ben_desc`, {headers: headers_auth});
        const ben_desc = await req.json();
        
        setBenList(ben_desc || []);
    }

    useEffect(()=>{
        if(benefits_list && benefits_list.length > 0) return;
        setBenDesc();
    });

    
    const CustomSelectKg = (props)=><SelectKg 
        data={option_kg}
        onChange={(event, value) => props.onChange(value && value._id || '') }
    />


    const set_kg_by_name = async ( {name_last, name_first} )=>{
        const response = await fetch(`/v1/find_child_by_name?name_last=${encodeURI(name_last)}&name_first=${encodeURI(name_first)}`, { headers: headers_auth });
        const data = await response.json();
        setOptionsKg(data);
       };

    const WidgetSelectCh = (props)=><WidgetChildrenSelect headers_auth={headers_auth} set_kg_by_name={set_kg_by_name} groups={groups} list_kg={option_kg} onChange={(data) => props.onChange(data|| '')} editdata={props.value} />
        


    const widgets = {
        CustomSelectKg:CustomSelectKg,
        CustomSelectChildren:WidgetSelectCh
        };


    const onSubmit = (value, callback) => {

        const info = Object.assign({}, value.formData );

        const { child, kginfo } = JSON.parse(info.customer);

        info['ben_name'] = benefits_list.find(ben=>ben.code == info.type_ben)['name'];
        info['name_type_doc'] =doc_types.find(doc=>doc.key == info.typedoc)['name'];
        info['id_child'] = kginfo.groups.children.childId;
        info['id_kg'] = kginfo._id;
        info['name_last'] = child.name_last;
        info['name_first'] = child.name_first;
        info['group_number'] = kginfo.groups.number;
        info['kg_number'] = kginfo.number;
        addBenefit(info);
    }
    
    const onCancel = () => {
    }
    
    const onFormChanged = ({ formData }) => {
    }
    
    const onUpload = (value) => {
    }

    const closeForm = () => {
        close();
    }

    const fill_info = ()=>{


        schema = JSON.parse(JSON.stringify( require('./schema.json') ) );

        formData = JSON.parse(JSON.stringify( require('./formData.json') ) );

        const curDate = new Date().toISOString().split('T')[0];

        const [year, month] = curDate.split('-');


        if(!editdata || Object.keys(editdata).length == 0) {

            formData.customer = '';

            formData.date_set = curDate;
            formData.date_fin = `${year}-01-01`;
            formData.date_doc = `${+year}-01-01`;
            formData.typedoc = "certificate";
    
        } else {
            
            const { date_set, date_fin, date_doc, typedoc,  type_ben, customer, ownerdoc, organ, status } = editdata;

            const customer_obj = JSON.parse(customer);

            if(customer_obj && !customer_obj.child['sezzdo_name_last']) customer_obj.child['sezzdo_name_last'] = customer_obj.child.name_last;
            if(customer_obj && !customer_obj.child['sezzdo_name_first']) customer_obj.child['sezzdo_name_first'] = customer_obj.child.name_first;

            if( customer_obj && !customer_obj.kginfo.contacts) customer_obj.kginfo['contacts'] = { address: { district: '' }};


            formData.date_set = localDate( date_set );
            formData.date_fin = localDate( date_fin );
            formData.date_doc = localDate( date_doc );
            formData.typedoc = typedoc;
            formData.type_ben = +type_ben;
            formData.customer = JSON.stringify(customer_obj);
            formData.ownerdoc = ownerdoc;
            formData.organ = organ;
            formData.status = status;

            schema.title = 'Змінити інформацію про пільгу';

        }
    }

    const add_benlist = ()=>{
        schema.properties.type_ben["enum"] = benefits_list.map(el=>+el.code);
        schema.properties.type_ben["enumNames"] = benefits_list.map(el=>el.name);
    }

    const add_typedoc = ()=>{
        schema.properties.typedoc["enum"] = doc_types.map(doc=>doc.key);
        schema.properties.typedoc["enumNames"] = doc_types.map(doc=>doc.name);
    }


    fill_info();

    if(!schema.properties.type_ben.enum) add_benlist();
    if(!schema.properties.typedoc.enum) add_typedoc();

    //useEffect(()=>fill_info());



    //if(!props.show) return <div/>;


    return (
         <Form
              schema={schema}
              autocomplete="off"
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={closeForm}>Закрити без змін</Button>
         </Form>
    );
}