// Library
import React from 'react';
import Form from '@rjsf/material-ui';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import TextField from "@material-ui/core/TextField";

import Button from '@material-ui/core/Button';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";



// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');

export default function FormAddBenefit (props) {

    const { close, addBenefit, saveNewBenefit } = props;

    const [value, setValue] = React.useState();


    const CurrencyInputW = (props)=><CurrencyTextField label="Сума"
    variant="standard"
    textAlign="left"
    value={value}
    currencySymbol="грн."
    //minimumValue="0"
    outputFormat="number"
    decimalCharacter="."
    digitGroupSeparator=" "
    onChange={(event, value)=> { setValue(value); props.onChange(value) ; } }
    />
 

    const widgets = {
        CurrencyInput: CurrencyInputW
    };


    const onSubmit = (value, callback) => {
        saveNewBenefit( value.formData )
        addBenefit(false);
    }
    
    const onCancel = () => {
        console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
        console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
        console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const closeForm = () => {
        close();
    }

    if(!props.show) return <div/>;
  
    return (
         <Form
              schema={schema}
              uiSchema={uiSchema}
              widgets={widgets}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
             <Button type="submit">Додати та закрити</Button>
             <Button type="close" onClick={closeForm}>Закрити без змін</Button>
         </Form>
    );
}