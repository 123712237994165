export const get_list_classes = async (data, headers) => {

    const { id_school, period_start_year } = data;

    console.log(`get_list_classes id_school, headers ${id_school, headers}`);
    const options = {
        method: 'GET',
        headers: headers
      };
  
      const link = `/v1/get_classes?id_school=${id_school}&period_start_year=${period_start_year}`;
  
      const res = await fetch(link, options);
  
      if(!res.ok) {
        console.log("error by fetch", link);
        alert("Error by sending req");
        return;
      }
  
      const info = await res.json();
  
      return info;
}

export const get_students_by_sh = async (data, headers) => {

  const options = {
      method: 'GET',
      headers: headers
    };

    const params = Object.keys(data).map(k=>`${k}=${data[k]}`).join("&");

    const link = `/v1/get_students?${params}`;

    const res = await fetch(link, options);

    if(!res.ok) {
      console.log("error by fetch", link);
      alert("Error by sending req");
      return;
    }

    const info = await res.json();

    return info;

}

export const set_attendance_student = async (data, headers) => {
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ data: data})
  };

  const link = `/v1/attendance_student`;

  const res = await fetch(link, options);

  if(!res.ok) {
    console.log("error by fetch", link);
    alert("Error by sending req");
    return;
  }

  const info = await res.json();

  return info;
}

export const promise_set_attendance_student = (data, headers) =>{
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    };

    const link = `/v1/attendance_student`;

    return fetch(link, options);
}

export const  get_accounts_list = async ( callback, err, headers) =>{

  const url = `/v1/district_buch`;
  const response = await fetch(url, { headers: headers });
  if(!response.ok) {
    err();
  };
  const data = await response.json();
  return callback( data || [] );

}