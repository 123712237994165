import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import { FilterNumericBetween } from '../../common/Daterangematerialtable';
import AddButton from './AddButton';
import SelectKg from './SelectKg';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const prep_data = (data)=>data.map(d=>{
  d.summ = d.summ.slice(1);
  return d;
})


export  function ListOfPayments(props) {

    const classes = useStyles();

    const { show, data, listKgs, edit, headers_auth } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const [headers, setHeaders] = useState();

    const style_column = [
      {
      },
      {
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 15,
        maxWidth: 15
      },{
        width: 100,
        maxWidth: 200
      },{
        width: 30,
        maxWidth: 60
      },{
        width: 20,
        maxWidth: 20
      },
    ];

    const columns = [
        {title:"Дата", field: "date_pay", render: (row)=>new Date(row.date_pay).toLocaleDateString('uk'), filterComponent: FilterNumericBetween},
        {title:"Сума", field:"summ", type: "number"},
        {title: "Заклад", field:"recipients_number"},
        {title: "Група", field:"group_number"},
        {title:"Дитина", render: rowData => `${rowData.customer_name_l} ${rowData.customer_name_f} `, customFilterAndSearch: (term, rowData) => (rowData.customer_name_l + " " + rowData.customer_name_f).toLowerCase().match(new RegExp(term.toLowerCase()))},
        {title: "Призначення", field: "purpose" },
        {title: "Вид оплати",render: rowData=>`${rowData.type_purpose == "bfn" ? 'БФН': 'Харчування'}`},
        {title: "Статус", field: "status"},
        {title: "Додано", field: "created_at", type: "date", defaultSort: "desc"}
    ];

    for(let col in columns){
      columns[col]['headerStyle'] = style_column[col];
      columns[col]['cellStyle'] =  style_column[col];
    }



    const [template_data, setTableData] = useState();

    const [initDataState, setInitDataState] = useState(false);

    const [display, setShow] = useState(false);


    useEffect(()=>{
      if(!headers_auth) return;
      setHeaders(headers_auth)
    },[headers_auth]);
    
    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{ 
      setTableData(data.data)
      }, [data.data, show]
    );

    const check_filters = (filters)=>{
      let result = true;
      filters.forEach(f=>{
        const { column, value } = f;
        const { field, type, tableData } = column;
        if(type == 'number' && isNaN(value)) result = false;
      })
      return result;
    }


    const getData = async (query) => 
      { if(!headers) return;

        if(!check_filters(query.filters)) return ({
          data: [],
          page: 0,
          totalCount: 0,
        });


        let { search, filters, page, pageSize, orderDirection,  orderBy } = query;

        if(!orderBy || !orderBy.field) {
          orderBy = {field: 'created_at'};
          orderDirection = 'desc';
        };

        let filterq = filters.map(filt=>{
          
          const { column, value } = filt;
          const { field, type, tableData } = column;

          if(typeof value == 'object') {
            const r = [];
            Object.keys(value).forEach(k=>r.push(`${k}=${value[k]}`));
            return r.join('&');
          }

          return `${field ? field : 'col_' + tableData.columnOrder}=${type == 'date' ? new Date(value).toISOString() : value}`;

        }).join('&');

        return new Promise((resolve, reject) => {

        let url = '/v1/get_payments?'
        url += 'per_page=' + pageSize
        url += '&page=' + (page + 1)
        if(filters && filterq) url += `&${filterq}`;

        if(query && search) url += `&search=${search}`;

        if(query && orderDirection) url += `&ordirection=${orderDirection}`;
        if(query && orderBy && orderBy.field) url += `&ordby=${orderBy.field}`;

        fetch(url, { headers: headers})
          .then(response => response.json())
          .then(result => {
            resolve({
              data: prep_data(result.data),
              page: result.page - 1,
              totalCount: result.totalCount,
            })
          })
      });
      }



    const addNew = ()=>{
      props.addNew(true);
    }

    if(!display || !listKgs || listKgs.length < 1) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <div><Button onClick={addNew}>Додати новий платіж </Button></div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={getData}
          title="Платежі розподілені"
          components={{
            Action: props=><AddButton {...props} />
          }}
          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }
          actions={[
            {
              icon: 'edit',
              title: 'Дія',
              tooltip: 'Редагувати інформацію',
              onClick: (event, rowData) => { edit(rowData)}
            }
          ]}
          editable={{
            isEditable: false
          }}
          options={{
            actionsColumnIndex: -1,
            filtering: true
          }}
        />
      </div>
    );

}