import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';
import SelectKg from './SelectKg';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export  function ListOfBanks(props) {

    const classes = useStyles();

    const { show, data } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
        {title:"Дата видкриття", field:"date_open", type: "date"},
        {title:"Назва установи", field:"name_bank"},
        {title: "ЄДРПОУ", field:"edrpou"},
        {title: "Рахунок IBAN", field:"iban"},
        {title: "Призначення рахунку", field: "purpose" },
        {title:"Статус", field:"status"},
        {title:"Дата закриття", field:"date_close"}
    ];

    const [template_data, setTableData] = useState(data.data);
    
    const [initDataState, setInitDataState] = useState(false);
    

    const [display, setShow] = useState(false);


    
    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{ setTableData(data.data)}, [data.data, show])



    const addNew = ()=>{
      props.addNew(true);
    }

    if(!show) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
        <div><Button onClick={addNew}>Додати новий рахунок </Button></div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={template_data}
          title="Банківські рахунки"
          components={{
            Action: props=><AddButton {...props} />
          }}

          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }

          actions={[
            {
              icon: "edit",
              tooltip: "редагуваті",
              onClick: (event, rowData) => alert(`Редагувати ${rowData.name_last}`)
            }
          ]}
          editable={{
            isEditable: false
          }}
        />
      </div>
    );

}