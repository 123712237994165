import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import SelectID from './SelectID';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '300px',
      height: '160px',
    },
  },
}));

/* 
{"statusMR":{"result":"false"},
"groupId":"5f36315e1a6ea2073f357e3f",
"groupName":"Чомучка",
"groupNumber":"06-2020",
"_id":"5cca8f2e41610220f0ebcf6c",
"dateFrom":"2020-08-31T21:00:00.000Z",
"tabelNumber":"338","bigNumber":"315011",
"firstName":"Аріна",
"lastName":"Примаченко",
"birthDate":"2015-11-16T12:00:00.000Z",
"benefits":[{"_id":"5cc07b6bb563c9526eddf9ee","benefitId":"5c16b5a22f8be751f04fce92"}],"placeType":"standart","gioc_id_rono_child":564657,"debet_info":{"summ":"$500.00","summ_dolg":"$271.27"},"tableData":{"id":4}}
*/

/*
const benefits_list = [
  {code: 0, name: "Пільга відсутня"},
  {code: 1, name: "Діти учасників АТО (одна гривня)"},
  {code: 2, name: "Малий дохід членів сім’ї (100%)"},
  {code: 6, name: "Діти з багатодітних сімей (50%)"},
  {code: 7, name: "Діти ВПО (100%)"},
  {code: 8, name: "Малозабезпечені сім’ї (100%)"},
  {code: 9, name: "Діти з інвалідністю (100%)"},
  {code: 10, name: "Опіка (100%)"},
  //{code: 11, name: "Постраждалі від аварії на ЧАЕС"},
  {code: 12, name: "Діти з особливими освітними потребами (100%)"},
  {code: 13, name: "Діти загиблих(померлих) Захисників і Захисниць України, ветеранів війни (100%)"},
  {code: 14, name: "Діти киян, які брали( беруть) участь у заходах, необхідних для забезпечення оборони України (100%)"},
  {code: 15, name: "Діти киян, які брали( беруть) участь у заходах, необхідних для забезпечення оборони України (одна гривня)"},
  {code: 16, name: "Діти учасників АТО (100%)"},
  {code: 18, name: "Діти, які постраждали внаслідок воєнних дій і збройних конфліктів (100%)"},
  {code: 19, name: "Діти членів сімей Захисників і Захисниць України, які перебувають в полоні або зникли безвісті (100%)"},
  {code: 20, name: "Діти членів сімей киян-Захисників і киянок-Захисниць України, які перебувають у полоні або зникли безвісті (100%)"},
  {code: 21, name: "Діти, які постраждалі в наслідок Чорнобильської катастрофи (100%)"}
]
*/

export default function EditChildren(props) {

  const { open, data, close, kg_id, headers } = props;

  const [free_id, setFreeId] = useState([]);

  const [selected_id, setSelectedID] = useState({})

  const [result_find, setResultFindId] = useState('');

  const [finding_num, setFindNumber] = useState('');

  const [benefits_list, setBenList] = useState('');

  const classes = useStyles();

  const handleClose = async () => {
    if(selected_id && selected_id.id_rono_child && window.confirm(`Зафіксувати зміни?`)){
      console.log("Fixed change data");
      await setChildID();
    }
    close();
  };

  const findId = async (e) =>{
    console.log(`findId e ${e}`);

    const value = e && e.target && e.target.value || '';
    
    if(value.length < 5) return;

    const req = await fetch(`/v1/find_by_id?gioc_id=${value}`, {headers: headers});

    const result = await req.json();

    setFindNumber(value);

    if(result && result.length == 1) setResultFindId(result[0]);

  }

  const setListIDs = async ()=>{
    if(!kg_id || kg_id.length < 10 ) return;
    
    const freeIDs_req = await fetch(`/v1/free_gioc_id?kg_id=${kg_id}`, {headers: headers});
    const freeIDs = await freeIDs_req.json();
    
    setFreeId(freeIDs || []);
  }

  const setBenDesc = async ()=>{
    
    const req = await fetch(`/v1/ben_desc`, {headers: headers});
    const ben_desc = await req.json();
    
    setBenList(ben_desc || []);
  }


  const setChildID = async ()=>{
    const zdo_id = data._id;

//    return console.log(`/v1/update_id_gioc?zdo_id=${zdo_id}&gioc_id=${selected_id.id_rono_child}`);

    const updateInfo = await fetch(`/v1/update_id_gioc?zdo_id=${zdo_id}&gioc_id=${selected_id.id_rono_child}&first_name=${data.firstName}&last_name=${data.lastName}&gioc_name=${selected_id.fio_rono_child}`, {headers: headers});

    if(updateInfo.status != 200) return alert(`Виникла помилка під час встановлення ID. Спробуйте ще раз`);

  }

  const setID = (event, value)=>{
    console.log(`data ${JSON.stringify(value)}`);
    setSelectedID(value);
  }

  const remID = async (id, gioc_id)=>{
    if(!window.confirm(`Номер ID ${gioc_id} буде видалений`) ) return;

    const updateInfo = await fetch(`/v1/remove_id_gioc?zdo_id=${id}`, {headers: headers});

    if(updateInfo.status == 200) return close();

    alert('Помилка видалення номеру ID');
  }


  useEffect(()=>{
    setListIDs();
    setBenDesc();
    
  }, [open])

  

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{"Редагувати картку дитини"}</DialogTitle>
        <DialogContent>
        <Container maxWidth="lg">
          <Typography component="div" style={{  height: '48vh', width: '45vw' }} >
          <Grid container spacing={1}>
            <Grid item xs={6}>{`${data.lastName} ${data.firstName}, група ${data.groupNumber}, таб. ${data.tabelNumber}, ID ${data.gioc_id_rono_child}`}</Grid>
            <Grid item xs={6}><Button onClick={()=>remID(data._id, data.gioc_id_rono_child)} color="primary" autoFocus>Видалити ID номер</Button></Grid>
            <Grid item xs={4}>
              Номер ID, оберіть:
            </Grid>
            <Grid item xs={8}>
              <SelectID data={free_id} onChange={setID}/>
            </Grid>
            <Grid item xs={4}>
              Знайти ID , введіть номер:
            </Grid>
            <Grid item xs={8}>
              <TextField id="standard-basic" label="Номер ID" onChange={findId}/>
            </Grid>
            <Grid item xs={6}>
              <span>{Object.keys(result_find).map(el=>result_find[el]).join(", ") || 'знайти...'}</span>
            </Grid>
            <Grid item xs={6}>{result_find && Object.keys(result_find) && Object.keys(result_find).length > 0 && <Button onClick={()=>remID(result_find.sezzdo_id_child, finding_num)} color="primary" autoFocus>Видалити ID номер у цієї дитини</Button> } </Grid>
            
          
          </Grid>
 
          </Typography>
        </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Закрити
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}