/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectBank(props) {
  const classes = useStyles();

  const { data, onChange, default_val, value_d } = props;


  const [options, setOptions] = useState();
  const [value, setValue] = useState();


  useEffect(() => {
    
    if(value_d && value_d != 'null' && value_d.length > 29) {

      const obj = JSON.parse(value_d);
      console.log('select bank obj.iban', obj.iban);

      console.log('select bank default_val', default_val);

      setValue(obj.iban);
    };

  });


  return (
    <Autocomplete
      id="country-select-demo"
      //style={{ width: 300 }}
      options={data}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      inputValue={ value }
      onInputChange={(_, newInputValue) => {
        setValue(newInputValue)
      }}
      autoHighlight
      getOptionLabel={(option) => option.iban}
      defaultValue={ default_val }
      renderOption={(option) => (
        <React.Fragment>
          {option.iban} {option.purpose} {option.name_bank} 
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Оберить рахунок"
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

