// Library
import React, { useState, useEffect } from 'react';
import Form from '@rjsf/material-ui';
import Grid from '@material-ui/core/Grid';

import Box from '@material-ui/core/Box';

import InputMask from 'react-input-mask';

import Button from '@material-ui/core/Button';

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TextField from "@material-ui/core/TextField";

import SelectKg from '../../SelectKg';
import WidgetChildrenSelect from '../../GroupSelectChildren';




// Internals
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');



export function AddEditPayForm (props) {

    const { close, data, add, groups, headers_auth, pointer, next, length, prev } = props;


    const [option_kg, setOptionsKg] = useState([]);

    const [f_d, setFD] = useState({});

    const { child_id, child_name, child_tab, group_number,kg_number } = data;

    const [info, setInfo] = useState({ child_id, child_name, child_tab, group_number,kg_number });

    const CustomSelectKg = (props)=><SelectKg 
        data={option_kg}
        onChange={(event, value) => props.onChange(value && value._id || '') }
    />

    

    const set_kg_by_name = async ( {name_last, name_first} )=>{
        const response = await fetch(`/v1/find_child_by_name?name_last=${encodeURI(name_last)}&name_first=${encodeURI(name_first)}`, { headers: headers_auth });
        const data = await response.json();
        setOptionsKg(data);
       };

    const WidgetSelectCh = (props)=><WidgetChildrenSelect headers_auth={headers_auth} set_kg_by_name={set_kg_by_name} groups={groups} list_kg={option_kg} onChange={(data) => props.onChange(data|| '')} info={info}/> 
        

    const MaskEDRPOU = (props)=><MuiThemeProvider>
            <InputMask 
                mask="99 99 99 99" 
                value={props.value}
                onChange={(event) => props.onChange(event.target.value.replace(/\s/g, "")) }
                disabled={false}
                children={<TextField label={props.schema.title}/>}/>
            </MuiThemeProvider>
    
    const MaskIBAN = (props)=><MuiThemeProvider>
    <InputMask 
        mask="UA999999999999999999999999999" 
        value={props.value}
        onChange={(event) => props.onChange(event.target.value) }
        disabled={false}
        children={<TextField label={props.schema.title}/>}/>
    </MuiThemeProvider>

    const widgets = {
        MaskEDRPOU: MaskEDRPOU,
        MaskIBAN: MaskIBAN,
        CustomSelectKg:CustomSelectKg,
        CustomSelectChildren:WidgetSelectCh
    };


    const onSubmit = (value, callback) => {

        add(value.formData);
        
        //setPayOpt( value.formData )
        //add_close(value.formData);
    }
    
    const onCancel = () => {
//        console.log('on reset being called');
    }
    
    const onFormChanged = ({ formData }) => {
//        console.log('onFormChanged: ',formData); // eslint-disable-line no-console
    }
    
    const onUpload = (value) => {
//        console.log('onUpload: ', value); // eslint-disable-line no-console
    }

    const closeForm = () => {
        close();
    }

    const fillForm = (rec)=>{

        const { da, namer, naza, op, datd, IBANA, naz, s, child_id, child_name, child_tab, group_number,kg_number, id} = rec;
        
        const _s = s.toString();
        
        const sum = `${_s.slice(0, _s.length - 2)}.${_s.slice(-2)}`;
        
        const formTempl = Object.assign({},formData);

        const ms_date = (date)=> new Date(Math.round((date - 25569)*86400*1000)).toISOString().split('T')[0];

        formTempl.purpose = naz;
        formTempl.date_pay = ms_date(da);
        formTempl.bank_account = IBANA;
        formTempl.bank_from = naza;
        formTempl.summ = sum;
        formTempl.customer = JSON.stringify({child_id: child_id, child_name: child_name, child_tab: child_tab, group_number: group_number, kg_number: kg_number });
        formTempl['id'] = id;

        setFD(formTempl);
        setInfo(Object.assign({},{child_id: child_id, child_name: child_name, child_tab: child_tab, group_number: group_number, kg_number: kg_number } ) );

    }

    const nextRec = ()=>{
        next();
    }

    useEffect(()=>{
        fillForm(data);
    },[pointer])

    if(!props.show) return <div/>;




   
    return (
         <Form
              schema={schema}
              autocomplete="off"
              uiSchema={uiSchema}
              widgets={widgets}
              formData={f_d}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onUpload={onUpload}
              onChange={onFormChanged}
              submitOnEnter
              activityIndicatorEnabled
         >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button type="submit">Зберегти</Button>
                    {length > 1 && <Button type="close" onClick={prev}>Попередній</Button> }
                    {length > 1 && <Button type="close" onClick={nextRec} title="зафіксувати нез'ясований платіж">Наступний</Button> }
                </Grid>
                <Grid item xs={3}>
                    <Button type="close" onClick={closeForm}>Закрити</Button>
                </Grid>
                <Grid item xs={5}>
                    {`${pointer + 1} of ${length}`}
                </Grid>
            </Grid>
         </Form>
    );
}