import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from "material-table";
import {IconButton, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import AddButton from './AddButton';
import { headers } from '../../../helpers/headers';
import { useGlobalState } from 'state-pool';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons_div: {
    cursor: 'pointer',
    "&:hover, &:focus": {
      fontWeight: "bold",
      color: "#303F9F"
    }
  }
}));

export function Bfnlist(props) {

    const [auth, updateAuth, setAuth] = useGlobalState("auth");

    const classes = useStyles();

    const { show, data, add, edit, upd, role } = props;

    const tableRef = React.createRef();
    const addActionRef = React.useRef();

    const columns = [
      {title:'Заклад', field: 'kg_number'},
      {title:'ПІБ', render: (r)=>`${r.name_last} ${r.name_first}`, customFilterAndSearch: (term, rowData) => term == rowData.name.length},
      {title:'Дата початку', field: 'date_from', type: "date"},
      {title:'Дата припинення', field: 'date_to', type: "date"},
      {title:'Нараховано', field: 'charged'},
      {title:'Сплачено', field: 'payments'},
      {title:'Статус', field: 'status'}
    ];

    const [template_data, setTableData] = useState([]);
    
    const [initDataState, setInitDataState] = useState(false);

    const [size_of_content, setSizeContent ] = useState(17);
    const[vis_control, setVisControl] = useState('hidden');

    

    const [display, setShow] = useState(false);


    const fill_data = ()=>{
      if(data && data.length > 0) {
        setVisControl('visible');
      } else {
        setVisControl('hidden');
      }
      setTableData(data);
    }

    
    useEffect(()=>{
      setShow(show);
    },[show])

    useEffect(()=>{
      fill_data();
    }, [data.length, show] )

    const editBenefit = (data)=>{
      edit();
    }

    const addNew = ()=>{
      add(true);
    }

    const updMass = ()=>{
      upd(true);
    }


    const addNewUser = ()=>{
      props.addTarif(true);
    }

    const check_filters = (filters)=>{
      let result = true;
      filters.forEach(f=>{
        const { column, value } = f;
        const { field, type, tableData } = column;
        if(type == 'number' && isNaN(value)) result = false;
      })
      return result;
    }


    const getData = async (query) => 
    { if(!headers) return;

      if(!check_filters(query.filters)) return ({
        data: [],
        page: 0,
        totalCount: 0,
      });


      let { search, filters, page, pageSize, orderDirection,  orderBy } = query;


      if(orderBy && orderBy.tableData && orderBy.tableData.columnOrder == 1) orderBy['field'] = 's';

      if(!orderBy || !orderBy.field) {
        orderBy = {field: 'created_at'};
        orderDirection = 'desc';
      };



      if(orderBy && orderBy.field && orderBy.field == 'date') orderBy.field = 'da';

      let filterq = filters.map(filt=>{
        
        const { column, value } = filt;
        const { field, type, tableData } = column;

        return `${field ? field : 'col_' + tableData.columnOrder}=${type == 'date' ? new Date(value).toISOString() : value}`;

      }).join('&');

      return new Promise((resolve, reject) => {

      let url = '/v1/bfn_all?'
      url += 'per_page=' + pageSize
      url += '&page=' + (page + 1)
      if(filters && filterq) url += `&${filterq}`;

      if(query && search) url += `&search=${search}`;

      if(query && orderDirection) url += `&ordirection=${orderDirection}`;
      if(query && orderBy && orderBy.field) url += `&ordby=${orderBy.field}`;

      fetch(url, { headers: headers(auth)})
        .then(response => response.json())
        .then(result => {
          resolve({
            data: result.data,
            page: result.page - 1,
            totalCount: result.totalCount,
          })
        })
    });
    }

    if(!display) return null;

    return (
      <div style={{ maxWidth: "100%" }}>
      <div style={{display: 'flex'}}>
        <div><Button onClick={addNew}>Додати </Button></div>
      </div>
      <div className={classes.size_control} style={{ visibility: vis_control}}>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content < 30) setSizeContent(size_of_content + 1) } }>+ Збільшити шрифт в таблиці</div>
            <div  className={ classes.buttons_div} onClick={()=>{ if(size_of_content > 10) setSizeContent(size_of_content - 1) } }>- Зменьшити шрифт в таблиці</div>
        </div>

        <div style={{ fontSize: size_of_content + 'px'}}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={getData}
          title="Нарахування за БФН"
          components={{
            Action: props=><AddButton {...props} />
          }}

          icons={
            { Add: ()=> <IconButton onClick={()=>{}} />}
          }

          actions={[
            {
              icon: 'edit',
              title: 'Дія',
              tooltip: 'Редагувати інформацію',
              onClick: (event, rowData) => { edit(rowData)}
            }
          ]}

          editable={{
            onRowAdd: (newData) =>
              Promise.resolve(setTableData([...template_data, newData]))
          }}
          options={{
            filtering: true,
            pageSize: 10,
            pageSizeOptions: [10,20,50],
            actionsColumnIndex: -1
          }}
        /></div>
      </div>
    );

}