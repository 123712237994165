export const fetchInit = (endp, stMang, headers, params)=>{
    if(endp.isLoad) return null;
    fetch(endp.url + (params || ''), { headers: headers})
    .then((res)=>{
      if(!res.ok) {
        //if(res.status = 401) 
        throw new Error('Error fetch url status' + res.status);
      } 
      return res.json();
    })
    .then((data)=> stMang(data))
    .then(()=>endp.isLoad=true )
    .catch(err=>
      {
        console.log(`Error fetch ${endp.url}, err `)
        return ([]);
      });
  }