export const add_student = async (data, headers) => {
    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      };
  
      const link = `/v1/add_student`;
  
      const res = await fetch(link, options);
  
      if(!res.ok) {
        console.log("error by fetch", link);
        alert("Error by sending req");
        return;
      }
  
      const info = await res.json();
  
      return info;
}

export const set_student = async (data, headers) => {

  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data)
  };

  const link = `/v1/set_student`;

  const res = await fetch(link, options);

  if(!res.ok) {
    console.log("error by fetch", link);
    alert("Error by sending req");
    return;
  }

  const info = await res.json();

  return info;
}